.fullscreen-button
    display none
    position absolute
    right 12px
    bottom 120px
    width 50px
    height 50px
    line-height 50px
    text-align center
    border-radius 50%
    font-size 14px
    color #ffffff
    background #397FFF
    box-shadow 0px 4px 12px 0px rgba(42, 53, 75, 0.3)
    cursor pointer
    &.show
        display block
        transition all 0.2s
        &:hover
            background #619EFF
