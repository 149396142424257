.login-info
    .confirm-title
        margin 30px 0 50px
    .confirm-content
        .blue-border
            float left
            width 4px
            height 16px
            background #397fff
            border-radius 2px
            margin 3px 10px 0 30px
        .second-title
            font-size 16px
            font-weight bold
            color #2a354b
            line-height 21px
    .information
        margin-bottom 28px
        .line
            margin 6px 10px 0 44px
            font-size 14px
            line-height 24px
            color #2a354b
            display flex
            .line-name
                width 70px
                display inline-block
            .spacing
                width 434px
                display inline-block
                color #555e6f
                margin-left 8px
    .ks-btn
        margin 18px 200px 40px 200px
