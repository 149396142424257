.ant-notification-notice.exam-message {
  position: relative;
  width: 400px;
  border-left: 6px solid;
  box-shadow: 0px 6px 26px 0px rgba(42,53,75,0.16);
  background-color: #fff;
  border-radius: 4px;
  padding: 15px 24px 15px 16px;
  line-height: 24px;
}
.ant-notification-notice.exam-message.success-style {
  border-color: #02c882;
}
.ant-notification-notice.exam-message.error-style {
  border-color: #f9302a;
}
.ant-notification-notice.exam-message .ant-notification-notice-message {
  margin-left: 32px;
  margin-bottom: 0;
}
.ant-notification-notice.exam-message .message-content {
  color: #2a354b;
  font-size: 16px;
}
.ant-notification-notice.exam-message .iconfont {
  position: relative;
  top: -2px;
  font-size: 18px;
  line-height: 24px;
}
.ant-notification-notice.exam-message .icon-success {
  color: #02c882;
}
.ant-notification-notice.exam-message .icon-error {
  color: #f9302a;
}
