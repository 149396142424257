@import '../../../../style/mixins.styl'

.paper-item-detail
    .row-1 span, .row-3 span
        margin-right 8px
        font-size 14px
    .row-2
        font-size 18px
        padding 2px 0
        line-height 30px
        textOverflow()
