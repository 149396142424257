.exam-page-main
    .view-header
        height 67px
        display flex
        align-items center
        justify-content space-between
        .header-right
            display flex
        .remain-time
            display flex
            align-items center
            color #2A354B
            .icon-clock
                font-weight bold
                font-size 17px
            .time-tip
                margin 0 10px 0 7px
        .submit-btn
            margin 0 40px
            width 100px
    .view-question
        border solid #E9EAEC
        border-width 1px 0 1px 0
        height calc(100% - 147px)
        overflow-y scroll
        padding 25px 32px 40px 25px
    .view-footer
        position relative
        height 80px
        text-align right
        padding-right 30px
        .ks-btn
            margin 16px 10px
            width 100px
            &.disabled
                pointer-events auto
                cursor default
        .answer-btn
            position absolute
            left 30px
            width 126px
            padding 0 10px
            color #397fff
            border-color #397fff
.confirm-submit-modal
    .confirm-submit-view
        color #555E6F
        font-size 14px
        line-height 24px
        b
            font-weight bold
        .paper-status
            padding 12px 0
            span
                margin-right 24px
            span b
                margin 0 10px
            span:nth-child(1) b
                color #02C882
            span:nth-child(2) b
                color #F9302A
