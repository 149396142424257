.exam-image-container {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 50px);
  height: 0;
  padding-bottom: 56.25%;
  border-radius: 2px;
}
.exam-image-container .image-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.exam-image-container .image-wrap .transparent-mask {
  background: transparent;
}
.exam-image-container .image-wrap .ant-image-img {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}
.exam-image-container .image-preview {
  position: absolute;
  top: 10px;
  right: 9px;
  background: rgba(42,53,75,0.45);
  border-radius: 4px;
  text-align: center;
  width: 32px;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
}
.exam-image-container .image-preview .icon-enlarge {
  color: #fff;
  font-size: 20px;
}
