.login-instruction .instruction-title .icon-back {
  position: relative;
  top: 2px;
  font-size: 25px;
  color: #aaaeb7;
  margin-left: 28px;
  cursor: pointer;
  transition: all 0.1s;
}
.login-instruction .instruction-title .icon-back:hover {
  color: #397fff;
}
.login-instruction .instruction-title .confirm-title {
  display: inline-block;
  vertical-align: middle;
  margin: 30px 0 40px 66px;
}
.login-instruction .instruction-content {
  margin: 0 30px;
}
.login-instruction .instruction-content .ant-tabs-tabpane {
  height: 211px;
  margin-top: 20px;
}
.login-instruction .instruction-content .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.login-instruction .instruction-content .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  border-radius: 4px 0 0 0;
}
.login-instruction .instruction-content .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.login-instruction .instruction-content .ant-tabs-nav {
  margin: 0;
}
.login-instruction .instruction-content .ant-tabs-tab {
  width: 110px;
  height: 36px;
  border: none;
  background: #e9eaec;
  font-size: 16px;
  border-radius: 20px;
}
.login-instruction .instruction-content .ant-tabs-tab .ant-tabs-tab-btn {
  margin: 0 auto;
  color: #2a354b;
}
.login-instruction .instruction-content .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:nth-child(1) {
  border-radius: 4px 0 0 0;
}
.login-instruction .instruction-content .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:nth-child(2) {
  border-radius: 0 4px 0 0;
}
.login-instruction .instruction-content .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.login-instruction .instruction-content .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin-right: -2px;
}
.login-instruction .instruction-content .ant-tabs-tab-active {
  background: #397fff;
}
.login-instruction .instruction-content .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.login-instruction .instruction-content .ant-tabs-content-holder {
  width: 540px;
  background: #f0f8ff;
  border-radius: 0 4px 4px 4px;
}
.login-instruction .instruction-content .ant-tabs-content-holder .card-content {
  margin: 0 20px 27px 20px;
  white-space: pre-wrap;
}
.login-instruction .ks-btn {
  margin: 30px 200px 20px;
}
.login-instruction .ks-btn .button-countdown {
  display: inline;
}
.login-instruction .instruction-bottom {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #7f8693;
}
.login-instruction .content-bottom {
  margin-bottom: 16px;
}
