.gjj-page {
  position: relative;
  height: 100%;
  z-index: 0;
}
.gjj-page .gjj-main {
  overflow-y: scroll;
  position: absolute !important;
  top: 0;
  left: 234px;
  bottom: 0;
  right: 0;
  z-index: -1;
  padding: 0 20px 0 20px;
  background: #f4f4f5;
}
