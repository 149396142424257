.simulation-modal {
  height: 100%;
  max-width: none;
  padding: 0;
}
.simulation-modal >.ant-modal-content {
  border-radius: 0;
  height: 100%;
}
.simulation-modal >.ant-modal-content >.ant-modal-close .ant-modal-close-x {
  display: inline-block;
  width: auto;
  margin-right: 30px;
  font-size: 14px;
  color: #fff;
  height: 40px;
  line-height: 40px;
}
.simulation-modal >.ant-modal-content >.ant-modal-close .ant-modal-close-x .close-btn {
  position: relative;
  font-weight: normal;
}
.simulation-modal >.ant-modal-content >.ant-modal-close .ant-modal-close-x .close-btn .close-tip-view {
  position: absolute !important;
  top: 100%;
  left: auto;
  bottom: auto;
  right: -15px;
  top: 100%;
  margin-top: 2px;
  right: -15px;
  width: 154px;
  height: 58px;
  cursor: default;
}
.simulation-modal >.ant-modal-content >.ant-modal-close .ant-modal-close-x .close-btn .close-tip-view .close-bg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.simulation-modal >.ant-modal-content >.ant-modal-close .ant-modal-close-x .close-btn .close-tip-view .close-tip-btn {
  position: absolute !important;
  top: 6px;
  left: auto;
  bottom: auto;
  right: 0;
  top: 6px;
  right: 0;
  width: 14px;
  height: 12px;
  border-radius: 0 2px 2px 2px;
  background: #5991f7;
  text-align: center;
  cursor: pointer;
}
.simulation-modal >.ant-modal-content >.ant-modal-close .ant-modal-close-x .close-btn .close-tip-view .close-tip-btn .close-tip-btn-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
}
.simulation-modal >.ant-modal-content >.ant-modal-close .ant-modal-close-x .close-btn .close-tip-view .close-tip-text {
  position: absolute;
  left: 15px;
  bottom: 6px;
  width: 124px;
  line-height: 20px;
  font-size: 12px;
}
.simulation-modal >.ant-modal-content >.ant-modal-body {
  position: relative;
  height: 100%;
  padding: 0;
}
.simulation-modal >.ant-modal-content >.ant-modal-body .simulation-title {
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  background: #001529;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
  font-size: 16px;
  font-weight: bold;
}
.simulation-modal .ant-modal-wrap {
  text-align: center;
}
