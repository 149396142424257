.ant-modal-confirm.sb-confirm .ant-modal-body .ant-modal-confirm-btns
    float none
    display flex
    justify-content center
    flex-flow row-reverse
    .ant-btn:first-child
        color #333333
        border-color #999999
        &:hover
            color #BE986F
            background #ffffff
            border-color #BE986F
    .ant-btn:last-child
        margin-left 0
        margin-right 20px
        background #BE986F
        color #ffffff
        border none
        &:hover
            background #CBB586
