.score-statistics
    border-left 1px solid
    .column
        width 10%
        float left
        .row-1, .row-2, .row-3
            height 30px
            text-align center
            border 1px solid
            line-height 30px
            border-left none
        .row-2, .row-3
            border-top none
        .active
            height 30px
            text-align center
            border 1px solid #000
            line-height 30px
            border-left none
            border-top none
            color red
    .columns
        .rows-1, .rows-2, .rows-3
            span
                width 10%
                display inline-block
                height 30px
                text-align center
                border 1px solid #000
                line-height 30px
                border-left none
        .rows-2, .rows-3
            span
                border-top none
        .active
            color red
