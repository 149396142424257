.analyze-box {
  width: 100%;
  border: 1px solid #000;
  margin: 30px 0;
  padding: 30px;
}
.analyze-box .analyze-name {
  font-weight: bold;
  font-size: 18px;
}
