.gjj-QiFeng-page .QiFeng-header {
  font-size: 14px;
  color: #333;
  margin: 8px 0;
}
.gjj-QiFeng-page .QiFeng-header .current {
  color: #128ed5;
  margin-right: 5px;
}
.gjj-QiFeng-page .QiFeng-header .header-personal {
  margin-left: 5px;
}
.gjj-QiFeng-page .QiFeng-main {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(20,66,149,0.1);
}
.gjj-QiFeng-page .QiFeng-main .main-header .search-form {
  pointer-events: none;
  border-bottom: 1px solid #ededed;
}
.gjj-QiFeng-page .QiFeng-main .main-header .search-form .form-row {
  margin-top: -5px;
}
.gjj-QiFeng-page .QiFeng-main .main-header .search-form .form-btn {
  margin-bottom: 20px;
}
.gjj-QiFeng-page .QiFeng-main .main-header .search-form .form-btn .ant-btn {
  width: 100px;
  height: 36px;
  border-radius: 4px;
}
.gjj-QiFeng-page .QiFeng-main .main-header .search-form .form-btn .ant-btn:first-child {
  background: #1497e2;
  color: #fff;
}
.gjj-QiFeng-page .QiFeng-main .main-header .search-form .form-btn .submit-btn {
  margin: 0 16px 0 20px;
}
.gjj-QiFeng-page .QiFeng-main .main-header .search-form .form-btn .form-tagging {
  color: #999;
  font-size: 12px;
}
.gjj-QiFeng-page .QiFeng-main .main-button {
  margin: 20px 0;
}
.gjj-QiFeng-page .QiFeng-main .main-button .ant-btn {
  width: 100px;
  height: 36px;
  margin-right: 20px;
  border-radius: 4px;
}
.gjj-QiFeng-page .QiFeng-main .main-button .ant-btn:hover {
  color: #1497e2;
  background: #fff;
  border-color: #1497e2;
}
.gjj-QiFeng-page .QiFeng-main .main-button .btn-four {
  width: 114px;
}
.gjj-QiFeng-page .QiFeng-main .main-button .btn-five {
  width: 128px;
}
.gjj-QiFeng-page .QiFeng-main .alert-warn {
  background: rgba(255,130,23,0.1);
  border: 1px solid rgba(255,130,23,0.4);
  border-radius: 4px;
}
.gjj-QiFeng-page .QiFeng-main .alert-warn .icon-img {
  width: 14px;
  height: 14px;
}
.gjj-QiFeng-page .QiFeng-main .alert-warn .ant-alert-warning,
.gjj-QiFeng-page .QiFeng-main .alert-warn .ant-alert-icon {
  color: #ff8217;
}
.gjj-QiFeng-page .QiFeng-main .main-table .success {
  color: #02c882;
}
.gjj-QiFeng-page .QiFeng-main .main-table .fail {
  color: #f00;
}
.gjj-QiFeng-page .QiFeng-main .main-table .ant-table-thead .ant-table-cell {
  font-weight: 700;
}
.gjj-QiFeng-page .QiFeng-main .main-table .show-more {
  cursor: pointer;
}
.gjj-QiFeng-page .QiFeng-main .main-table .ant-pagination {
  margin: 16px 45%;
}
.gjj-warning .ant-message-notice-content {
  border: 1px solid #ffe6d1;
  background: #fff9f3;
  border-radius: 4px;
}
.gjj-warning .ant-message-notice-content .anticon svg {
  color: #ff8217;
}
