.paper-snapshot
    .empty
        color rgba(0, 0, 0, 0.25)
    .paper-snapshot-preview-container
        min-height 200px
        margin-top 10px
        border 1px solid #d9d9d9
        padding 20px 25px 40px
        .paper-item-detail
            border none
        .paper-description
            border-radius 4px
            background #D9D9D9
            padding 2px 8px 10px
            margin 20px 0 40px
            .view-name
                font-weight bold
                font-size 16px
                line-height 36px
            .view-content
                font-size 14px
                line-height 20px
