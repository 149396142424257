.sb-detail-page-sidebar
    width 234px
    height 100%
    box-shadow 0px 0px 10px 0px rgba(61, 41, 0, 0.08)
    overflow-x hidden
    padding-bottom 40px
    .view-title
        display block
        height 60px
        line-height 60px
        border-bottom 1px solid #EDEDED
        padding-left 30px
        .back-icon
            position relative
            top -1px
            display inline-block
            width 24px
            height 24px
            line-height 24px
            font-size 16px
            border-radius 4px
            background #E9EAEC
            color #AAAEB7
            text-align center
            transform rotate(90deg)
        .back-title
            color #333333
            font-size 20px
            margin-left 16px
    .ant-menu-submenu-arrow
        color #555555
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title
        height 44px
        line-height 44px
    .ant-menu-item-disabled, .ant-menu-submenu-disabled
        cursor default
        pointer-events none
        .ant-menu-submenu-title
            color #555555 !important
    .ant-menu-item
        height 40px
        line-height 40px
        &.ant-menu-item-disabled, &.ant-menu-item-disabled a
            color #555555 !important
            cursor default
            pointer-events none
            &.ant-menu-item-only-child
                opacity 0.7
    .ant-menu-item, .ant-menu-submenu
        font-size 14px
        color #555555
        .item-icon-hover
            display none
        .item-icon, .item-icon-hover
            width 16px
            margin-right 12px
    .item-link
        color #555555
        padding-left 4px
        &:hover
            color #BE986F
    .ant-menu-submenu-title, .ant-menu-item, .ant-menu-item:not(:last-child)
        transition all 0.1s
        margin-top 0
        margin-bottom 0
    .ant-menu-submenu.no-children .ant-menu-submenu-arrow
        display none
    .ant-menu-submenu:hover .ant-menu-submenu-title, .ant-menu-item-selected .item-link
        color #BE986F
    .ant-menu-item:hover, .ant-menu-item-active, .ant-menu-submenu:hover, .ant-menu-submenu.ant-menu-submenu-selected, .ant-menu-item.ant-menu-item-selected
        color #BE986F
        .ant-menu-submenu-arrow
            color #BE986F
        .item-icon
            display none
        .item-icon-hover
            display inline-block
    .ant-menu
        .ant-menu-item-active, .ant-menu-item-selected, .ant-menu-submenu.ant-menu-submenu-active, .ant-menu-item:active, .ant-menu-submenu-title:active
            background-color rgba(203, 181, 134, 0.15)
    .ant-menu-inline, .ant-menu-item::after
        border-right none
    .ant-menu.ant-menu-sub.ant-menu-inline
        background #ffffff
