fixed(top = 0, right = 0, bottom = 0, left = 0)
	position fixed
	top top
	left left
	bottom bottom
	right right
absolute(top = auto, right = auto, bottom = auto, left = auto)
	position absolute !important
	top top
	left left
	bottom bottom
	right right
fill()
	position absolute
	left 0
	top 0
	width 100%
	height 100%
center()
	position absolute
	left 50%
	top 50%
	transform translate(-50%, -50%)
textOverflow()
	text-overflow ellipsis
	word-break break-all
	white-space nowrap
	overflow hidden
