.gjj-page-sidebar {
  width: 234px;
  height: 100%;
  box-shadow: 0px 0px 10px 0px rgba(61,41,0,0.08);
  overflow-x: hidden;
  padding-bottom: 40px;
}
.gjj-page-sidebar .ant-menu-submenu-arrow {
  color: #555;
}
.gjj-page-sidebar .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
}
.gjj-page-sidebar .ant-menu-item {
  height: 40px;
  line-height: 40px;
}
.gjj-page-sidebar .ant-menu-item.ant-menu-item-disabled,
.gjj-page-sidebar .ant-menu-item.ant-menu-item-disabled a {
  color: #555 !important;
  cursor: default;
  pointer-events: none;
}
.gjj-page-sidebar .ant-menu-item.ant-menu-item-disabled.ant-menu-item-only-child,
.gjj-page-sidebar .ant-menu-item.ant-menu-item-disabled a.ant-menu-item-only-child {
  opacity: 0.7;
}
.gjj-page-sidebar .ant-menu-item,
.gjj-page-sidebar .ant-menu-submenu {
  font-size: 14px;
  color: #555;
}
.gjj-page-sidebar .item-link {
  color: #555;
  padding-left: 4px;
}
.gjj-page-sidebar .item-link:hover {
  color: #1497e2;
}
.gjj-page-sidebar .ant-menu-submenu-title,
.gjj-page-sidebar .ant-menu-item,
.gjj-page-sidebar .ant-menu-item:not(:last-child) {
  transition: all 0.1s;
  margin-top: 0;
  margin-bottom: 0;
}
.gjj-page-sidebar .ant-menu-submenu.no-children .ant-menu-submenu-arrow {
  display: none;
}
.gjj-page-sidebar .ant-menu-submenu:hover .ant-menu-submenu-title,
.gjj-page-sidebar .ant-menu-item-selected .item-link {
  color: #1497e2;
}
.gjj-page-sidebar .ant-menu-item:hover,
.gjj-page-sidebar .ant-menu-item-active,
.gjj-page-sidebar .ant-menu-submenu:hover,
.gjj-page-sidebar .ant-menu-submenu.ant-menu-submenu-selected,
.gjj-page-sidebar .ant-menu-item.ant-menu-item-selected {
  color: #1497e2;
}
.gjj-page-sidebar .ant-menu-item:hover .ant-menu-submenu-arrow,
.gjj-page-sidebar .ant-menu-item-active .ant-menu-submenu-arrow,
.gjj-page-sidebar .ant-menu-submenu:hover .ant-menu-submenu-arrow,
.gjj-page-sidebar .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-arrow,
.gjj-page-sidebar .ant-menu-item.ant-menu-item-selected .ant-menu-submenu-arrow {
  color: #1497e2;
}
.gjj-page-sidebar .ant-menu .ant-menu-item-active,
.gjj-page-sidebar .ant-menu .ant-menu-item-selected,
.gjj-page-sidebar .ant-menu .ant-menu-submenu.ant-menu-submenu-active,
.gjj-page-sidebar .ant-menu .ant-menu-item:active,
.gjj-page-sidebar .ant-menu .ant-menu-submenu-title:active {
  background-color: rgba(20,151,226,0.15);
}
.gjj-page-sidebar .ant-menu-inline,
.gjj-page-sidebar .ant-menu-item::after {
  border-right: none;
}
.gjj-page-sidebar .ant-menu.ant-menu-sub.ant-menu-inline {
  background: #fff;
}
