@import '../../../style/mixins.styl'

.login-page
    position relative
    height 100%
    width 100%
    background #f8f9fc
    .login-view
        background #fff
        width 480px
        height 320px
        text-align center
        padding 30px 90px
        border-radius 6px
        box-shadow 0 2px 33px rgba(0 104 255 0.12)
        center()
        .ant-form-item-explain
            text-align left
        .view-title
            font-size 24px
            font-weight bold
            margin-bottom 30px
        .login-item
            margin-bottom 16px
        .login-form-button
            width 148px
            margin-top 10px
        .login-tip
            color #999AA0
