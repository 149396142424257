.login-instruction
    .instruction-title
        .icon-back
            position relative
            top 2px
            font-size 25px
            color #AAAEB7
            margin-left 28px
            cursor pointer
            transition all 0.1s
            &:hover
                color #397FFF
        .confirm-title
            display inline-block
            vertical-align middle
            margin 30px 0 40px 66px
    .instruction-content
        margin 0 30px
        .ant-tabs-tabpane
            height 211px
            margin-top 20px
        .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap
            border-radius 4px 0 0 0
        .ant-tabs-card > .ant-tabs-nav::before
            display none
        .ant-tabs-nav
            margin 0
        .ant-tabs-tab
            width 110px
            height 36px
            border none
            background #E9EAEC
            font-size 16px
            border-radius 20px
            .ant-tabs-tab-btn
                margin 0 auto
                color #2A354B
        .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab
            &:nth-child(1)
                border-radius 4px 0 0 0
            &:nth-child(2)
                border-radius 0 4px 0 0
        .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab
            margin-right -2px
        .ant-tabs-tab-active
            background #397FFF
            .ant-tabs-tab-btn
                color #fff
        .ant-tabs-content-holder
            width 540px
            background #F0F8FF
            border-radius 0 4px 4px 4px
            .card-content
                margin 0 20px 27px 20px
                white-space pre-wrap
    .ks-btn
        margin 30px 200px 20px
        .button-countdown
            display inline
    .instruction-bottom
        font-size 12px
        line-height 20px
        text-align center
        color #7f8693
    .content-bottom
        margin-bottom 16px
