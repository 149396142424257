.form-question-list .question-list {
  min-height: 200px;
  padding: 12px;
}
.form-question-list .question-list .page-list-container .list-item {
  padding: 24px 10px 24px 20px;
  height: auto;
  min-height: 140px;
}
.form-question-list .question-list .page-list-container .list-item .row-1 span {
  margin-right: 8px;
  font-size: 14px;
}
.form-question-list .question-list .page-list-container .list-item .row-1 .item-type {
  color: #6076ff;
}
.form-question-list .question-list .page-list-container .list-item .analyze-score-btn {
  position: absolute;
  top: 10px;
  right: 116px;
}
.form-question-list .question-list .page-list-container .list-item .row-2 {
  line-height: 24px;
  padding: 6px 0;
}
.form-question-list .question-list .page-list-container .list-item .row-2 .item-content {
  padding-right: 100px;
  width: calc(100% - 130px);
  max-height: 48px;
  overflow: hidden;
  font-size: 18px;
}
.form-question-list .question-list .page-list-container .list-item .row-2 .item-score {
  position: absolute;
  top: 50%;
  margin-top: -24px;
  right: 10px;
  width: 120px;
  font-size: 30px;
  line-height: 48px;
  text-align: right;
}
.form-question-list .question-list .page-list-container .list-item .row-3 {
  width: calc(100% - 200px);
}
.form-question-list .question-list .page-list-container .list-item .row-3 .ant-tag {
  margin-bottom: 4px;
}
