.sb-detail-page-sidebar {
  width: 234px;
  height: 100%;
  box-shadow: 0px 0px 10px 0px rgba(61,41,0,0.08);
  overflow-x: hidden;
  padding-bottom: 40px;
}
.sb-detail-page-sidebar .view-title {
  display: block;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #ededed;
  padding-left: 30px;
}
.sb-detail-page-sidebar .view-title .back-icon {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  border-radius: 4px;
  background: #e9eaec;
  color: #aaaeb7;
  text-align: center;
  transform: rotate(90deg);
}
.sb-detail-page-sidebar .view-title .back-title {
  color: #333;
  font-size: 20px;
  margin-left: 16px;
}
.sb-detail-page-sidebar .ant-menu-submenu-arrow {
  color: #555;
}
.sb-detail-page-sidebar .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 44px;
  line-height: 44px;
}
.sb-detail-page-sidebar .ant-menu-item-disabled,
.sb-detail-page-sidebar .ant-menu-submenu-disabled {
  cursor: default;
  pointer-events: none;
}
.sb-detail-page-sidebar .ant-menu-item-disabled .ant-menu-submenu-title,
.sb-detail-page-sidebar .ant-menu-submenu-disabled .ant-menu-submenu-title {
  color: #555 !important;
}
.sb-detail-page-sidebar .ant-menu-item {
  height: 40px;
  line-height: 40px;
}
.sb-detail-page-sidebar .ant-menu-item.ant-menu-item-disabled,
.sb-detail-page-sidebar .ant-menu-item.ant-menu-item-disabled a {
  color: #555 !important;
  cursor: default;
  pointer-events: none;
}
.sb-detail-page-sidebar .ant-menu-item.ant-menu-item-disabled.ant-menu-item-only-child,
.sb-detail-page-sidebar .ant-menu-item.ant-menu-item-disabled a.ant-menu-item-only-child {
  opacity: 0.7;
}
.sb-detail-page-sidebar .ant-menu-item,
.sb-detail-page-sidebar .ant-menu-submenu {
  font-size: 14px;
  color: #555;
}
.sb-detail-page-sidebar .ant-menu-item .item-icon-hover,
.sb-detail-page-sidebar .ant-menu-submenu .item-icon-hover {
  display: none;
}
.sb-detail-page-sidebar .ant-menu-item .item-icon,
.sb-detail-page-sidebar .ant-menu-submenu .item-icon,
.sb-detail-page-sidebar .ant-menu-item .item-icon-hover,
.sb-detail-page-sidebar .ant-menu-submenu .item-icon-hover {
  width: 16px;
  margin-right: 12px;
}
.sb-detail-page-sidebar .item-link {
  color: #555;
  padding-left: 4px;
}
.sb-detail-page-sidebar .item-link:hover {
  color: #be986f;
}
.sb-detail-page-sidebar .ant-menu-submenu-title,
.sb-detail-page-sidebar .ant-menu-item,
.sb-detail-page-sidebar .ant-menu-item:not(:last-child) {
  transition: all 0.1s;
  margin-top: 0;
  margin-bottom: 0;
}
.sb-detail-page-sidebar .ant-menu-submenu.no-children .ant-menu-submenu-arrow {
  display: none;
}
.sb-detail-page-sidebar .ant-menu-submenu:hover .ant-menu-submenu-title,
.sb-detail-page-sidebar .ant-menu-item-selected .item-link {
  color: #be986f;
}
.sb-detail-page-sidebar .ant-menu-item:hover,
.sb-detail-page-sidebar .ant-menu-item-active,
.sb-detail-page-sidebar .ant-menu-submenu:hover,
.sb-detail-page-sidebar .ant-menu-submenu.ant-menu-submenu-selected,
.sb-detail-page-sidebar .ant-menu-item.ant-menu-item-selected {
  color: #be986f;
}
.sb-detail-page-sidebar .ant-menu-item:hover .ant-menu-submenu-arrow,
.sb-detail-page-sidebar .ant-menu-item-active .ant-menu-submenu-arrow,
.sb-detail-page-sidebar .ant-menu-submenu:hover .ant-menu-submenu-arrow,
.sb-detail-page-sidebar .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-arrow,
.sb-detail-page-sidebar .ant-menu-item.ant-menu-item-selected .ant-menu-submenu-arrow {
  color: #be986f;
}
.sb-detail-page-sidebar .ant-menu-item:hover .item-icon,
.sb-detail-page-sidebar .ant-menu-item-active .item-icon,
.sb-detail-page-sidebar .ant-menu-submenu:hover .item-icon,
.sb-detail-page-sidebar .ant-menu-submenu.ant-menu-submenu-selected .item-icon,
.sb-detail-page-sidebar .ant-menu-item.ant-menu-item-selected .item-icon {
  display: none;
}
.sb-detail-page-sidebar .ant-menu-item:hover .item-icon-hover,
.sb-detail-page-sidebar .ant-menu-item-active .item-icon-hover,
.sb-detail-page-sidebar .ant-menu-submenu:hover .item-icon-hover,
.sb-detail-page-sidebar .ant-menu-submenu.ant-menu-submenu-selected .item-icon-hover,
.sb-detail-page-sidebar .ant-menu-item.ant-menu-item-selected .item-icon-hover {
  display: inline-block;
}
.sb-detail-page-sidebar .ant-menu .ant-menu-item-active,
.sb-detail-page-sidebar .ant-menu .ant-menu-item-selected,
.sb-detail-page-sidebar .ant-menu .ant-menu-submenu.ant-menu-submenu-active,
.sb-detail-page-sidebar .ant-menu .ant-menu-item:active,
.sb-detail-page-sidebar .ant-menu .ant-menu-submenu-title:active {
  background-color: rgba(203,181,134,0.15);
}
.sb-detail-page-sidebar .ant-menu-inline,
.sb-detail-page-sidebar .ant-menu-item::after {
  border-right: none;
}
.sb-detail-page-sidebar .ant-menu.ant-menu-sub.ant-menu-inline {
  background: #fff;
}
