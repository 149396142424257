.question-detail .ant-row {
  margin: 10px 0;
  padding: 4px 0;
  line-height: 24px;
  flex-wrap: nowrap;
}
.question-detail .ant-row .item-label {
  min-width: 80px;
  font-weight: bold;
}
.question-detail .ant-row .item-value {
  white-space: pre-wrap;
}
.question-detail .ant-row .tag {
  display: inline-block;
  vertical-align: top;
  border-radius: 12px;
  line-height: 14px;
  margin: 0 6px 6px 0;
  padding: 5px 10px;
  border: 1px solid #d9d9d9;
}
.question-detail .ant-row:nth-child(2) {
  margin-bottom: 4px;
}
.question-detail .ant-row:nth-child(3) {
  margin-top: 0px;
}
