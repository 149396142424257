answerStyle()
    .answer-view
        position relative
        padding 8px 20px 24px
        background #F3F8FF
        border-radius 8px
        margin 30px 0 30px 15px
        color #55525E
        font-size 14px
        overflow hidden
        .view-section
            display flex
            align-items top
            margin-top 16px
            .view-icon
                height 17px
                margin-top 5px
                margin-right 8px
            .view-label
                line-height 26px
                color #2A354B
                font-size 16px
                white-space nowrap
            .view-answer, .view-explanation
                line-height 26px
                white-space pre-wrap
        .view-reference
            white-space pre-wrap
            margin-top 16px
            line-height 24px
            overflow-x auto
            &::-webkit-scrollbar-thumb
                background-color rgba(220, 220, 220, 0.8)
            table
                margin-top 20px
                min-width 100%
                tr td
                    border-color #DEECFF
                    height 48px
                    padding 11px 24px
                    white-space nowrap
                    &:first-of-type
                        width 80px
                tr:first-of-type
                    td
                        background #C3DCFF
                        font-weight bold
                        color #2A354B
        .fold-view
            height 26px
        .fold-btn
            position absolute
            bottom 0px
            left 0
            right 0
            width 100%
            padding-top 6px
            line-height 44px
            text-align center
            color #397FFF
            font-size 14px
            background linear-gradient(0deg, #F3F8FF 0%, rgba(243, 248, 255, 0.88) 100%)
            cursor pointer
            .icon-down
                display inline-block
                padding 0 6px
                font-size 14px
                transition 0.1s all
                &.rotate-180
                    transform rotate(180deg)
.exam-page-main
    answerStyle()
