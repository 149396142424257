@import '../../../../../style/mixins.styl'

.simulation-gongjijin
    height 100%
    .page-header
        display flex
        justify-content space-between
        align-items center
        height 80px
        background #1497E2
        box-shadow 0px 0px 10px 0px rgba(61, 41, 0, 0.08)
        padding 0 20px 0 88px
        position relative
        .page-left
            width 42.63%
            .img-logo
                position absolute
                top 20px
                left 30px
            .system-name
                display inline-block
                font-size 22px
                font-weight 700
                color #ffffff
                line-height 39px
        .page-right
            display flex
            .page-middle
                font-size 12px
                color #ffffff
                line-height 25px
                margin-right 60px
            .page-user
                margin-top 8px
                margin-right 60px
                font-size 14px
                color #ffffff
                line-height 24px
                img
                    margin-right 8px
            .page-sign
                margin-right 20px
                .img-sign-out
                    margin-left 20px
                    color #ffffff
                .sign-out
                    text-align center
                    font-size 14px
                    line-height 24px
                    color #ffffff
    .page-content
        height calc(100% - 80px)
    .panel
        border-radius 4px
        background #fff
        box-shadow 0px 0px 10px 0px rgba(61, 41, 0, 0.08)
