.mock-exam-item {
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  margin: 8px;
}
.mock-exam-item .paper-action {
  margin-left: 2em;
}
.mock-exam-item .exam-level {
  margin-right: 2em;
}
.mock-exam-item .paper-name {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  flex: 1;
  text-align: center;
}
.maneuvers-list-page .mock-exam-search-form .search-form-layout {
  margin: 16px 0px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 16px;
  align-items: end;
  padding: 16px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.maneuvers-list-page .mock-exam-search-form .search-form-layout .ant-row.ant-form-item {
  margin-bottom: 8px;
}
.exam-list-item {
  margin-bottom: 8px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 70px 1fr;
  gap: 0 8px;
}
