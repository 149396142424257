.login-page
    .login-layout .login-content
        height 436px
    .ant-form-item-label
        text-align left
    .ant-row.ant-form-item
        margin-bottom 20px
        &.ant-form-item-with-help
            margin-bottom 0
    .login-title
        margin 30px 0
        font-size 24px
        font-weight 700
        text-align center
        color #2a354b
        line-height 31px
    .icon-refresh
        display block
        font-size 19px
        width 32px
        height 32px
        background #e9eaec
        color #AAAEB7
        border-radius 16px
        position absolute
        line-height 32px
        text-align center
        top 0
        right 45px
        cursor pointer
        transition all 0.1s
        &:hover
            background #DBEDFF
            color #007AFF
        &.active
            animation rotating 0.6s infinite linear forwards
    .ant-form-item-explain, .ant-form-item-explain.ant-form-item-explain-error
        line-height 30px
        height 40px
        color #F9302A
    .ks-btn
        width 200px
        height 36px
        border-radius 4px
        font-size 14px
        line-height 22px
        margin 28px 200px
    .login-one
        padding-bottom 30px
    .login-bottom
        font-size 12px
        text-align center
        color #7f8693
        line-height 20px
        b
            font-weight bold
    .ant-form-item-control-input
        display block
        .ant-form-item-control-input-content
            height 32px
            .ant-select-item-empty
                text-align center
            p
                font-size 12px
                text-align left
                color #7f8693
                line-height 20px
    .login-logo
        width 100px
        height 36px
        position absolute
        left 80px
        top 40px
    .ant-col-6
        flex none
        width 70px
        margin-left 80px
.top-popover
    .ant-popover-inner
        width 60px
        height 32px
        background #2A354B
        border-radius 4px
        display flex
        align-items center
    .title
        font-size 14px
        color #ffffff
.top-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow, .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow
    border-color #2A354B
.login-dropdown.ant-select-dropdown
    .ant-select-item-empty
        text-align center
        line-height 50px
@keyframes rotating
    0%
        background #DBEDFF
        color #007AFF
        transform rotate(0)
    100%
        background #DBEDFF
        color #007AFF
        transform rotate(360deg)
