html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6
    margin 0
    padding 0
ul
    list-style none
button, input, select, textarea
    margin 0
html
    box-sizing border-box
*, *:before, *:after
    box-sizing inherit
img, video
    height auto
    max-width 100%
iframe
    border 0
table
    border-collapse collapse
    border-spacing 0
td, th
    padding 0
    text-align justify
input[type='text'], input[type='password'], input[type='number'], textarea
    -webkit-appearance none
a
    color #1890ff
    text-decoration none
    background-color transparent
    outline none
    cursor pointer
    -webkit-transition color 0.3s
    transition color 0.3s
    -webkit-text-decoration-skip objects
a:hover
    color #40a9ff
a:active
    color #096dd9
a:active, a:hover
    text-decoration none
    outline 0
a:focus
    text-decoration none
    outline 0
a[disabled]
    color rgba(0, 0, 0, 0.25)
    cursor not-allowed
    pointer-events none
img
    vertical-align middle
    border-style none
a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea
    -ms-touch-action manipulation
    touch-action manipulation
table
    border-collapse collapse
th
    text-align inherit
input, button, select, optgroup, textarea
    margin 0
    color inherit
    font-size inherit
    font-family inherit
    line-height inherit
button, input
    overflow visible
button, select
    text-transform none
button, html [type='button'], [type='reset'], [type='submit']
    -webkit-appearance button
button::-moz-focus-inner, [type='button']::-moz-focus-inner, [type='reset']::-moz-focus-inner, [type='submit']::-moz-focus-inner
    padding 0
    border-style none
input[type='radio'], input[type='checkbox']
    -webkit-box-sizing border-box
    box-sizing border-box
    padding 0
input[type='date'], input[type='time'], input[type='datetime-local'], input[type='month']
    -webkit-appearance listbox
textarea
    overflow auto
    resize vertical
