.ant-cascader-picker.ks-cascader
    .ant-cascader-input.ant-input
        color #333333
    &:hover, &:focus
        .ant-cascader-input.ant-input
            box-shadow none
.ant-cascader-menu
    padding 8px 0
    border-radius 4px
    height auto
    max-height 304px
    &:not(:first-child)
        margin-left -1px
        border-left 1px solid #f0f0f0
    .ant-cascader-menu-item
        color #666666
        line-height 26px
        &:hover
            background-color rgba(203, 181, 134, 0.15)
        &.ant-cascader-menu-item-active
            color #ffffff
            .anticon.anticon-right
                color #ffffff
