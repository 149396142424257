.ant-modal.submit-success-report-modal
    width 800px !important
    padding-top 20px
    padding-bottom 18px
    height 100%
    .ant-modal-confirm-btns
        display none
    .ant-modal-content
        height 100%
        overflow hidden
    .ant-modal-content .ant-modal-body
        padding 30px 0 16px
        height 100%
        overflow-x hidden
        overflow-y auto
        -webkit-overflow-scrolling touch
        &::-webkit-scrollbar
            width 8px
            height 8px
            background-color transparent
        &::-webkit-scrollbar-track
            background-color transparent
        &::-webkit-scrollbar-thumb
            border-radius 4px
            background-color rgba(220, 220, 220, 0.8)
        .ant-modal-confirm-body .ant-modal-confirm-content
            margin-top 0
            text-align center
            .exam-result-header
                position relative
                background #ffffff
                z-index 1
            .icon-success
                display inline-block
                vertical-align top
                font-size 50px
                line-height 50px
                color #02C882
            .result-text
                display inline-block
                vertical-align top
                font-size 24px
                font-weight bold
                line-height 50px
                padding-left 12px
                color #2A354B
            .result-hint
                font-size 14px
                line-height 24px
                color #C9CCD2
                padding 12px 0 6px
            .footer-buttons
                position absolute
                left 0
                right 0
                bottom 30px
                text-align center
                .ks-btn
                    margin 0 10px
            .report-view
                position relative
                padding 80px 142px 80px
                margin -120px -112px 0
                text-align left
                .report-logo
                    position absolute
                    left 142px
                    top 25px
                    display flex
                    align-items center
                    line-height 22px
                    justify-content center
                    .logo
                        height 20px
                    .separator
                        color #E9EAEC
                        margin 0 12px
                    .logo-name
                        color #2A354B
                        font-size 14px
                        padding-top 3px
                .report-name
                    color #397FFF
                    font-size 32px
                    font-weight bold
                    line-height 42px
                    text-align center
                .section-title
                    font-size 20px
                    font-weight bold
                    line-height 30px
                    color #2A354B
                    margin-top 40px
                    margin-bottom 20px
                    img
                        width 24px
                        margin-right 10px
                .paper-description
                    font-size 14px
                    line-height 22px
                    color #555E6F
                    white-space pre-wrap
                    word-break break-all
                .scores-detail
                    width 100%
                    color #555E6F
                    border 1px solid #E9EAEC
                    td
                        border 1px solid #E9EAEC
                        width calc((100% / 3))
                        text-align center
                        padding 0 24px
                        height 44px
                        font-size 14px
                        &:first-of-type
                            text-align left
                        span:first-of-type
                            color #4DD8A7
                        .my-score
                            font-size 24px
                            font-weight bold
                .result-answer-sheet
                    border 1px solid #E9EAEC
                    padding 20px 24px
                    .legends
                        display flex
                        justify-content flex-end
                        color #85838F
                        div
                            position relative
                            width 78px
                            padding-left 26px
                            line-height 20px
                            &:before
                                content ' '
                                position absolute
                                left 0
                                top 2px
                                width 16px
                                height 16px
                                border-radius 50%
                                border 1px solid
                            &:first-child:before
                                background #4DD8A7
                                border-color #4DD8A7
                            &:nth-child(2):before
                                background #F9302A
                                border-color #F9302A
                            &:last-child:before
                                background #F4F5F6
                                border-color #E3E3E3
                    .answer-section
                        .section-title
                            font-size 16px
                            font-weight bold
                            color #222222
                            margin 20px 0
                        .question-result
                            display inline-block
                            vertical-align top
                            margin-right 10px
                            margin-bottom 14px
                            width 34px
                            height 34px
                            line-height 34px
                            text-align center
                            border-radius 50%
                            font-size 14px
                            color #555E6F
                            background #F4F5F6
                            &.correct
                                color #FFFFFF
                                background #4DD8A7
                            &.wrong
                                color #FFFFFF
                                background #F9302A
