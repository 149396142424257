.sb-detail-page {
  position: relative;
  height: 100%;
  z-index: 0;
}
.sb-detail-page .sb-detail-main {
  position: absolute !important;
  top: 0;
  left: 234px;
  bottom: 0;
  right: 0;
  z-index: -1;
  padding: 20px;
  background: #f4f4f5;
}
.sb-detail-page .panel {
  border-radius: 8px;
  margin-bottom: 20px;
}
.sb-detail-page .panel-title {
  position: relative;
  line-height: 22px;
  padding-top: 20px;
  padding-left: 44px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}
.sb-detail-page .panel-title:before {
  content: ' ';
  position: absolute;
  top: 23px;
  left: 30px;
  width: 4px;
  height: 16px;
  border-radius: 2px;
  background: #be986f;
  display: inline-block;
}
.sb-detail-page .panel-content {
  padding: 0 44px 30px;
}
.sb-detail-page .panel-content .form-tip {
  color: #999;
  font-size: 12px;
  line-height: 14px;
}
.sb-detail-page .bordered-panel {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 20px 20px 10px;
  margin-bottom: 15px;
}
.sb-detail-page .bordered-panel .ant-form-item {
  margin-bottom: 10px;
}
.sb-detail-page .bordered-panel .sub-module .module-name {
  font-weight: bold;
  margin-bottom: 10px;
}
.sb-detail-page .bordered-panel .module-separator {
  margin: 10px 0 20px;
  border-bottom: 1px solid #e4e4e4;
}
.sb-detail-page .bottom-action {
  text-align: center;
}
.sb-detail-page .ant-form .ant-form-item {
  margin-bottom: 15px;
}
.sb-detail-page .ant-picker {
  width: 210px;
}
.sb-detail-page .ant-picker .ant-picker-range .ant-picker-active-bar {
  background: #cbb586;
}
.sb-detail-page .ant-picker:hover,
.sb-detail-page .ant-picker.ant-picker-focused {
  box-shadow: none;
  border-color: #cbb586;
}
.sb-detail-page .ant-checkbox .ant-checkbox-inner {
  border-color: #a09fa3;
}
.sb-detail-page .ant-checkbox:hover .ant-checkbox-inner,
.sb-detail-page .ant-checkbox:focus .ant-checkbox-inner {
  border-color: #cbb586;
}
.sb-detail-page .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background: #cbb586;
  border-color: #cbb586;
}
.sb-detail-page .ant-checkbox.ant-checkbox-checked:after {
  border-color: #cbb586;
}
.sb-detail-page .ant-cascader-picker.ks-cascader:hover .ant-cascader-input.ant-input,
.sb-detail-page .ant-cascader-picker.ks-cascader:focus .ant-cascader-input.ant-input {
  border-color: #cbb586 !important;
}
.sb-detail-page .ant-cascader-picker.ks-cascader.address-cascader,
.sb-detail-page .ant-cascader-picker.ks-cascader.job-cascader {
  width: 320px;
}
.sb-detail-page .alert-warning-view {
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #f2e1be;
  background: #faf5e9;
  line-height: 38px;
  color: rgba(0,0,0,0.65);
  font-size: 14px;
}
.sb-detail-page .alert-warning-view .icon-warn {
  display: inline-block;
  vertical-align: top;
  font-size: 18px;
  margin-left: 16px;
  margin-right: 8px;
  color: #cbb586;
}
.ant-cascader-menus.sb-cascader-popup .ant-cascader-menu-item.ant-cascader-menu-item-active {
  background-color: #cbb586;
}
.ant-picker-dropdown .ant-picker-cell-inner {
  text-align: center;
}
