.ant-message .ant-message-notice.simulation-message
    color #2A354B
    .ant-message-notice-content
        padding 9px 30px
        border 1px solid
        border-radius 4px
        .anticon
            margin-right 12px
    &.success-style .ant-message-notice-content
        background #F2FCF9
        border-color #CCF4E6
    &.error-style .ant-message-notice-content
        background #FEF4F4
        border-color #FED6D4
