.paper-preview .question-module .question-name {
  font-size: 20px;
  line-height: 36px;
  font-weight: bold;
  margin: 20px 0 20px;
}
.paper-preview .question-module .exam-question {
  margin-bottom: 30px;
}
.paper-preview .question-module .exam-question .question-header .question-type {
  display: none;
}
