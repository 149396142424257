.simulation-record-list .ant-table-content table {
  border: 1px solid #e4e4e4;
  border-width: 1px 1px 0 1px;
  border-radius: 4px;
}
.simulation-record-list .ant-table-content table .ant-table-thead > tr > th,
.simulation-record-list .ant-table-content table .ant-table-tbody > tr > td {
  padding: 6px 24px;
  line-height: 36px;
}
.simulation-record-list .ant-table-content table .ant-table-tbody > tr > td {
  border-color: #e4e4e4;
  color: #555550;
}
.simulation-record-list .ant-table-content table .ant-table-thead > tr > th {
  background: #f4f4f5;
  font-weight: bold;
  color: #333;
}
.simulation-record-list .ant-table-content table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgba(57,127,255,0.05);
}
.simulation-record-list .ant-table-content table .operate-targets {
  padding: 5px 0;
  line-height: 26px;
}
.simulation-record-list .ant-table-content table .operate-targets .show-more {
  cursor: pointer;
}
