.confirm-QiFeng-user .ant-modal-body .anticon,
.confirm-QiFeng-user .ant-modal-body .anticon-exclamation-circle {
  display: none;
}
.confirm-QiFeng-user .ant-modal-body .ant-modal-confirm-title {
  font-weight: bold;
  font-size: 18px;
  color: #2a354b;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-status-tips {
  text-align: center;
  color: #ff8217;
  margin: 10px 0 15px 0;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-status-tips .icon-img {
  width: 14px;
  height: 14px;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-status-tips .tips-content {
  margin-left: 8px;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .download-btn .ant-btn {
  width: 100px;
  height: 36px;
  border-radius: 5px;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .download-btn .ant-btn:last-child {
  width: 156px;
  margin-left: 20px;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form {
  border-top: 1px solid #ccc;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-title {
  display: flex;
  background: #f5f5f5;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-title div {
  border: 1px solid #ccc;
  border-right: none;
  border-top: none;
  margin-bottom: 0;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-title div:last-child {
  border-right: 1px solid #ccc;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row {
  display: flex;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row .ant-form-item {
  border: 1px solid #ccc;
  border-right: none;
  border-top: none;
  margin-bottom: 0;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row .ant-form-item:last-child {
  border-right: 1px solid #ccc;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row a,
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row area,
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row button,
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row [role='button'],
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row input:not([type='range']),
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row label,
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row select,
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row summary,
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row textarea {
  border: none;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-status-form .ant-input {
  border-radius: 5px;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn {
  margin-top: 20px;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn {
  height: 36px;
  width: 100px;
  border-radius: 5px;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn:first-child {
  margin: 0 20px 0 300px;
  border-color: #aaaeb7;
  color: #555e6f;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn:first-child:hover {
  color: #1497e2;
  background: #ebf2ff;
  border-color: #1497e2;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn:last-child {
  color: #fff;
  background: #1497e2;
}
.confirm-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.confirm-QiFeng-user .ant-modal-body .ant-modal-confirm-btns {
  display: none;
}
.modal-QiFeng-user .ant-modal-body .anticon,
.modal-QiFeng-user .ant-modal-body .anticon-exclamation-circle {
  display: none;
}
.modal-QiFeng-user .ant-modal-body .ant-modal-confirm-title {
  font-weight: bold;
  font-size: 18px;
  color: #2a354b;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-status-tips {
  text-align: center;
  color: #ff8217;
  margin: 10px 0 15px 0;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-status-tips .icon-img {
  width: 14px;
  height: 14px;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-status-tips .tips-content {
  margin-left: 8px;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .download-btn .ant-btn {
  width: 100px;
  height: 36px;
  border-radius: 5px;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .download-btn .ant-btn:last-child {
  width: 156px;
  margin-left: 20px;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form {
  border-top: 1px solid #ccc;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-title {
  display: flex;
  background: #f5f5f5;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-title div {
  border: 1px solid #ccc;
  border-right: none;
  border-top: none;
  margin-bottom: 0;
  text-align: center;
  height: 30px;
  line-height: 30px;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-title div:last-child {
  border-right: 1px solid #ccc;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row {
  display: flex;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row .ant-form-item {
  border: 1px solid #ccc;
  border-right: none;
  border-top: none;
  margin-bottom: 0;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row .ant-form-item:last-child {
  border-right: 1px solid #ccc;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row a,
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row area,
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row button,
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row [role='button'],
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row input:not([type='range']),
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row label,
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row select,
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row summary,
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-user-form .user-row textarea {
  border: none;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-status-form .ant-input {
  border-radius: 5px;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn {
  margin-top: 20px;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn {
  height: 36px;
  width: 100px;
  border-radius: 5px;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn:first-child {
  margin: 0 20px 0 300px;
  border-color: #aaaeb7;
  color: #555e6f;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn:first-child:hover {
  color: #1497e2;
  background: #ebf2ff;
  border-color: #1497e2;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn:last-child {
  color: #fff;
  background: #1497e2;
}
.modal-QiFeng-user .ant-modal-body .confirm-status .confirm-notice-btn .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.modal-QiFeng-user .ant-modal-footer {
  padding: 0;
}
.modal-QiFeng-user .ant-modal-footer .ant-btn {
  display: none;
}
.confirm-submits .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn {
  float: none;
  display: flex;
  justify-content: center;
  margin-top: 28px;
}
.confirm-submits .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn {
  height: 36px;
  width: 100px;
  margin-left: 20px;
  border-radius: 5px;
}
.confirm-submits .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:first-child {
  border-color: #aaaeb7;
  color: #555e6f;
}
.confirm-submits .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:first-child:hover {
  color: #1497e2;
  background: #ebf2ff;
  border-color: #1497e2;
}
.confirm-submits .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child {
  color: #fff;
  background: #1497e2;
}
.confirm-submits .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.confirm-submits .ant-modal-body .ant-modal-confirm-btns {
  display: none;
}
