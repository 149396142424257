.ant-modal-confirm.sb-confirm .ant-modal-body .ant-modal-confirm-btns {
  float: none;
  display: flex;
  justify-content: center;
  flex-flow: row-reverse;
}
.ant-modal-confirm.sb-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn:first-child {
  color: #333;
  border-color: #999;
}
.ant-modal-confirm.sb-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn:first-child:hover {
  color: #be986f;
  background: #fff;
  border-color: #be986f;
}
.ant-modal-confirm.sb-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn:last-child {
  margin-left: 0;
  margin-right: 20px;
  background: #be986f;
  color: #fff;
  border: none;
}
.ant-modal-confirm.sb-confirm .ant-modal-body .ant-modal-confirm-btns .ant-btn:last-child:hover {
  background: #cbb586;
}
