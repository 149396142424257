@import './variables.styl'

.simulation-geshui-modal .total-form-item
    .ant-form-item-control-input-content > .ant-input, .ant-form-item-label > label[title='小计']
        color #FF8217
.simulation-geshui .tax-panel
    color $fontColor
    article
        background-color #fff
        padding $space
        ul.steps
            margin-bottom 30px
            display flex
            justify-content center
            li.current
                background-color $mainColor
                color #fff
            li
                margin-right 80px
                position relative
                width 220px
                height 40px
                background-color #f4f4f5
                border-radius 4px
                line-height 40px
                text-align center
                &:last-child
                    margin-right 0
            li:not(:last-child)::after
                content '> >'
                position absolute
                right -53px
                color #999999
        h3
            margin-bottom 20px
            font-weight 700
            font-size 16px
            display flex
            align-items center
            .line
                margin-right 10px
                height 16px
                width 4px
                border-radius 2px
                background-color $mainColor
                display inline-block
        table.menu-table
            line-height 26px
            border-collapse separate
            border 1px solid #E4E4E4
            width 100%
            border-radius 4px
            thead
                background #f4f4f5
                font-weight 700
            td, th
                padding 11px 24px
                border-bottom 1px solid #E4E4E4
                border-right 1px solid #E4E4E4
            tr>td:first-child
                color #4285F3
            tr>td:last-child
                border-right none
            tr:last-child>td
                border-bottom none
        button.link
            background none
            color $mainColor
            border none
            cursor pointer
        a
            color $mainColor
            padding 0 6px
        a.text
            color #4285F3
            padding 0
