.ant-notification-notice.exam-message
    position relative
    width 400px
    border-left 6px solid
    box-shadow 0px 6px 26px 0px rgba(42, 53, 75, 0.16)
    background-color #fff
    border-radius 4px
    padding 15px 24px 15px 16px
    line-height 24px
    &.success-style
        border-color #02C882
    &.error-style
        border-color #F9302A
    .ant-notification-notice-message
        margin-left 32px
        margin-bottom 0
    .message-content
        color #2A354B
        font-size 16px
    .iconfont
        position relative
        top -2px
        font-size 18px
        line-height 24px
    .icon-success
        color #02C882
    .icon-error
        color #F9302A
