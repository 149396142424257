.sb-detail-page .sb-input {
  border-color: #e4e4e4;
  outline: none;
  color: #333;
  max-width: 210px;
}
.sb-detail-page .sb-input:placeholder {
  color: #c9ccd2;
}
.sb-detail-page .sb-input:not(.ant-input-disabled):hover,
.sb-detail-page .sb-input:not(.ant-input-disabled):focus {
  border-color: #cbb586;
}
.sb-detail-page .sb-input.ant-input-borderless {
  padding-left: 0;
}
