.exam-table
    td
        position relative
    .pin::before
        content ''
        position absolute
        top -34px
        left -34px
        height 0
        width 0
        border 34px solid transparent !important
        border-right 34px solid #000 !important
        transform rotate(45deg)
    .pin::after
        content '置顶'
        position absolute
        transform rotate(-45deg)
        top 6px
        left 2px
        color white
