@import '../../../../../style/mixins.styl'

.gjj-page
    position relative
    height 100%
    z-index 0
    .gjj-main
        overflow-y scroll
        absolute(0, 0, 0, 234px)
        z-index -1
        padding 0 20px 0 20px
        background #F4F4F5
