.login-page {
  position: relative;
  height: 100%;
  width: 100%;
  background: #f8f9fc;
}
.login-page .login-view {
  background: #fff;
  width: 480px;
  height: 320px;
  text-align: center;
  padding: 30px 90px;
  border-radius: 6px;
  box-shadow: 0 2px 33px rgba(0,104,255,0.12);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login-page .login-view .ant-form-item-explain {
  text-align: left;
}
.login-page .login-view .view-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
}
.login-page .login-view .login-item {
  margin-bottom: 16px;
}
.login-page .login-view .login-form-button {
  width: 148px;
  margin-top: 10px;
}
.login-page .login-view .login-tip {
  color: #999aa0;
}
