.confirm-QiFeng-user
    .ant-modal-body
        .anticon, .anticon-exclamation-circle
            display none
        .ant-modal-confirm-title
            font-weight bold
            font-size 18px
            color #2a354b
        .confirm-status
            .confirm-status-tips
                text-align center
                color #FF8217
                margin 10px 0 15px 0
                .icon-img
                    width 14px
                    height 14px
                .tips-content
                    margin-left 8px
            .download-btn
                .ant-btn
                    width 100px
                    height 36px
                    border-radius 5px
                .ant-btn:last-child
                    width 156px
                    margin-left 20px
            .confirm-user-form
                border-top 1px solid #ccc
                .user-title
                    display flex
                    background #f5f5f5
                    div
                        border 1px solid #ccc
                        border-right none
                        border-top none
                        margin-bottom 0
                        text-align center
                        height 30px
                        line-height 30px
                    div:last-child
                        border-right 1px solid #ccc
                .user-row
                    display flex
                    .ant-form-item
                        border 1px solid #ccc
                        border-right none
                        border-top none
                        margin-bottom 0
                    .ant-form-item:last-child
                        border-right 1px solid #ccc
                    a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea
                        border none
            .confirm-status-form
                .ant-input
                    border-radius 5px
            .confirm-notice-btn
                margin-top 20px
                .ant-btn
                    height 36px
                    width 100px
                    border-radius 5px
                .ant-btn:first-child
                    margin 0 20px 0 300px
                    border-color #AAAEB7
                    color #555E6F
                    &:hover
                        color #1497e2
                        background #EBF2FF
                        border-color #1497e2
                .ant-btn:last-child
                    color #ffffff
                    background #1497e2
                    &:hover
                        color #ffffff
                        background #1497e2
        .ant-modal-confirm-btns
            display none
.modal-QiFeng-user
    .ant-modal-body
        .anticon, .anticon-exclamation-circle
            display none
        .ant-modal-confirm-title
            font-weight bold
            font-size 18px
            color #2a354b
        .confirm-status
            .confirm-status-tips
                text-align center
                color #FF8217
                margin 10px 0 15px 0
                .icon-img
                    width 14px
                    height 14px
                .tips-content
                    margin-left 8px
            .download-btn
                .ant-btn
                    width 100px
                    height 36px
                    border-radius 5px
                .ant-btn:last-child
                    width 156px
                    margin-left 20px
            .confirm-user-form
                border-top 1px solid #ccc
                .user-title
                    display flex
                    background #f5f5f5
                    div
                        border 1px solid #ccc
                        border-right none
                        border-top none
                        margin-bottom 0
                        text-align center
                        height 30px
                        line-height 30px
                    div:last-child
                        border-right 1px solid #ccc
                .user-row
                    display flex
                    .ant-form-item
                        border 1px solid #ccc
                        border-right none
                        border-top none
                        margin-bottom 0
                    .ant-form-item:last-child
                        border-right 1px solid #ccc
                    a, area, button, [role='button'], input:not([type='range']), label, select, summary, textarea
                        border none
            .confirm-status-form
                .ant-input
                    border-radius 5px
            .confirm-notice-btn
                margin-top 20px
                .ant-btn
                    height 36px
                    width 100px
                    border-radius 5px
                .ant-btn:first-child
                    margin 0 20px 0 300px
                    border-color #AAAEB7
                    color #555E6F
                    &:hover
                        color #1497e2
                        background #EBF2FF
                        border-color #1497e2
                .ant-btn:last-child
                    color #ffffff
                    background #1497e2
                    &:hover
                        color #ffffff
                        background #1497e2
    .ant-modal-footer
        padding 0
        .ant-btn
            display none
.confirm-submits
    .ant-modal-body
        .ant-modal-confirm-content
            .confirm-notice-btn
                float none
                display flex
                justify-content center
                margin-top 28px
                .ant-btn
                    height 36px
                    width 100px
                    margin-left 20px
                    border-radius 5px
                .ant-btn:first-child
                    border-color #AAAEB7
                    color #555E6F
                    &:hover
                        color #1497e2
                        background #EBF2FF
                        border-color #1497e2
                .ant-btn:last-child
                    color #ffffff
                    background #1497e2
                    &:hover
                        color #ffffff
                        background #1497e2
        .ant-modal-confirm-btns
            display none
