.exam-question {
  text-align: justify;
}
.exam-question .ant-input:hover,
.exam-question .ant-input:focus,
.exam-question .ant-input:active {
  border-color: #397fff;
}
.exam-question .question-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
}
.exam-question .question-header .question-no {
  flex: 0 0 auto;
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px;
  line-height: 36px;
  color: #397fff;
}
.exam-question .question-header .question-no:after {
  content: '.';
}
.exam-question .question-header .question-type {
  flex: 0 0 auto;
  color: #6076ff;
  line-height: 36px;
}
.exam-question .question-header .question-title {
  flex: 1 1 auto;
  color: #2a354b;
  font-size: 16px;
  line-height: 36px;
}
.exam-question .question-header .question-title .question-score {
  display: inline-block;
  line-height: 22px;
  border: 1px solid #397fff;
  border-radius: 2px;
  color: #397fff;
  font-size: 14px;
  padding: 0 10px;
  margin-left: 5px;
}
.exam-question.child-question .question-header .question-no {
  font-size: 16px;
}
.exam-question.child-question .question-header .question-no:before {
  content: '（';
}
.exam-question.child-question .question-header .question-no:after {
  content: '）.';
}
.exam-question.child-question .question-header .question-type {
  color: #2a354b;
}
.exam-question .question-image {
  height: 260px;
  text-align: center;
  margin: 20px 40px 36px;
}
.exam-question .question-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.exam-question .question-answer {
  margin-top: 20px;
}
.exam-question .question-answer .ant-checkbox + span {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 16px);
  padding-right: 0;
}
.exam-question .question-answer .question-option {
  display: block;
  margin: 0 0 6px 8px;
  line-height: 24px;
  padding: 8px 0 8px 20px;
  border-radius: 4px;
  white-space: nowrap;
}
.exam-question .question-answer .question-option .option-content {
  width: calc(100% - 20px);
  display: inline-block;
  vertical-align: top;
  white-space: pre-wrap;
}
.exam-question .question-answer .question-option:hover {
  background: #f0f8ff;
}
.exam-question .question-answer .question-option .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #d9d9d9;
}
.exam-question .question-answer .question-option .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-input:hover + .ant-checkbox-inner {
  border-color: #397fff;
}
.exam-question .question-answer .question-option.radio-style .ant-checkbox-inner,
.exam-question .question-answer .question-option.radio-style .ant-checkbox::after {
  border-radius: 50%;
}
.exam-question .question-answer .question-option.img-option {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 24px);
  padding: 7px 0 7px 10px;
  margin-left: 17px;
}
.exam-question .question-answer .question-option.img-option span {
  vertical-align: top;
  line-height: 18px;
}
.exam-question .question-answer .question-textarea {
  margin-left: 28px;
}
.exam-question .question-answer .question-textarea textarea {
  line-height: 24px;
  padding: 10px 11px;
}
.exam-question .question-answer .sorting-text,
.exam-question .question-answer .sorting-checkbox {
  display: inline-block;
  vertical-align: top;
}
.exam-question .question-answer .sorting-text {
  width: calc(100% - 28px);
}
.exam-question .question-answer .sorting-checkbox {
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 2px;
  margin-top: 3px;
  margin-right: 10px;
}
.exam-question .question-answer .sorting-checkbox.active {
  background: #397fff;
  color: #fff;
}
.exam-question .question-answer .sorting-checkbox.inactive {
  transition: all 0.3s;
  border: 1px solid #a09fa3;
}
.exam-question .question-answer:hover .sorting-checkbox.inactive {
  border-color: #397fff;
}
.exam-question.question-analyze {
  position: absolute !important;
  top: 67px;
  left: 0;
  bottom: 82px;
  right: 0;
  padding: 25px 0 0 25px;
}
.exam-question.question-analyze.static {
  position: static !important;
  padding: 0;
}
.exam-question.question-analyze .question-quick-locating {
  line-height: 34px;
  padding-left: 23px;
}
.exam-question.question-analyze .question-quick-locating .locating-tag {
  display: inline-block;
  vertical-align: top;
  border: 1px solid #f4f5f6;
  color: #7f8693;
  background: #f4f5f6;
  margin-right: 20px;
  border-radius: 18px;
  min-width: 36px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s;
}
.exam-question.question-analyze .question-quick-locating .locating-tag.top-tag {
  width: 88px;
}
.exam-question.question-analyze .question-quick-locating .locating-tag.completed {
  color: #fff;
  background: #4dd8a7;
  border-color: #4dd8a7;
}
.exam-question.question-analyze .question-quick-locating .locating-tag:hover {
  color: #397fff;
  background: #fff;
  border-color: #397fff;
}
.exam-question.question-analyze .analyze-scroll-wrap {
  padding: 0 32px 40px 0;
  height: calc(100% - 35px);
}
.exam-question.question-analyze .question-case {
  margin: 0 20px 48px 35px;
  padding-top: 14px;
  padding-bottom: 40px;
  border-bottom: 1px solid #e9eaec;
}
.exam-question.question-analyze .question-case h1,
.exam-question.question-analyze .question-case h2,
.exam-question.question-analyze .question-case h3 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 12px;
  color: #2a354b;
}
.exam-question.question-analyze .question-case h1 {
  line-height: 30px;
  font-size: 20px;
}
.exam-question.question-analyze .question-case h2 {
  line-height: 28px;
  font-size: 18px;
}
.exam-question.question-analyze .question-case h3 {
  line-height: 26px;
  font-size: 16px;
}
.exam-question.question-analyze .question-case p {
  line-height: 24px;
  margin-bottom: 8px;
  color: #2a354b;
}
.exam-question.question-analyze .question-case div.media-wrap.image-wrap {
  margin: 20px auto;
  text-align: center;
}
.exam-question.question-analyze .question-list {
  padding-left: 30px;
  margin-right: 20px;
}
.exam-question.question-analyze .question-list .exam-question {
  margin-bottom: 40px;
}
.exam-question.question-analyze .question-list .exam-question .question-case {
  margin: 0;
  padding-bottom: 0;
}
.exam-question.question-analyze .question-list .exam-question .question-answer {
  margin-left: 28px;
}
.exam-question.question-analyze.question-operate .question-operate-child .question-case {
  border-bottom: none;
  margin-bottom: 20px;
}
.exam-question.question-analyze.question-operate .content-separator {
  border-bottom: 1px solid #e9eaec;
  margin: 48px 0;
}
.exam-question.question-analyze.question-operate .question-operate-child:last-child .content-separator {
  display: none;
}
.exam-question.question-analyze.question-operate .answer-view {
  margin-left: 0;
}
