.sb-detail-page .sb-button {
  font-size: 14px;
  color: #333;
  border-color: #999;
  background: transparent;
  width: 60px;
  padding: 0 6px;
  margin: 0 5px;
  transition: all 0.2s;
}
.sb-detail-page .sb-button:hover {
  color: #be986f;
  border-color: #be986f;
}
.sb-detail-page .sb-button.theme {
  color: #be986f;
  border-color: #be986f;
}
.sb-detail-page .sb-button.theme:hover {
  color: #be986f;
  background: #faf5e9;
}
.sb-detail-page .sb-button.ant-btn-primary {
  background: #be986f;
  color: #fff;
  border: none;
}
.sb-detail-page .sb-button.ant-btn-primary:hover {
  background: #cbb586;
}
.sb-detail-page .sb-button.commit-btn,
.sb-detail-page .sb-button.cancel-btn {
  width: 100px;
  height: 36px;
  border-radius: 4px;
  margin: 0 10px;
}
