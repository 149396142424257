.answer-statistics
    width 80%
    .question
        margin-bottom 40px
        .question-type
            width 95%
            display flex
            justify-content space-between
            .question-name
                font-weight bold
                font-size 16px
            .question-score
                margin-bottom 5px
                width 150px
                height 40px
                border 1px solid #000
                display flex
                .get-score
                    display inline-block
                    width 60px
                    height 38px
                    line-height 40px
                    text-align center
                    background #f5f5f5
                    border-right 1px solid #000
                .score
                    display inline-block
                    width 90px
                    height 40px
                    line-height 40px
                    text-align center
                .active
                    display inline-block
                    width 90px
                    height 40px
                    line-height 40px
                    text-align center
                    color red
