@import './variables.styl'
@import './antd.styl'

// 使用二倍图做的雪碧图，所以坐标跟大小都除2
sprite_img()
    background-size 176px 140px
    background-image url('./img/geshui_icons.png')
sprite_img_pos(x, y)
    background-position (x / -2) (y / -2)
ant-modal()
.simulation-geshui-select
    .ant-cascader-menu .ant-cascader-menu-item:hover
        background-color rgba(66, 133, 243, 0.15)
    .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active
        background-color $mainColor
    .ant-select-item-option-selected
        background-color $mainColor !important
        .ant-select-item-option-content
            color #fff !important
    .ant-select-item-option-active
        background-color rgba(66, 133, 243, 0.15)
        .ant-select-item-option-content
            color #666666
    .ant-select-item-option-content
        color #666666
.simulation-geshui
    .icon
        display inline-block
    .logo
        width 38px
        height 47px
        sprite_img_pos(276px, 0)
        sprite_img()
    .bg-nav
        width 24px
        height 24px
        sprite_img()
    .bg-nav-查询统计
        sprite_img_pos(276px, 94px)
    .bg-nav-人员信息采集
        sprite_img_pos(96px, 184px)
    .bg-nav-分类所得申报
        sprite_img_pos(0px, 184px)
    .bg-nav-非居民所得申报
        sprite_img_pos(48px, 184px)
    .bg-nav-税款缴纳
        sprite_img_pos(144px, 184px)
    .bg-nav-退付手续费核对
        sprite_img_pos(192px, 184px)
    .bg-nav-系统设置
        sprite_img_pos(240px, 184px)
    .bg-nav-限售股所得申报
        sprite_img_pos(288px, 184px)
    .bg-nav-优惠备案
        sprite_img_pos(0px, 232px)
    .bg-nav-专项附加扣除信息采集
        sprite_img_pos(48px, 232px)
    .bg-nav-综合所得申报
        sprite_img_pos(96px, 232px)
    .bg-menu
        width 46px
        height 46px
        sprite_img()
    .bg-menu-非居民所得申报
        sprite_img_pos(0px, 0px)
    .bg-menu-分类所得申报
        sprite_img_pos(92px, 0px)
    .bg-menu-人员信息采集
        sprite_img_pos(0px, 92px)
    .bg-menu-税款缴纳
        sprite_img_pos(92px, 92px)
    .bg-menu-专项附加扣除信息采集
        sprite_img_pos(184px, 0px)
    .bg-menu-综合所得申报
        sprite_img_pos(184px, 92px)
    .nav-bar-icon
        width 16px
        height 16px
        sprite_img()
    .nav-bar-icon-代扣代缴
        sprite_img_pos(144px, 232px)
    .nav-bar-icon-单位管理
        sprite_img_pos(176px, 232px)
    .nav-bar-icon-消息中心
        sprite_img_pos(276px, 142px)
    .nav-bar-icon-在线
        sprite_img_pos(240px, 232px)
    .nav-bar-icon-操作手册
        sprite_img_pos(310px, 142px)
    height 100%
    overflow-y hidden
    background-color #F3F3F3
    display grid
    grid-template-columns 270px auto
    grid-template-rows 60px minmax(100px, 1fr)
    grid-template-areas 'header header' 'sidebar wrap'
    .no-answer
        pointer-events none
        opacity 0.7
    header
        grid-area header
        display flex
        justify-content space-between
        align-items center
        padding 0 30px
        background-color $mainColor
        color #fff
        .system-name
            display inline-flex
            align-items center
            .daijiao
                margin-left 30px
                display flex
                flex-direction column
                align-items center
            .system-name-text
                font-size 22px
                font-weight 700
                line-height 29px
                margin-left 10px
        ul
            display inline-flex
            font-size 14px
            li
                margin-left 30px
                display inline-flex
                align-items center
                div.icon
                    margin-right 8px
    aside
        grid-area sidebar
        background-color #ffffff
        box-shadow 0px 0px 10px 0px rgba(61, 41, 0, 0.08)
        border-right 1px solid rgba(61, 41, 0, 0.08)
        nav>ul>li:first-child
            margin-top $space
        nav .nav-item
            position relative
            height 48px
            color #555555
            line-height 48px
            font-size 16px
            padding-left 30px
            display flex
            align-items center
            .icon
                margin-right 14px
            &:hover
                color $mainColor
                background-color rgba(66, 133, 243, 0.15)
                cursor pointer
        .sanjiao::after
            content url('./img/下拉-icon.svg')
            position absolute
            right 20px
        nav .active
            color $mainColor
            background-color rgba(66, 133, 243, 0.15)
    div.wrap
        height 100%
        grid-area wrap
        overflow-y auto
        .full-height
            height 100%
        .breadcrumb
            height 42px
            display flex
            align-items center
            padding 0 $space
            .home-icon
                margin-right 3px
                background-image url('./img/home-icon.svg')
                display inline-block
                width 16px
                height 16px
            .home-icon.active
                background-image url('./img/home-icon-active.svg')
            .breadcrumb-item
                margin-right 1em
                margin-left 1em
                color #333333
                display flex
                align-items center
                &:first-child
                    margin-left 0
                &:hover
                    color $mainColor
                    .home-icon
                        background-image url('./img/home-icon-active.svg')
            .current
                color $mainColor
    // 组件样式
    ant-alert-info()
    ant-tabs-top()
    ant-button()
    .summary
        padding 22px 10px 20px 23px
        border 1px solid #e4e4e4
        border-radius 4px
        .summary-info
            position relative
            color #333333
            margin-bottom $space
            span.title
                margin-right 46px
            span
                font-weight 700
                font-size 16px
                margin-right $space
                &::after
                    content attr(data-unit)
                    font-size 14px
                    font-weight normal
            .line
                display inline-block
                width 4px
                height 16px
                background $mainColor
                position absolute
                left -13px
                top 5px
                border-radius 2px
        .summary-btn-group
            display flex
            justify-content space-between
            button
                margin-right $space
            button:last-child
                margin-right 0
    .ant-table-content tr:hover
        cursor pointer
        td
            background-color #F4F9FF
