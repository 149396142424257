.student-filter
    position relative
    .box
        float right
        display flex
        .question-mark
            width 17px
            height 17px
            border-radius 50%
            border 1px solid rgba(0, 0, 0, 0.45)
            text-align center
            line-height 15px
            margin 8px 15px 0 0
            color rgba(0, 0, 0, 0.45)
        .question-tips
            position absolute
            right 365px
            top 205px
            padding 4px 10px
            border 1px solid #ccc
            border-radius 5px
        .question-active
            display none
        .ant-btn
            margin-right 15px
            width 130px
