.countdown {
  color: #ff8217;
  font-size: 20px;
  line-height: 26px;
  min-width: 104px;
}
.ant-message-notice.countdown-warning .ant-message-notice-content {
  border: 1px solid rgba(255,130,23,0.5);
  background: #fff2e7;
  border-radius: 4px;
  color: #2a354b;
}
.ant-message-notice.countdown-warning .ant-message-notice-content .anticon.anticon-exclamation-circle {
  color: #ff8217;
}
.ant-message-notice.countdown-warning .ant-message-notice-content .confirm-btn {
  display: inline-block;
  width: 80px;
  margin-left: 60px;
  text-align: right;
  color: #397fff;
  font-size: 14px;
  cursor: pointer;
}
