.gjj-page-sidebar
    width 234px
    height 100%
    box-shadow 0px 0px 10px 0px rgba(61, 41, 0, 0.08)
    overflow-x hidden
    padding-bottom 40px
    .ant-menu-submenu-arrow
        color #555555
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title
        height 48px
        line-height 48px
    .ant-menu-item
        height 40px
        line-height 40px
        &.ant-menu-item-disabled, &.ant-menu-item-disabled a
            color #555555 !important
            cursor default
            pointer-events none
            &.ant-menu-item-only-child
                opacity 0.7
    .ant-menu-item, .ant-menu-submenu
        font-size 14px
        color #555555
    .item-link
        color #555555
        padding-left 4px
        &:hover
            color #1497e2
    .ant-menu-submenu-title, .ant-menu-item, .ant-menu-item:not(:last-child)
        transition all 0.1s
        margin-top 0
        margin-bottom 0
    .ant-menu-submenu.no-children .ant-menu-submenu-arrow
        display none
    .ant-menu-submenu:hover .ant-menu-submenu-title, .ant-menu-item-selected .item-link
        color #1497e2
    .ant-menu-item:hover, .ant-menu-item-active, .ant-menu-submenu:hover, .ant-menu-submenu.ant-menu-submenu-selected, .ant-menu-item.ant-menu-item-selected
        color #1497e2
        .ant-menu-submenu-arrow
            color #1497e2
    .ant-menu
        .ant-menu-item-active, .ant-menu-item-selected, .ant-menu-submenu.ant-menu-submenu-active, .ant-menu-item:active, .ant-menu-submenu-title:active
            background-color rgba(20, 151, 226, 0.15)
    .ant-menu-inline, .ant-menu-item::after
        border-right none
    .ant-menu.ant-menu-sub.ant-menu-inline
        background #ffffff
