@import '../../../../style/mixins.styl'

.simulation-modal
    height 100%
    max-width none
    padding 0
    >.ant-modal-content
        border-radius 0
        height 100%
        >.ant-modal-close .ant-modal-close-x
            display inline-block
            width auto
            margin-right 30px
            font-size 14px
            color #ffffff
            height 40px
            line-height 40px
            .close-btn
                position relative
                font-weight normal
                .close-tip-view
                    absolute(100%, -15px, auto, auto)
                    top 100%
                    margin-top 2px
                    right -15px
                    width 154px
                    height 58px
                    cursor default
                    .close-bg
                        width 100%
                        height 100%
                        object-fit contain
                    .close-tip-btn
                        absolute(6px, 0, auto, auto)
                        top 6px
                        right 0
                        width 14px
                        height 12px
                        border-radius 0 2px 2px 2px
                        background #5991F7
                        text-align center
                        cursor pointer
                        .close-tip-btn-icon
                            center()
                            width 8px
                    .close-tip-text
                        position absolute
                        left 15px
                        bottom 6px
                        width 124px
                        line-height 20px
                        font-size 12px
        >.ant-modal-body
            position relative
            height 100%
            padding 0
            .simulation-title
                display flex
                justify-content space-between
                padding 0 30px
                height 40px
                line-height 40px
                color #ffffff
                background #001529
                box-shadow 0px 0px 20px 0px rgba(0, 0, 0, 0.2)
                font-size 16px
                font-weight bold
    .ant-modal-wrap
        text-align center
