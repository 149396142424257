.gjj-default-page {
  height: 100%;
}
.gjj-default-page .default-header {
  font-size: 14px;
  color: #333;
  margin: 8px 0;
}
.gjj-default-page .default-header .current {
  color: #128ed5;
  margin-right: 5px;
}
.gjj-default-page .default-header .header-personal {
  margin-left: 5px;
}
.gjj-default-page .default {
  padding-top: 30px;
}
.gjj-default-page .default-main {
  width: calc(100% - 8px);
  min-height: 450px;
  height: calc(100% - 30px);
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(20,66,149,0.1);
}
.gjj-default-page .default-main .main-tab {
  height: 60px;
  border-bottom: 1px solid #ededed;
}
.gjj-default-page .default-main .main-tab .tab-item {
  font-size: 16px;
  display: inline-block;
  margin-left: 3.82%;
  height: 60px;
  line-height: 80px;
}
.gjj-default-page .default-main .main-tab :nth-child(1) {
  color: #1497e2;
  border-bottom: 2px solid #1497e2;
}
.gjj-default-page .default-main .alert-warn {
  width: 94.78%;
  margin: 30px 0 30px 40px;
  background: rgba(255,130,23,0.1);
  border: 1px solid rgba(255,130,23,0.4);
  border-radius: 4px;
}
.gjj-default-page .default-main .alert-warn .icon-img {
  width: 14px;
  height: 14px;
}
.gjj-default-page .default-main .alert-warn .ant-alert-warning,
.gjj-default-page .default-main .alert-warn .ant-alert-icon {
  color: #ff8217;
}
.gjj-default-page .default-main .warning {
  margin: 0 auto;
}
.gjj-default-page .default-main .main-content {
  width: 100%;
  margin-top: 20px;
}
.gjj-default-page .default-main .main-content .content-item {
  margin: 10px 0 0 3.82%;
  display: flex;
  justify-content: space-between;
}
.gjj-default-page .default-main .main-content .content-item .content-title .content-item-name {
  font-size: 14px;
  display: inline-block;
  margin-left: 5px;
}
.gjj-default-page .default-main .main-from {
  margin-left: 3.82%;
}
.gjj-default-page .default-main .main-from .search-form .ant-input {
  border-radius: 4px;
}
.gjj-default-page .default-main .main-from .ant-btn {
  width: 100px;
  height: 36px;
  margin-right: 20px;
  border-radius: 5px;
}
.gjj-default-page .default-main .main-from .ant-btn:last-child {
  width: 156px;
}
.gjj-default-page .default-main .main-from .submit-btn {
  color: #1497e2;
  border: 1px solid #1497e2;
}
.gjj-default-page .default-main .main-from .submit-btn:first-child {
  background: #1497e2;
  color: #fff;
}
.gjj-default-page .default-main .main-button {
  margin: 20px 0 0 3.82%;
  color: #1497e2;
  cursor: pointer;
}
.gjj-default-page .default-main .search-from {
  margin-top: 30px;
}
.gjj-default-page .default-main .search-from .form-row .form-label {
  margin-left: 70px;
  margin-top: -20px;
}
.gjj-default-page .default-main .search-from .form-row .tips {
  font-size: 12px;
  line-height: 16px;
  color: #999;
  margin-top: 8px;
}
.gjj-default-page .default-main .search-from .form-row .form-labels {
  margin-left: 42px;
}
.gjj-default-page .default-main .search-from .form-row .span-tips {
  font-size: 12px;
  line-height: 16px;
  color: #999;
  margin-top: -12px;
}
.gjj-default-page .default-main .search-from .form-row .ant-btn {
  width: 100px;
  height: 36px;
  margin-right: 20px;
}
.gjj-default-page .default-main .search-from .form-row .submit-btn {
  background: #1497e2;
  color: #fff;
  margin-left: 40px;
}
.gjj-default-page .default-main .search-from .form-radius .ant-btn {
  border-radius: 5px;
}
