.simulation-gongjijin {
  height: 100%;
}
.simulation-gongjijin .page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: #1497e2;
  box-shadow: 0px 0px 10px 0px rgba(61,41,0,0.08);
  padding: 0 20px 0 88px;
  position: relative;
}
.simulation-gongjijin .page-header .page-left {
  width: 42.63%;
}
.simulation-gongjijin .page-header .page-left .img-logo {
  position: absolute;
  top: 20px;
  left: 30px;
}
.simulation-gongjijin .page-header .page-left .system-name {
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  line-height: 39px;
}
.simulation-gongjijin .page-header .page-right {
  display: flex;
}
.simulation-gongjijin .page-header .page-right .page-middle {
  font-size: 12px;
  color: #fff;
  line-height: 25px;
  margin-right: 60px;
}
.simulation-gongjijin .page-header .page-right .page-user {
  margin-top: 8px;
  margin-right: 60px;
  font-size: 14px;
  color: #fff;
  line-height: 24px;
}
.simulation-gongjijin .page-header .page-right .page-user img {
  margin-right: 8px;
}
.simulation-gongjijin .page-header .page-right .page-sign {
  margin-right: 20px;
}
.simulation-gongjijin .page-header .page-right .page-sign .img-sign-out {
  margin-left: 20px;
  color: #fff;
}
.simulation-gongjijin .page-header .page-right .page-sign .sign-out {
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
}
.simulation-gongjijin .page-content {
  height: calc(100% - 80px);
}
.simulation-gongjijin .panel {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(61,41,0,0.08);
}
