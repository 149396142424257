.submit-success-modal
    .ant-modal-confirm-btns
        display none
    .ant-modal-content .ant-modal-body
        padding 60px
        .ant-modal-confirm-body .ant-modal-confirm-content
            margin-top 0
            text-align center
            .icon-success
                font-size 50px
                color #02C882
            .result-text
                font-size 18px
                font-weight bold
                line-height 24px
                margin-top 18px
                margin-bottom 4px
                padding-left 13px
            .result-hint
                font-size 14px
                line-height 24px
                color #C9CCD2
                padding-left 1px
