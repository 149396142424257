@import '../../../../style/mixins.styl'

.exam-question
    text-align justify
    .ant-input
        &:hover, &:focus, &:active
            border-color #397FFF
    .question-header
        display flex
        flex-wrap nowrap
        align-items flex-start
        .question-no
            flex 0 0 auto
            font-size 20px
            font-weight bold
            margin-right 10px
            line-height 36px
            color #397FFF
            &:after
                content '.'
        .question-type
            flex 0 0 auto
            color #6076ff
            line-height 36px
        .question-title
            flex 1 1 auto
            color #2A354B
            font-size 16px
            line-height 36px
            .question-score
                display inline-block
                line-height 22px
                border 1px solid #397FFF
                border-radius 2px
                color #397FFF
                font-size 14px
                padding 0 10px
                margin-left 5px
    &.child-question .question-header
        .question-no
            font-size 16px
            &:before
                content '（'
            &:after
                content '）.'
        .question-type
            color #2A354B
    .question-image
        height 260px
        text-align center
        margin 20px 40px 36px
        img
            height 100%
            width 100%
            object-fit contain
    .question-answer
        margin-top 20px
        .ant-checkbox + span
            display inline-block
            vertical-align top
            width calc(100% - 16px)
            padding-right 0
        .question-option
            display block
            margin 0 0 6px 8px
            line-height 24px
            padding 8px 0 8px 20px
            border-radius 4px
            white-space nowrap
            .option-content
                width calc(100% - 20px)
                display inline-block
                vertical-align top
                white-space pre-wrap
            &:hover
                background #F0F8FF
            .ant-checkbox:not(.ant-checkbox-checked)
                .ant-checkbox-input:focus + .ant-checkbox-inner
                    border-color #D9D9D9
                .ant-checkbox-input:hover + .ant-checkbox-inner
                    border-color #397FFF
            &.radio-style
                .ant-checkbox-inner, .ant-checkbox::after
                    border-radius 50%
            &.img-option
                display inline-block
                vertical-align top
                width calc(50% - 24px)
                padding 7px 0 7px 10px
                margin-left 17px
                span
                    vertical-align top
                    line-height 18px
        .question-textarea
            margin-left 28px
            textarea
                line-height 24px
                padding 10px 11px
        .sorting-text, .sorting-checkbox
            display inline-block
            vertical-align top
        .sorting-text
            width calc(100% - 28px)
        .sorting-checkbox
            width 18px
            height 18px
            line-height 18px
            text-align center
            border-radius 2px
            margin-top 3px
            margin-right 10px
            &.active
                background #397FFF
                color #ffffff
            &.inactive
                transition all 0.3s
                border 1px solid #A09FA3
    .question-answer:hover .sorting-checkbox.inactive
        border-color #397FFF
.exam-question.question-analyze
    absolute(67px, 0, 82px, 0)
    padding 25px 0 0 25px
    &.static
        position static !important
        padding 0
    .question-quick-locating
        line-height 34px
        padding-left 23px
        .locating-tag
            display inline-block
            vertical-align top
            border 1px solid #F4F5F6
            color #7F8693
            background #F4F5F6
            margin-right 20px
            border-radius 18px
            min-width 36px
            font-size 14px
            text-align center
            cursor pointer
            transition all 0.1s
            &.top-tag
                width 88px
            &.completed
                color #ffffff
                background #4DD8A7
                border-color #4DD8A7
            &:hover
                color #397FFF
                background #ffffff
                border-color #397FFF
    .analyze-scroll-wrap
        padding 0 32px 40px 0
        height calc(100% - 35px)
    .question-case
        margin 0 20px 48px 35px
        padding-top 14px
        padding-bottom 40px
        border-bottom 1px solid #E9EAEC
        h1, h2, h3
            font-weight bold
            margin-top 20px
            margin-bottom 12px
            color #2A354B
        h1
            line-height 30px
            font-size 20px
        h2
            line-height 28px
            font-size 18px
        h3
            line-height 26px
            font-size 16px
        p
            line-height 24px
            margin-bottom 8px
            color #2A354B
        div.media-wrap.image-wrap
            margin 20px auto
            text-align center
    .question-list
        padding-left 30px
        margin-right 20px
        .exam-question
            margin-bottom 40px
            .question-case
                margin 0
                padding-bottom 0
            .question-answer
                margin-left 28px
    &.question-operate
        .question-operate-child .question-case
            border-bottom none
            margin-bottom 20px
        .content-separator
            border-bottom 1px solid #E9EAEC
            margin 48px 0
        .question-operate-child:last-child .content-separator
            display none
        .answer-view
            margin-left 0
