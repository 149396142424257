.list-item-paper
    padding 20px
    .detail-information
        .detail-information-title
            font-weight bold
            margin-bottom 10px
        div
            font-size 16px
        .paper-snapshot
            position fixed
            right 80px
            top 60px
        .text-button
            position fixed
            right 70px
            top 110px
    .detail-score
        margin 40px 0 60px 0
        .detail-score-title
            font-weight bold
            margin-bottom 10px
