.exam-info-btn
    font-size 14px
    .icon-notice
        margin-right 6px
        color #FF8217
    span
        color #AAAEB7
        &:hover
            color #397FFF
.exam-info-modal
    .exam-info-view
        text-align center
        .view-title
            color #2A354B
            font-size 24px
            font-weight bold
            line-height 31px
            margin-bottom 40px
        .view-tabs
            .ant-tabs-nav
                margin-bottom 0
                &::before
                    border-bottom none
                .ant-tabs-nav-list
                    .ant-tabs-tab
                        background #E9EAEC
                        border none
                        margin 0
                        color rgba(42, 53, 75, 0.9)
                        .ant-tabs-tab-btn
                            color #2A354B
                        &:nth-child(1)
                            border-radius 4px 0 0 0
                        &:nth-child(2)
                            border-radius 0 4px 0 0
                        &.ant-tabs-tab-active
                            background #397FFF
                            .ant-tabs-tab-btn
                                color #fff
            .ant-tabs-content
                height 231px
                padding-top 20px
                background #F0F8FF
                text-align justify
                color #555E6F
                font-size 14px
                line-height 22px
                white-space pre-wrap
                border-radius 0 4px 4px 4px
                .ant-tabs-tabpane
                    height 100%
                    padding 2px 20px 22px
        .close-btn
            width 200px
            margin 30px auto 6px
