@import '../../../../../style/mixins.styl'

.sb-home-page
    position relative
    height 100%
    z-index 0
    .sidebar.panel
        height 100%
        width 234px
        padding 20px 0
        border-radius 0
        z-index 1
        .sidebar-item
            display block
            line-height 48px
            color #555555
            transition all 0.1s
            font-size 16px
            &:hover
                color #BE986F
                background rgba(203, 181, 134, 0.15)
            &.disabled
                pointer-events none
            img
                width 24px
                margin -2px 14px auto 30px
    .page-right
        absolute(0, 0, 0, 234px)
        background #F4F4F5
        overflow-y scroll
        z-index -1
        .panel-1, .panel-2, .panel-3, .panel-4
            width calc((100% - 100px) * 0.2061)
            height 130px
            display flex
            align-items center
            justify-content center
            .panel-left
                img
                    width 46px
                    height 46px
            .panel-right
                margin-left 16%
                .type-num
                    font-size 30px
                    line-height 42px
                    color #555555
                .type-name
                    font-size 14px
                    line-height 26px
                    color #999999
        .panel-1
            absolute(20px, auto, auto, 20px)
        .panel-2
            absolute(20px, auto, auto, calc((100% - 100px) * 0.2061 + 40px))
        .panel-3
            absolute(170px, auto, auto, 20px)
        .panel-4
            absolute(170px, auto, auto, calc((100% - 100px) * 0.2061 + 40px))
        .panel-5
            absolute(20px, auto, auto, calc((100% - 100px) * 0.4122 + 60px))
            width calc((100% - 100px) * 0.3381)
            height 280px
            background #CBB586
            overflow hidden
            .c-5-img
                absolute(28px, auto, auto, 23px)
                width 183px
                max-width 75%
            .c-6-img
                absolute(auto, -75px, 0, auto)
                width 239px
        .panel-6
            absolute(20px, 12px, auto, calc((100% - 100px) * 0.7503 + 80px))
            height 280px
            padding 14px 20px
            .fake-input
                display flex
                align-items center
                justify-content space-between
                height 32px
                line-height 32px
                padding 0 10px
                border-radius 4px
                background #F4F4F5
                .fake-placeholder
                    color #C3C3C3
                    font-size 14px
                .search-img
                    width 16px
            .search-title
                line-height 26px
                font-size 14px
                color #BE986F
                margin-top 12px
            .search-result
                margin-top 10px
                .result-item
                    line-height 26px
                    margin-bottom 6px
                    textOverflow()
                    span:last-child
                        margin-left 12px
                        color #666666
                    &:nth-child(1) span:first-child
                        color #F9302A
                    &:nth-child(2) span:first-child
                        color #FF8217
                    &:nth-child(3) span:first-child
                        color #FFB517
                    &:nth-child(4) span:first-child
                        color #999999
        .panel-7, .panel-8
            height calc(100% - 320px)
            min-height 260px
            .panel-header
                display flex
                justify-content space-between
                line-height 48px
                border-bottom 1px solid #EDEDED
                padding 0 20px
                .panel-title
                    font-size 16px
                    color #333333
                .panel-action
                    font-size 14px
                    color #999999
        .panel-7
            absolute(320px, auto, auto, 20px)
            width calc((100% - 100px) * 0.4122 + 20px)
        .panel-8
            absolute(320px, 12px, auto, calc((100% - 100px) * 0.4122 + 60px))
            .panel-row
                margin 12px 20px
                line-height 36px
                background #F4F4F5
                .row-item
                    display inline-block
                    vertical-align top
                    width 25%
                    padding-left 28px
                    color #666666
                    font-size 14px
