@import '../../../../../style/mixins.styl'

.sb-detail-page
    position relative
    height 100%
    z-index 0
    .sb-detail-main
        absolute(0, 0, 0, 234px)
        z-index -1
        padding 20px
        background #F4F4F5
    .panel
        border-radius 8px
        margin-bottom 20px
    .panel-title
        position relative
        line-height 22px
        padding-top 20px
        padding-left 44px
        padding-bottom 20px
        font-size 16px
        font-weight bold
        color #333333
        &:before
            content ' '
            position absolute
            top 23px
            left 30px
            width 4px
            height 16px
            border-radius 2px
            background #BE986F
            display inline-block
    .panel-content
        padding 0 44px 30px
        .form-tip
            color #999999
            font-size 12px
            line-height 14px
    .bordered-panel
        border 1px solid #E4E4E4
        border-radius 4px
        padding 20px 20px 10px
        margin-bottom 15px
        .ant-form-item
            margin-bottom 10px
        .sub-module
            .module-name
                font-weight bold
                margin-bottom 10px
        .module-separator
            margin 10px 0 20px
            border-bottom 1px solid #E4E4E4
    .bottom-action
        text-align center
    .ant-form
        .ant-form-item
            margin-bottom 15px
    .ant-picker
        width 210px
        .ant-picker-range
            .ant-picker-active-bar
                background #CBB586
        &:hover, &.ant-picker-focused
            box-shadow none
            border-color #CBB586
    .ant-checkbox
        .ant-checkbox-inner
            border-color #A09FA3
        &:hover, &:focus
            .ant-checkbox-inner
                border-color #CBB586
        &.ant-checkbox-checked
            .ant-checkbox-inner
                background #CBB586
                border-color #CBB586
            &:after
                border-color #CBB586
    .ant-cascader-picker.ks-cascader
        &:hover, &:focus
            .ant-cascader-input.ant-input
                border-color #CBB586 !important
        &.address-cascader, &.job-cascader
            width 320px
    .alert-warning-view
        margin-bottom 10px
        border-radius 4px
        border 1px solid #F2E1BE
        background #FAF5E9
        line-height 38px
        color rgba(0, 0, 0, 0.65)
        font-size 14px
        .icon-warn
            display inline-block
            vertical-align top
            font-size 18px
            margin-left 16px
            margin-right 8px
            color #CBB586
.ant-cascader-menus.sb-cascader-popup
    .ant-cascader-menu-item
        &.ant-cascader-menu-item-active
            background-color #CBB586
.ant-picker-dropdown
    .ant-picker-cell-inner
        text-align center
