.create-exam-modal .ant-modal-content .ant-modal-body {
  padding: 10px;
  height: 300px;
  overflow-y: scroll;
  padding-bottom: 30px;
}
.create-exam-modal .ant-modal-content .ant-modal-body .ant-form-horizontal {
  padding: 0 50px 40px 0;
}
.create-exam-modal .ant-modal-content .ant-modal-body .ant-form-horizontal .ant-row {
  margin-top: 10px;
}
.create-exam-modal .ant-modal-content .ant-modal-body .ant-form-horizontal h3 {
  font-size: 18px;
  font-weight: bold;
  margin-left: 70px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.create-exam-modal .ant-modal-content .ant-modal-body .ant-form-horizontal h3.top-h3 {
  margin-top: 10px;
}
.create-exam-modal .ant-modal-content .ant-modal-body .ant-form-horizontal .ant-form-item {
  margin-bottom: 5px;
}
.create-exam-modal .ant-modal-content .ant-modal-body .right-action {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
}
.create-exam-modal .ant-modal-content .ant-modal-body .right-action .ant-btn {
  width: 100px;
  margin: 30px 0 10px 100px;
}
