.ant-modal.submit-success-report-modal {
  width: 800px !important;
  padding-top: 20px;
  padding-bottom: 18px;
  height: 100%;
}
.ant-modal.submit-success-report-modal .ant-modal-confirm-btns {
  display: none;
}
.ant-modal.submit-success-report-modal .ant-modal-content {
  height: 100%;
  overflow: hidden;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body {
  padding: 30px 0 16px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body::-webkit-scrollbar-track {
  background-color: transparent;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(220,220,220,0.8);
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 0;
  text-align: center;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .exam-result-header {
  position: relative;
  background: #fff;
  z-index: 1;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .icon-success {
  display: inline-block;
  vertical-align: top;
  font-size: 50px;
  line-height: 50px;
  color: #02c882;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .result-text {
  display: inline-block;
  vertical-align: top;
  font-size: 24px;
  font-weight: bold;
  line-height: 50px;
  padding-left: 12px;
  color: #2a354b;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .result-hint {
  font-size: 14px;
  line-height: 24px;
  color: #c9ccd2;
  padding: 12px 0 6px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .footer-buttons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  text-align: center;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .footer-buttons .ks-btn {
  margin: 0 10px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view {
  position: relative;
  padding: 80px 142px 80px;
  margin: -120px -112px 0;
  text-align: left;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .report-logo {
  position: absolute;
  left: 142px;
  top: 25px;
  display: flex;
  align-items: center;
  line-height: 22px;
  justify-content: center;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .report-logo .logo {
  height: 20px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .report-logo .separator {
  color: #e9eaec;
  margin: 0 12px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .report-logo .logo-name {
  color: #2a354b;
  font-size: 14px;
  padding-top: 3px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .report-name {
  color: #397fff;
  font-size: 32px;
  font-weight: bold;
  line-height: 42px;
  text-align: center;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .section-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: #2a354b;
  margin-top: 40px;
  margin-bottom: 20px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .section-title img {
  width: 24px;
  margin-right: 10px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .paper-description {
  font-size: 14px;
  line-height: 22px;
  color: #555e6f;
  white-space: pre-wrap;
  word-break: break-all;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .scores-detail {
  width: 100%;
  color: #555e6f;
  border: 1px solid #e9eaec;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .scores-detail td {
  border: 1px solid #e9eaec;
  width: calc((100% / 3));
  text-align: center;
  padding: 0 24px;
  height: 44px;
  font-size: 14px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .scores-detail td:first-of-type {
  text-align: left;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .scores-detail td span:first-of-type {
  color: #4dd8a7;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .scores-detail td .my-score {
  font-size: 24px;
  font-weight: bold;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet {
  border: 1px solid #e9eaec;
  padding: 20px 24px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .legends {
  display: flex;
  justify-content: flex-end;
  color: #85838f;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .legends div {
  position: relative;
  width: 78px;
  padding-left: 26px;
  line-height: 20px;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .legends div:before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .legends div:first-child:before {
  background: #4dd8a7;
  border-color: #4dd8a7;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .legends div:nth-child(2):before {
  background: #f9302a;
  border-color: #f9302a;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .legends div:last-child:before {
  background: #f4f5f6;
  border-color: #e3e3e3;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .answer-section .section-title {
  font-size: 16px;
  font-weight: bold;
  color: #222;
  margin: 20px 0;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .answer-section .question-result {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  margin-bottom: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  color: #555e6f;
  background: #f4f5f6;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .answer-section .question-result.correct {
  color: #fff;
  background: #4dd8a7;
}
.ant-modal.submit-success-report-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .report-view .result-answer-sheet .answer-section .question-result.wrong {
  color: #fff;
  background: #f9302a;
}
