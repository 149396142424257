@import '../../../../../../style/mixins.styl'

.gjj-default-page
    height 100%
    .default-header
        font-size 14px
        color #333333
        margin 8px 0
        .current
            color #128ED5
            margin-right 5px
        .header-personal
            margin-left 5px
    .default
        padding-top 30px
    .default-main
        width calc(100% - 8px)
        min-height 450px
        height calc(100% - 30px)
        background #ffffff
        border-radius 8px
        box-shadow 0px 0px 10px 0px rgba(20, 66, 149, 0.1)
        .main-tab
            height 60px
            border-bottom 1px solid #ededed
            .tab-item
                font-size 16px
                display inline-block
                margin-left 3.82%
                height 60px
                line-height 80px
            :nth-child(1)
                color #1497e2
                border-bottom 2px solid #1497e2
        .alert-warn
            width 94.78%
            margin 30px 0 30px 40px
            background rgba(255, 130, 23, 0.1)
            border 1px solid rgba(255, 130, 23, 0.4)
            border-radius 4px
            .icon-img
                width 14px
                height 14px
            .ant-alert-warning, .ant-alert-icon
                color #FF8217
        .warning
            margin 0 auto
        .main-content
            width 100%
            margin-top 20px
            .content-item
                margin 10px 0 0 3.82%
                display flex
                justify-content space-between
                .content-title
                    .content-item-name
                        font-size 14px
                        display inline-block
                        margin-left 5px
        .main-from
            margin-left 3.82%
            .search-form
                .ant-input
                    border-radius 4px
            .ant-btn
                width 100px
                height 36px
                margin-right 20px
                border-radius 5px
            .ant-btn:last-child
                width 156px
            .submit-btn
                color #1497E2
                border 1px solid #1497E2
            .submit-btn:first-child
                background #1497E2
                color #ffffff
        .main-button
            margin 20px 0 0 3.82%
            color #1497E2
            cursor pointer
        .search-from
            margin-top 30px
            .form-row
                .form-label
                    margin-left 70px
                    margin-top -20px
                .tips
                    font-size 12px
                    line-height 16px
                    color #999999
                    margin-top 8px
                .form-labels
                    margin-left 42px
                .span-tips
                    font-size 12px
                    line-height 16px
                    color #999999
                    margin-top -12px
                .ant-btn
                    width 100px
                    height 36px
                    margin-right 20px
                .submit-btn
                    background #1497E2
                    color #ffffff
                    margin-left 40px
            .form-radius
                .ant-btn
                    border-radius 5px
