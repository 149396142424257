.ks-btn {
  font-size: 14px;
  color: #555e6f;
  background: #fff;
  border: 1px solid #aaaeb7;
  border-radius: 4px;
  padding: 0 22px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  outline: none;
  user-select: none;
}
.ks-btn .iconfont {
  margin-right: 8px;
  font-size: 18px;
}
.ks-btn .iconfont,
.ks-btn span {
  display: inline-block;
  vertical-align: middle;
}
.ks-btn.bold {
  font-weight: bold;
}
.ks-btn:hover {
  color: #397fff;
  background: #ebf2ff;
  border-color: #397fff;
}
.ks-btn.disabled {
  pointer-events: none;
  color: #c2c2c2;
  background: #fff;
  border-color: #c2c2c2;
}
.ks-btn.text,
.ks-btn.text:hover,
.ks-btn.text.disabled {
  border: none;
  background: transparent;
}
.ks-btn.text {
  color: #397fff;
}
.ks-btn.text:hover {
  color: #619eff;
}
.ks-btn.primary,
.ks-btn.warn,
.ks-btn.danger {
  border: none;
  color: #fff;
}
.ks-btn.primary:hover,
.ks-btn.warn:hover,
.ks-btn.danger:hover,
.ks-btn.primary.disabled,
.ks-btn.warn.disabled,
.ks-btn.danger.disabled {
  border: none;
}
.ks-btn.primary.disabled,
.ks-btn.warn.disabled,
.ks-btn.danger.disabled,
.ks-btn.primary.disabled:hover,
.ks-btn.warn.disabled:hover,
.ks-btn.danger.disabled:hover {
  background: #c2c2c2;
}
.ks-btn.primary {
  background: #397fff;
}
.ks-btn.primary:hover {
  background: #619eff;
}
.ks-btn.warn {
  background: #ff8217;
}
.ks-btn.warn:hover {
  background: #ff9f40;
}
.ks-btn.danger {
  background: #f9302a;
}
.ks-btn.danger:hover {
  background: #ff6054;
}
