.answer-statistics {
  width: 80%;
}
.answer-statistics .question {
  margin-bottom: 40px;
}
.answer-statistics .question .question-type {
  width: 95%;
  display: flex;
  justify-content: space-between;
}
.answer-statistics .question .question-type .question-name {
  font-weight: bold;
  font-size: 16px;
}
.answer-statistics .question .question-type .question-score {
  margin-bottom: 5px;
  width: 150px;
  height: 40px;
  border: 1px solid #000;
  display: flex;
}
.answer-statistics .question .question-type .question-score .get-score {
  display: inline-block;
  width: 60px;
  height: 38px;
  line-height: 40px;
  text-align: center;
  background: #f5f5f5;
  border-right: 1px solid #000;
}
.answer-statistics .question .question-type .question-score .score {
  display: inline-block;
  width: 90px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.answer-statistics .question .question-type .question-score .active {
  display: inline-block;
  width: 90px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #f00;
}
