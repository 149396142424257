.list-menu
    width 80px
    text-align center
    background #fff
    color rgba(0, 0, 0, 0.85)
    padding 4px 0
    border-radius 2px
    box-sizing border-box
    box-shadow 0px 4px 12px 0px rgba(42, 53, 75, 0.3)
    .list-item-menu
        cursor pointer
        width 100%
        line-height 32px
        transition background 0.3s
        &:hover
            background #f5f5f5
