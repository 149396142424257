.countdown
    color #FF8217
    font-size 20px
    line-height 26px
    min-width 104px
.ant-message-notice.countdown-warning .ant-message-notice-content
    border 1px solid rgba(255, 130, 23, 0.5)
    background #FFF2E7
    border-radius 4px
    color #2A354B
    .anticon.anticon-exclamation-circle
        color #FF8217
    .confirm-btn
        display inline-block
        width 80px
        margin-left 60px
        text-align right
        color #397FFF
        font-size 14px
        cursor pointer
