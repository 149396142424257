.exam-page-sidebar {
  padding-bottom: 80px;
}
.exam-page-sidebar .sidebar-title {
  padding: 23px 30px 20px;
  font-size: 20px;
  line-height: 24px;
  color: #222;
}
.exam-page-sidebar .sidebar-catalog {
  padding: 0 22px 40px 30px;
  height: calc(100% - 67px);
  overflow-y: scroll;
}
.exam-page-sidebar .sidebar-catalog .catalog-item {
  border-radius: 8px;
  background: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 72px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-title {
  position: relative;
  display: flex;
  height: 72px;
  line-height: 72px;
  align-items: center;
  cursor: pointer;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-title .item-no {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 6px;
  background: rgba(57,127,255,0.15);
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #2a354b;
  margin: 0 10px 0 24px;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-title .item-type {
  font-size: 16px;
  font-weight: bold;
  color: #2a354b;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-title .item-score {
  font-size: 14px;
  color: #555e6f;
  transition: color 0.3s;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-title .icon-fold,
.exam-page-sidebar .sidebar-catalog .catalog-item .item-title .icon-unfold {
  position: absolute;
  right: 30px;
  color: #397fff;
  cursor: pointer;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-list {
  padding: 19px 25px 25px;
  border-top: 1px solid #fff;
  transition: border-color 0.3s;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-list .list-item {
  display: inline-block;
  vertical-align: top;
  margin: 5px;
  background: #f4f5f6;
  color: #555e6f;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  line-height: 38px;
  cursor: pointer;
  transition: all 0.1s;
  border: 1px solid #f4f5f6;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-list .list-item.completed {
  border-color: #4dd8a7;
  background: #4dd8a7;
  color: #fff;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-list .list-item:hover {
  background: #f4f5f6;
  border-color: #397fff;
  color: #397fff;
}
.exam-page-sidebar .sidebar-catalog .catalog-item .item-list .list-item.current {
  border-color: #397fff;
  background: #397fff;
  color: #fff;
}
.exam-page-sidebar .sidebar-catalog .catalog-item.unfold {
  max-height: 1000px;
  box-shadow: 10px 20px 20px 0px rgba(177,188,199,0.15);
}
.exam-page-sidebar .sidebar-catalog .catalog-item.unfold .item-title .item-score {
  color: #aaaeb7;
}
.exam-page-sidebar .sidebar-catalog .catalog-item.unfold .item-list {
  border-color: #f4f5f6;
}
.exam-page-sidebar .sidebar-footer {
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  height: 80px;
  border-top: 1px solid #e9eaec;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.exam-page-sidebar .sidebar-footer .status-item .item-content {
  display: inline-block;
  vertical-align: top;
  color: #85838f;
  font-size: 14px;
  line-height: 16px;
}
.exam-page-sidebar .sidebar-footer .status-item .item-tag {
  display: inline-block;
  vertical-align: top;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  margin-right: 10px;
}
.exam-page-sidebar .sidebar-footer .status-item:nth-child(1) .item-tag {
  border: 1px solid #aaaeb7;
  background: #f4f5f6;
}
.exam-page-sidebar .sidebar-footer .status-item:nth-child(2) .item-tag {
  background: #4dd8a7;
}
.exam-page-sidebar .sidebar-footer .status-item:nth-child(3) .item-tag {
  background: #397fff;
}
