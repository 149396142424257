.ant-message .ant-message-notice.simulation-message {
  color: #2a354b;
}
.ant-message .ant-message-notice.simulation-message .ant-message-notice-content {
  padding: 9px 30px;
  border: 1px solid;
  border-radius: 4px;
}
.ant-message .ant-message-notice.simulation-message .ant-message-notice-content .anticon {
  margin-right: 12px;
}
.ant-message .ant-message-notice.simulation-message.success-style .ant-message-notice-content {
  background: #f2fcf9;
  border-color: #ccf4e6;
}
.ant-message .ant-message-notice.simulation-message.error-style .ant-message-notice-content {
  background: #fef4f4;
  border-color: #fed6d4;
}
