.ant-cascader-picker.ks-cascader .ant-cascader-input.ant-input {
  color: #333;
}
.ant-cascader-picker.ks-cascader:hover .ant-cascader-input.ant-input,
.ant-cascader-picker.ks-cascader:focus .ant-cascader-input.ant-input {
  box-shadow: none;
}
.ant-cascader-menu {
  padding: 8px 0;
  border-radius: 4px;
  height: auto;
  max-height: 304px;
}
.ant-cascader-menu:not(:first-child) {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-cascader-menu .ant-cascader-menu-item {
  color: #666;
  line-height: 26px;
}
.ant-cascader-menu .ant-cascader-menu-item:hover {
  background-color: rgba(203,181,134,0.15);
}
.ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active {
  color: #fff;
}
.ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active .anticon.anticon-right {
  color: #fff;
}
