@import '../../../../../style/mixins.styl'

.simulation-shebao
    height 100%
    .page-header
        display flex
        justify-content space-between
        align-items center
        height 60px
        line-height 60px
        background #CBB586
        box-shadow 0px 0px 10px 0px rgba(61, 41, 0, 0.08)
        padding 0 30px
        .system-name
            width 80%
            font-size 22px
            font-weight bold
            color #fff
        .header-right
            height 36px
        .img-notice
            display inline-block
            vertical-align top
            margin-top 10px
            width 16px
            margin-right 50px
        .user-wrap
            position relative
            display inline-block
            vertical-align top
            background rgba(255, 255, 255, 0.2)
            border-radius 50%
            width 36px
            height 36px
            .img-user
                width 18px
                center()
    .page-content
        height calc(100% - 60px)
    .panel
        border-radius 4px
        background #fff
        box-shadow 0px 0px 10px 0px rgba(61, 41, 0, 0.08)
