.ant-modal-confirm.confirm-modal .ant-modal-content {
  border-radius: 8px;
}
.ant-modal-confirm.confirm-modal .ant-modal-body {
  padding: 30px 40px;
}
.ant-modal-confirm.confirm-modal .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-title {
  line-height: 18px;
  font-size: 18px;
  font-weight: bold;
  color: #2a354b;
}
.ant-modal-confirm.confirm-modal .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 28px;
  white-space: pre-wrap;
  color: #555e6f;
  font-size: 14px;
  line-height: 24px;
}
.ant-modal-confirm.confirm-modal .ant-modal-body .ant-modal-confirm-btns {
  margin-top: 28px;
}
.ant-modal-confirm.confirm-modal .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  height: 36px;
  width: 100px;
}
.ant-modal-confirm.confirm-modal .ant-modal-body .ant-modal-confirm-btns .ant-btn:first-child {
  border-color: #aaaeb7;
  color: #555e6f;
}
.ant-modal-confirm.confirm-modal .ant-modal-body .ant-modal-confirm-btns .ant-btn:first-child:hover {
  color: #397fff;
  background: #ebf2ff;
  border-color: #397fff;
}
.ant-modal-confirm.confirm-modal .ant-modal-body .ant-modal-confirm-btns .ant-btn:last-child {
  margin-left: 20px;
  background: #397fff;
}
.ant-modal-confirm.confirm-modal .ant-modal-body .ant-modal-confirm-btns .ant-btn:last-child:hover {
  background: #619eff;
}
.ant-modal-confirm.confirm-modal.error-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 16px;
}
.ant-modal-confirm.confirm-modal.error-confirm .error-content {
  text-align: center;
  color: #555e6f;
  line-height: 24px;
}
.ant-modal-confirm.confirm-modal.error-confirm .error-content .error-message {
  font-size: 14px;
  font-weight: bold;
  word-break: keep-all;
}
.ant-modal-confirm.confirm-modal.error-confirm .ant-modal-confirm-btns {
  float: none;
  text-align: center;
}
.ant-modal-confirm.confirm-modal.error-confirm .ant-modal-confirm-btns .ant-btn:first-child {
  display: none;
}
.ant-modal-confirm.confirm-modal.error-confirm .ant-modal-confirm-btns .ant-btn:last-child {
  margin-left: 0;
}
