@import '../../../../../style/mixins.styl'

.choose-paper-modal
    max-width 1000px
    .ant-modal-body
        .right-action
            width 400px
            margin 0 auto
            margin-top 20px
            .ant-btn
                width 100px
                margin-top 30px
                margin-left 50px
        .search-form
            padding 24px
            background #fbfbfb
            border 1px solid #d9d9d9
            margin-bottom 10px
        .action-bar
            width 100%
            height 20px
            .results-num
                float right
        .page-list
            width 100%
            height 400px
            overflow-y scroll
            .list-item
                position relative
                display flex
                align-items center
                line-height 22px
                padding 12px 20px 12px 12px
                border 1px solid #d9d9d9
                margin 10px 0
                height 110px
                color #3b3e4f
                .item-no
                    width 40px
                    font-size 25px
                    margin-right 20px
                .paper-item-detail
                    width 100%
                    cursor pointer
                    .paper-item-content
                        .row-1 span, .row-3 span
                            margin-right 8px
                            font-size 14px
                        .row-2
                            width 80%
                            font-size 18px
                            padding 2px 0
                            line-height 30px
                            textOverflow()
                    .paper-watch-detail
                        .ant-btn
                            float right
                            margin-top -50px
    .ant-pagination
        margin-top 20px
