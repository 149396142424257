.excel-import-view .view-header {
  display: flex;
  align-items: center;
}
.excel-import-view .view-header .view-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 32px;
}
.excel-import-view .view-header .excel-link {
  line-height: 20px;
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}
.excel-import-view .excel-upload {
  position: relative;
  height: 200px;
  display: flex;
  margin-top: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fafafa;
}
.excel-import-view .excel-upload .ant-upload.ant-upload-drag {
  position: absolute;
  top: 0;
  left: 0;
}
.excel-import-view .excel-upload .upload-text {
  color: rgba(0,0,0,0.85);
  font-size: 16px;
}
.excel-import-view .excel-upload .upload-hint {
  color: rgba(0,0,0,0.45);
  font-size: 14px;
}
.excel-import-view .excel-upload .ant-btn {
  margin-top: 10px;
}
