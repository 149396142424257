.br4,
.simulation-geshui-confirm button[class|="ant"],
.simulation-geshui-modal button[class|="ant"],
.simulation-geshui-modal .form-section .ant-select-selector,
.simulation-geshui-modal .form-section .ant-input-number,
.simulation-geshui-modal .form-section .ant-input,
.simulation-geshui-modal .form-section .ant-picker,
.simulation-geshui-modal .form-section .ant-input-number-input,
.simulation-geshui .ant-alert-info,
.simulation-geshui .ant-pagination > li,
.simulation-geshui .ant-table-container,
.simulation-geshui button[class|="ant"] {
  border-radius: 4px;
}
.simulation-geshui-confirm .ant-btn,
.simulation-geshui-modal .ant-btn {
  border-color: #4285f3;
  color: #4285f3;
}
.simulation-geshui-confirm .ant-btn-primary,
.simulation-geshui-modal .ant-btn-primary {
  background-color: #4285f3;
  color: #fdfdfd;
}
.simulation-geshui-confirm .ant-btn[disabled],
.simulation-geshui-modal .ant-btn[disabled] {
  background: #f3f3f3;
  border: 1px solid #e4e4e4;
  color: #c2c2c2;
}
.simulation-geshui-confirm .ant-modal-confirm-btns {
  float: none;
  text-align: center;
}
.simulation-geshui-modal .ant-modal-header {
  padding: 16px 32px;
  border-radius: 8px 8px 0 0;
}
.simulation-geshui-modal .ant-modal-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
}
.simulation-geshui-modal .ant-modal-body {
  padding: 0;
}
.simulation-geshui-modal .ant-modal-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.simulation-geshui-modal .ant-modal-footer .ant-btn {
  height: 36px;
  width: 68px;
}
.simulation-geshui-modal .geshui-modal-form {
  padding: 20px 40px 10px 30px;
  color: #333;
}
.simulation-geshui-modal .person-status-item {
  margin-bottom: 6px;
  justify-content: flex-end;
}
.simulation-geshui-modal .person-status-item .ant-form-item-control {
  flex: initial;
}
.simulation-geshui-modal .form-section {
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 30px;
}
.simulation-geshui-modal .form-section:last-child {
  margin-bottom: 0;
  border-bottom: none;
}
.simulation-geshui-modal .form-section .ant-picker-input > input[disabled],
.simulation-geshui-modal .form-section .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #333;
}
.simulation-geshui-modal .form-section .ant-cascader-picker.ks-cascader.address-cascader {
  width: 100%;
}
.simulation-geshui-modal .form-section .ant-cascader-picker.ks-cascader:hover .ant-cascader-input.ant-input,
.simulation-geshui-modal .form-section .ant-cascader-picker.ks-cascader:focus .ant-cascader-input.ant-input {
  border-color: #4285f3 !important;
}
.simulation-geshui-modal .form-section .ant-input-disabled {
  background-color: #f3f3f3;
}
.simulation-geshui-modal .form-section .ant-input-number {
  width: 100%;
}
.simulation-geshui-modal .form-section .ant-input,
.simulation-geshui-modal .form-section .ant-picker,
.simulation-geshui-modal .form-section .ant-input-number-input {
  width: 100%;
  border-color: #e4e4e4;
  color: #333;
}
.simulation-geshui-modal .form-section .ant-checkbox-wrapper {
  color: #333;
}
.simulation-geshui-modal .form-section .ant-input:hover:not(.ant-input-disabled),
.simulation-geshui-modal .form-section .ant-input:focus(.ant-input-disabled) {
  border-color: #4285f3;
}
.simulation-geshui-modal .form-section .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4285f3;
  border-color: #4285f3;
}
.simulation-geshui-modal .form-section .ant-form-item {
  display: inline-flex;
  width: 50%;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.simulation-geshui-modal .form-section .ant-form-item:nth-child(even) {
  justify-content: flex-end;
}
.simulation-geshui-modal .form-section .ant-form-item-label {
  width: 133px;
}
.simulation-geshui-modal .form-section .ant-form-item-label>label {
  color: #333;
}
.simulation-geshui-modal .form-section .ant-form-item-control {
  flex-grow: 0;
  flex-basis: auto;
  width: 222px;
}
.simulation-geshui-modal .form-section-title {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
}
.simulation-geshui-modal .form-section-title .line {
  height: 16px;
  width: 4px;
  display: inline-block;
  background: #4285f3;
  border-radius: 2px;
  margin-right: 10px;
  vertical-align: bottom;
}
.simulation-geshui-select .ant-cascader-menu .ant-cascader-menu-item:hover {
  background-color: rgba(66,133,243,0.15);
}
.simulation-geshui-select .ant-cascader-menu .ant-cascader-menu-item.ant-cascader-menu-item-active {
  background-color: #4285f3;
}
.simulation-geshui-select .ant-select-item-option-selected {
  background-color: #4285f3 !important;
}
.simulation-geshui-select .ant-select-item-option-selected .ant-select-item-option-content {
  color: #fff !important;
}
.simulation-geshui-select .ant-select-item-option-active {
  background-color: rgba(66,133,243,0.15);
}
.simulation-geshui-select .ant-select-item-option-active .ant-select-item-option-content {
  color: #666;
}
.simulation-geshui-select .ant-select-item-option-content {
  color: #666;
}
.simulation-geshui {
  height: 100%;
  overflow-y: hidden;
  background-color: #f3f3f3;
  display: grid;
  grid-template-columns: 270px auto;
  grid-template-rows: 60px minmax(100px, 1fr);
  grid-template-areas: 'header header' 'sidebar wrap';
}
.simulation-geshui .icon {
  display: inline-block;
}
.simulation-geshui .logo {
  width: 38px;
  height: 47px;
  background-position: -138px 0;
  background-size: 176px 140px;
  background-image: url("./img/geshui_icons.png");
}
.simulation-geshui .bg-nav {
  width: 24px;
  height: 24px;
  background-size: 176px 140px;
  background-image: url("./img/geshui_icons.png");
}
.simulation-geshui .bg-nav-查询统计 {
  background-position: -138px -47px;
}
.simulation-geshui .bg-nav-人员信息采集 {
  background-position: -48px -92px;
}
.simulation-geshui .bg-nav-分类所得申报 {
  background-position: 0px -92px;
}
.simulation-geshui .bg-nav-非居民所得申报 {
  background-position: -24px -92px;
}
.simulation-geshui .bg-nav-税款缴纳 {
  background-position: -72px -92px;
}
.simulation-geshui .bg-nav-退付手续费核对 {
  background-position: -96px -92px;
}
.simulation-geshui .bg-nav-系统设置 {
  background-position: -120px -92px;
}
.simulation-geshui .bg-nav-限售股所得申报 {
  background-position: -144px -92px;
}
.simulation-geshui .bg-nav-优惠备案 {
  background-position: 0px -116px;
}
.simulation-geshui .bg-nav-专项附加扣除信息采集 {
  background-position: -24px -116px;
}
.simulation-geshui .bg-nav-综合所得申报 {
  background-position: -48px -116px;
}
.simulation-geshui .bg-menu {
  width: 46px;
  height: 46px;
  background-size: 176px 140px;
  background-image: url("./img/geshui_icons.png");
}
.simulation-geshui .bg-menu-非居民所得申报 {
  background-position: 0px 0px;
}
.simulation-geshui .bg-menu-分类所得申报 {
  background-position: -46px 0px;
}
.simulation-geshui .bg-menu-人员信息采集 {
  background-position: 0px -46px;
}
.simulation-geshui .bg-menu-税款缴纳 {
  background-position: -46px -46px;
}
.simulation-geshui .bg-menu-专项附加扣除信息采集 {
  background-position: -92px 0px;
}
.simulation-geshui .bg-menu-综合所得申报 {
  background-position: -92px -46px;
}
.simulation-geshui .nav-bar-icon {
  width: 16px;
  height: 16px;
  background-size: 176px 140px;
  background-image: url("./img/geshui_icons.png");
}
.simulation-geshui .nav-bar-icon-代扣代缴 {
  background-position: -72px -116px;
}
.simulation-geshui .nav-bar-icon-单位管理 {
  background-position: -88px -116px;
}
.simulation-geshui .nav-bar-icon-消息中心 {
  background-position: -138px -71px;
}
.simulation-geshui .nav-bar-icon-在线 {
  background-position: -120px -116px;
}
.simulation-geshui .nav-bar-icon-操作手册 {
  background-position: -155px -71px;
}
.simulation-geshui .no-answer {
  pointer-events: none;
  opacity: 0.7;
}
.simulation-geshui header {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background-color: #4285f3;
  color: #fff;
}
.simulation-geshui header .system-name {
  display: inline-flex;
  align-items: center;
}
.simulation-geshui header .system-name .daijiao {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.simulation-geshui header .system-name .system-name-text {
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  margin-left: 10px;
}
.simulation-geshui header ul {
  display: inline-flex;
  font-size: 14px;
}
.simulation-geshui header ul li {
  margin-left: 30px;
  display: inline-flex;
  align-items: center;
}
.simulation-geshui header ul li div.icon {
  margin-right: 8px;
}
.simulation-geshui aside {
  grid-area: sidebar;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(61,41,0,0.08);
  border-right: 1px solid rgba(61,41,0,0.08);
}
.simulation-geshui aside nav>ul>li:first-child {
  margin-top: 20px;
}
.simulation-geshui aside nav .nav-item {
  position: relative;
  height: 48px;
  color: #555;
  line-height: 48px;
  font-size: 16px;
  padding-left: 30px;
  display: flex;
  align-items: center;
}
.simulation-geshui aside nav .nav-item .icon {
  margin-right: 14px;
}
.simulation-geshui aside nav .nav-item:hover {
  color: #4285f3;
  background-color: rgba(66,133,243,0.15);
  cursor: pointer;
}
.simulation-geshui aside .sanjiao::after {
  content: url("./img/下拉-icon.svg");
  position: absolute;
  right: 20px;
}
.simulation-geshui aside nav .active {
  color: #4285f3;
  background-color: rgba(66,133,243,0.15);
}
.simulation-geshui div.wrap {
  height: 100%;
  grid-area: wrap;
  overflow-y: auto;
}
.simulation-geshui div.wrap .full-height {
  height: 100%;
}
.simulation-geshui div.wrap .breadcrumb {
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.simulation-geshui div.wrap .breadcrumb .home-icon {
  margin-right: 3px;
  background-image: url("./img/home-icon.svg");
  display: inline-block;
  width: 16px;
  height: 16px;
}
.simulation-geshui div.wrap .breadcrumb .home-icon.active {
  background-image: url("./img/home-icon-active.svg");
}
.simulation-geshui div.wrap .breadcrumb .breadcrumb-item {
  margin-right: 1em;
  margin-left: 1em;
  color: #333;
  display: flex;
  align-items: center;
}
.simulation-geshui div.wrap .breadcrumb .breadcrumb-item:first-child {
  margin-left: 0;
}
.simulation-geshui div.wrap .breadcrumb .breadcrumb-item:hover {
  color: #4285f3;
}
.simulation-geshui div.wrap .breadcrumb .breadcrumb-item:hover .home-icon {
  background-image: url("./img/home-icon-active.svg");
}
.simulation-geshui div.wrap .breadcrumb .current {
  color: #4285f3;
}
.simulation-geshui .ant-alert-info {
  padding-left: 10px;
  margin-bottom: 20px;
  border-color: #95bbf8;
  background-color: #eff5ff;
}
.simulation-geshui .ant-alert-info .ant-alert-icon {
  color: #4285f3;
}
.simulation-geshui .ant-alert-info .ant-alert-content {
  color: #555;
}
.simulation-geshui .ant-alert-info .ant-alert-content .ant-alert-message,
.simulation-geshui .ant-alert-info .ant-alert-content .ant-alert-description {
  color: inherit;
}
.simulation-geshui .ant-table-thead > tr > th {
  color: #333;
  font-weight: 700;
}
.simulation-geshui .ant-table {
  color: #555;
}
.simulation-geshui .ant-table-selection-column .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4285f3;
  border-color: #4285f3;
}
.simulation-geshui .ant-table.ant-table-bordered > .ant-table-footer,
.simulation-geshui .ant-table-footer {
  display: inline-block;
  position: absolute;
  right: 0;
  border: none;
  background: transparent;
}
.simulation-geshui .ant-table.ant-table-bordered > .ant-table-footer .ant-btn,
.simulation-geshui .ant-table-footer .ant-btn {
  color: #ff8217;
  border-color: #ff8217;
}
.simulation-geshui .ant-pagination > li {
  color: #4285f3;
}
.simulation-geshui .ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #f4f9ff;
}
.simulation-geshui .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  color: #4285f3;
}
.simulation-geshui .ant-tabs-top .ant-tabs-tab-btn {
  color: #333;
}
.simulation-geshui .ant-tabs-top .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4285f3;
}
.simulation-geshui .ant-tabs-top .ant-tabs-nav {
  margin-top: 4px;
  margin-bottom: 20px;
}
.simulation-geshui .ant-btn {
  border-color: #4285f3;
  color: #4285f3;
}
.simulation-geshui .ant-btn-primary {
  background-color: #4285f3;
  color: #fdfdfd;
}
.simulation-geshui .ant-btn[disabled] {
  background: #f3f3f3;
  border: 1px solid #e4e4e4;
  color: #c2c2c2;
}
.simulation-geshui .summary {
  padding: 22px 10px 20px 23px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.simulation-geshui .summary .summary-info {
  position: relative;
  color: #333;
  margin-bottom: 20px;
}
.simulation-geshui .summary .summary-info span.title {
  margin-right: 46px;
}
.simulation-geshui .summary .summary-info span {
  font-weight: 700;
  font-size: 16px;
  margin-right: 20px;
}
.simulation-geshui .summary .summary-info span::after {
  content: attr(data-unit);
  font-size: 14px;
  font-weight: normal;
}
.simulation-geshui .summary .summary-info .line {
  display: inline-block;
  width: 4px;
  height: 16px;
  background: #4285f3;
  position: absolute;
  left: -13px;
  top: 5px;
  border-radius: 2px;
}
.simulation-geshui .summary .summary-btn-group {
  display: flex;
  justify-content: space-between;
}
.simulation-geshui .summary .summary-btn-group button {
  margin-right: 20px;
}
.simulation-geshui .summary .summary-btn-group button:last-child {
  margin-right: 0;
}
.simulation-geshui .ant-table-content tr:hover {
  cursor: pointer;
}
.simulation-geshui .ant-table-content tr:hover td {
  background-color: #f4f9ff;
}
