.ks-btn
    font-size 14px
    color #555E6F
    background #FFFFFF
    border 1px solid #AAAEB7
    border-radius 4px
    padding 0 22px
    height 36px
    text-align center
    cursor pointer
    transition all 0.2s
    outline none
    user-select none
    .iconfont
        margin-right 8px
        font-size 18px
    .iconfont, span
        display inline-block
        vertical-align middle
    &.bold
        font-weight bold
    &:hover
        color #397FFF
        background #EBF2FF
        border-color #397FFF
    &.disabled
        pointer-events none
        color #C2C2C2
        background #FFFFFF
        border-color #C2C2C2
    &.text, &.text:hover, &.text.disabled
        border none
        background transparent
    &.text
        color #397FFF
        &:hover
            color #619EFF
    &.primary, &.warn, &.danger
        border none
        color #FFFFFF
        &:hover, &.disabled
            border none
        &.disabled, &.disabled:hover
            background #C2C2C2
    &.primary
        background #397FFF
        &:hover
            background #619EFF
    &.warn
        background #FF8217
        &:hover
            background #FF9F40
    &.danger
        background #F9302A
        &:hover
            background #FF6054
