.paper-item-detail .row-1 span,
.paper-item-detail .row-3 span {
  margin-right: 8px;
  font-size: 14px;
}
.paper-item-detail .row-2 {
  font-size: 18px;
  padding: 2px 0;
  line-height: 30px;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
