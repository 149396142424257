.login-page .login-layout .login-content {
  height: 436px;
}
.login-page .ant-form-item-label {
  text-align: left;
}
.login-page .ant-row.ant-form-item {
  margin-bottom: 20px;
}
.login-page .ant-row.ant-form-item.ant-form-item-with-help {
  margin-bottom: 0;
}
.login-page .login-title {
  margin: 30px 0;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #2a354b;
  line-height: 31px;
}
.login-page .icon-refresh {
  display: block;
  font-size: 19px;
  width: 32px;
  height: 32px;
  background: #e9eaec;
  color: #aaaeb7;
  border-radius: 16px;
  position: absolute;
  line-height: 32px;
  text-align: center;
  top: 0;
  right: 45px;
  cursor: pointer;
  transition: all 0.1s;
}
.login-page .icon-refresh:hover {
  background: #dbedff;
  color: #007aff;
}
.login-page .icon-refresh.active {
  animation: rotating 0.6s infinite linear forwards;
}
.login-page .ant-form-item-explain,
.login-page .ant-form-item-explain.ant-form-item-explain-error {
  line-height: 30px;
  height: 40px;
  color: #f9302a;
}
.login-page .ks-btn {
  width: 200px;
  height: 36px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 22px;
  margin: 28px 200px;
}
.login-page .login-one {
  padding-bottom: 30px;
}
.login-page .login-bottom {
  font-size: 12px;
  text-align: center;
  color: #7f8693;
  line-height: 20px;
}
.login-page .login-bottom b {
  font-weight: bold;
}
.login-page .ant-form-item-control-input {
  display: block;
}
.login-page .ant-form-item-control-input .ant-form-item-control-input-content {
  height: 32px;
}
.login-page .ant-form-item-control-input .ant-form-item-control-input-content .ant-select-item-empty {
  text-align: center;
}
.login-page .ant-form-item-control-input .ant-form-item-control-input-content p {
  font-size: 12px;
  text-align: left;
  color: #7f8693;
  line-height: 20px;
}
.login-page .login-logo {
  width: 100px;
  height: 36px;
  position: absolute;
  left: 80px;
  top: 40px;
}
.login-page .ant-col-6 {
  flex: none;
  width: 70px;
  margin-left: 80px;
}
.top-popover .ant-popover-inner {
  width: 60px;
  height: 32px;
  background: #2a354b;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.top-popover .title {
  font-size: 14px;
  color: #fff;
}
.top-popover.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  border-color: #2a354b;
}
.login-dropdown.ant-select-dropdown .ant-select-item-empty {
  text-align: center;
  line-height: 50px;
}
@-moz-keyframes rotating {
  0% {
    background: #dbedff;
    color: #007aff;
    transform: rotate(0);
  }
  100% {
    background: #dbedff;
    color: #007aff;
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotating {
  0% {
    background: #dbedff;
    color: #007aff;
    transform: rotate(0);
  }
  100% {
    background: #dbedff;
    color: #007aff;
    transform: rotate(360deg);
  }
}
@-o-keyframes rotating {
  0% {
    background: #dbedff;
    color: #007aff;
    transform: rotate(0);
  }
  100% {
    background: #dbedff;
    color: #007aff;
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  0% {
    background: #dbedff;
    color: #007aff;
    transform: rotate(0);
  }
  100% {
    background: #dbedff;
    color: #007aff;
    transform: rotate(360deg);
  }
}
