.gjj-QiFeng-page
    .QiFeng-header
        font-size 14px
        color #333333
        margin 8px 0
        .current
            color #128ED5
            margin-right 5px
        .header-personal
            margin-left 5px
    .QiFeng-main
        width 100%
        height 100%
        background #ffffff
        padding 30px
        border-radius 8px
        box-shadow 0px 0px 10px 0px rgba(20, 66, 149, 0.1)
        .main-header
            .search-form
                pointer-events none
                border-bottom 1px solid #ededed
                .form-row
                    margin-top -5px
                .form-btn
                    margin-bottom 20px
                    .ant-btn
                        width 100px
                        height 36px
                        border-radius 4px
                    .ant-btn:first-child
                        background #1497e2
                        color #ffffff
                    .submit-btn
                        margin 0 16px 0 20px
                    .form-tagging
                        color #999999
                        font-size 12px
        .main-button
            margin 20px 0
            .ant-btn
                width 100px
                height 36px
                margin-right 20px
                border-radius 4px
                &:hover
                    color #1497e2
                    background #ffffff
                    border-color #1497e2
            .btn-four
                width 114px
            .btn-five
                width 128px
        .alert-warn
            background rgba(255, 130, 23, 0.1)
            border 1px solid rgba(255, 130, 23, 0.4)
            border-radius 4px
            .icon-img
                width 14px
                height 14px
            .ant-alert-warning, .ant-alert-icon
                color #FF8217
        .main-table
            .success
                color #02C882
            .fail
                color red
            .ant-table-thead
                .ant-table-cell
                    font-weight 700
            .show-more
                cursor pointer
            .ant-pagination
                margin 16px 45%
.gjj-warning .ant-message-notice-content
    border 1px solid #FFE6D1
    background #FFF9F3
    border-radius 4px
    .anticon
        svg
            color #FF8217
