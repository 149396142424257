.question-detail
    .ant-row
        margin 10px 0
        padding 4px 0
        line-height 24px
        flex-wrap nowrap
        .item-label
            min-width 80px
            font-weight bold
        .item-value
            white-space pre-wrap
        .tag
            display inline-block
            vertical-align top
            border-radius 12px
            line-height 14px
            margin 0 6px 6px 0
            padding 5px 10px
            border 1px solid #d9d9d9
        &:nth-child(2)
            margin-bottom 4px
        &:nth-child(3)
            margin-top 0px
