.sb-detail-page .sb-select.ant-select {
  border-color: #e4e4e4;
  outline: none;
  user-select: none;
  color: #333;
  width: 210px;
}
.sb-detail-page .sb-select.ant-select:hover .ant-select-selector,
.sb-detail-page .sb-select.ant-select.ant-select-focused .ant-select-selector {
  border-color: #cbb586;
  box-shadow: none;
}
.sb-detail-page .sb-select.ant-select .ant-select-selection-search input:placeholder {
  color: #c9ccd2;
}
.sb-detail-page .sb-select.ant-select .ant-select-dropdown {
  top: 33px;
}
.sb-detail-page .sb-select.ant-select .ant-select-item.ant-select-item-option {
  color: #666;
}
.sb-detail-page .sb-select.ant-select .ant-select-item.ant-select-item-option:hover,
.sb-detail-page .sb-select.ant-select .ant-select-item.ant-select-item-option.ant-select-item-option-active {
  background: rgba(203,181,134,0.15);
}
.sb-detail-page .sb-select.ant-select .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  background: #cbb586;
  color: #fff;
}
