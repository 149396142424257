@import '../../../style/mixins.styl'

.exam-page-header
    display flex
    color #ffffff
    .exam-name
        padding-left 30px
        font-size 24px
        font-weight bold
        textOverflow()
        .icon-paper
            width 32px
            margin-right 15px
    .user
        padding 0 25px 0 65px
        display flex
        align-items center
        .icon-container
            width 36px
            height 36px
            border-radius 18px
            background rgba(138, 187, 255, 0.56)
            padding 6px 8px
            .icon-user
                width 20px
                height 22px
        .user-info
            padding 0 36px 0 12px
            .username
                font-size 16px
                padding-bottom 4px
                line-height 21px
            .user-ticket
                font-size 13px
                line-height 17px
                opacity 0.8
        .logout-btn
            color rgba(255, 255, 255, 0.8)
            font-size 12px
            .icon-quit
                display inline-block
                vertical-align middle
                margin-right 8px
                font-weight bold
