.exam-page-main .view-header {
  height: 67px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.exam-page-main .view-header .header-right {
  display: flex;
}
.exam-page-main .view-header .remain-time {
  display: flex;
  align-items: center;
  color: #2a354b;
}
.exam-page-main .view-header .remain-time .icon-clock {
  font-weight: bold;
  font-size: 17px;
}
.exam-page-main .view-header .remain-time .time-tip {
  margin: 0 10px 0 7px;
}
.exam-page-main .view-header .submit-btn {
  margin: 0 40px;
  width: 100px;
}
.exam-page-main .view-question {
  border: solid #e9eaec;
  border-width: 1px 0 1px 0;
  height: calc(100% - 147px);
  overflow-y: scroll;
  padding: 25px 32px 40px 25px;
}
.exam-page-main .view-footer {
  position: relative;
  height: 80px;
  text-align: right;
  padding-right: 30px;
}
.exam-page-main .view-footer .ks-btn {
  margin: 16px 10px;
  width: 100px;
}
.exam-page-main .view-footer .ks-btn.disabled {
  pointer-events: auto;
  cursor: default;
}
.exam-page-main .view-footer .answer-btn {
  position: absolute;
  left: 30px;
  width: 126px;
  padding: 0 10px;
  color: #397fff;
  border-color: #397fff;
}
.confirm-submit-modal .confirm-submit-view {
  color: #555e6f;
  font-size: 14px;
  line-height: 24px;
}
.confirm-submit-modal .confirm-submit-view b {
  font-weight: bold;
}
.confirm-submit-modal .confirm-submit-view .paper-status {
  padding: 12px 0;
}
.confirm-submit-modal .confirm-submit-view .paper-status span {
  margin-right: 24px;
}
.confirm-submit-modal .confirm-submit-view .paper-status span b {
  margin: 0 10px;
}
.confirm-submit-modal .confirm-submit-view .paper-status span:nth-child(1) b {
  color: #02c882;
}
.confirm-submit-modal .confirm-submit-view .paper-status span:nth-child(2) b {
  color: #f9302a;
}
