.paper-snapshot .empty {
  color: rgba(0,0,0,0.25);
}
.paper-snapshot .paper-snapshot-preview-container {
  min-height: 200px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  padding: 20px 25px 40px;
}
.paper-snapshot .paper-snapshot-preview-container .paper-item-detail {
  border: none;
}
.paper-snapshot .paper-snapshot-preview-container .paper-description {
  border-radius: 4px;
  background: #d9d9d9;
  padding: 2px 8px 10px;
  margin: 20px 0 40px;
}
.paper-snapshot .paper-snapshot-preview-container .paper-description .view-name {
  font-weight: bold;
  font-size: 16px;
  line-height: 36px;
}
.paper-snapshot .paper-snapshot-preview-container .paper-description .view-content {
  font-size: 14px;
  line-height: 20px;
}
