.geshuiText {
  line-height: 1.5;
}
.geshuiText div {
  margin-bottom: 14px;
  color: #2a354b;
}
.geshuiText table {
  color: #555;
  border-collapse: separate;
  border-radius: 4px;
  margin-top: 14px;
  margin-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  font-size: 14px;
}
.geshuiText table th {
  background-color: #f4f4f5;
  font-size: 14px;
  font-weight: 700;
  color: #333;
}
.geshuiText table th,
.geshuiText table td {
  padding: 11px 24px;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}
.geshuiText table tr>td:last-child {
  border-right: none;
}
.geshuiText table tr:last-child>td {
  border-bottom: none;
}
