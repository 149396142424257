.page-layout
    height 100%
    .search-form
        padding 24px
        background #fbfbfb
        border 1px solid #d9d9d9
        margin-top 16px
        margin-bottom 10px
    .ant-pagination
        text-align right
        margin-top 20px
        padding-bottom 40px
    main
        position absolute
        left 200px
        top 0
        bottom 0
        right 0
        padding 28px 40px 40px
    .page-header
        display flex
        align-items center
        .ant-breadcrumb
            width 100%
        .ant-btn
            margin-left 10px
    .page-title
        width 100%
        font-size 28px
        font-weight bold
        color #000
    .page-content-container
        border 1px solid #d9d9d9
        margin-top 10px
        padding 10px 24px
    .page-list-container
        .action-bar
            .ant-btn
                margin-right 10px
            .selects-num
                display inline-block
                vertical-align middle
            .results-num
                float right
                line-height 32px
        .list-item
            position relative
            display flex
            align-items center
            line-height 22px
            padding 12px 20px 12px 12px
            border 1px solid #d9d9d9
            margin 10px 0
            height 110px
            color #3B3E4F
            .ant-checkbox-wrapper
                width 16px
            .item-no
                font-size 24px
                font-weight bold
                padding 0 12px
                min-width 54px
                text-align center
                white-space nowrap
            .item-detail
                width calc(100% - 70px)
            .link-btn
                position absolute
                top 10px
                right 10px
            .row-action
                position absolute
                bottom 10px
                right 10px
                .ant-btn
                    margin-left 10px
    .ant-form-item-explain.ant-form-item-explain-error
        white-space pre-wrap
    .ant-radio-group
        margin 0 0 10px
        .ant-radio-wrapper
            line-height 32px
            .ant-radio
                vertical-align middle
    .item-extra
        color rgba(0, 0, 0, 0.45)
        font-size 14px
        margin-left 6px
