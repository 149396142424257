.create-detail-modal .ant-modal-content .ant-modal-body {
  width: 100%;
  padding: 0;
  padding-bottom: 30px;
}
.create-detail-modal .ant-modal-content .ant-modal-body .ant-form-horizontal {
  height: 300px;
  overflow-y: scroll;
  padding-bottom: 40px;
}
.create-detail-modal .ant-modal-content .ant-modal-body .ant-form-horizontal .ant-row {
  margin-top: 10px;
}
.create-detail-modal .ant-modal-content .ant-modal-body .ant-form-horizontal h3 {
  font-size: 18px;
  font-weight: bold;
  margin-left: 70px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.create-detail-modal .ant-modal-content .ant-modal-body .ant-form-horizontal h3.top-h3 {
  margin-top: 10px;
}
.create-detail-modal .ant-modal-content .ant-modal-body .ant-form-horizontal .ant-form-item {
  margin-bottom: 5px;
}
.create-detail-modal .ant-modal-content .ant-modal-body .right-action {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  background: #fff;
}
.create-detail-modal .ant-modal-content .ant-modal-body .right-action .ant-btn {
  width: 100px;
  margin-top: 30px;
  margin-left: 100px;
}
