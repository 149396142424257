.page-layout {
  height: 100%;
}
.page-layout .search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  margin-top: 16px;
  margin-bottom: 10px;
}
.page-layout .ant-pagination {
  text-align: right;
  margin-top: 20px;
  padding-bottom: 40px;
}
.page-layout main {
  position: absolute;
  left: 200px;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 28px 40px 40px;
}
.page-layout .page-header {
  display: flex;
  align-items: center;
}
.page-layout .page-header .ant-breadcrumb {
  width: 100%;
}
.page-layout .page-header .ant-btn {
  margin-left: 10px;
}
.page-layout .page-title {
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  color: #000;
}
.page-layout .page-content-container {
  border: 1px solid #d9d9d9;
  margin-top: 10px;
  padding: 10px 24px;
}
.page-layout .page-list-container .action-bar .ant-btn {
  margin-right: 10px;
}
.page-layout .page-list-container .action-bar .selects-num {
  display: inline-block;
  vertical-align: middle;
}
.page-layout .page-list-container .action-bar .results-num {
  float: right;
  line-height: 32px;
}
.page-layout .page-list-container .list-item {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 12px 20px 12px 12px;
  border: 1px solid #d9d9d9;
  margin: 10px 0;
  height: 110px;
  color: #3b3e4f;
}
.page-layout .page-list-container .list-item .ant-checkbox-wrapper {
  width: 16px;
}
.page-layout .page-list-container .list-item .item-no {
  font-size: 24px;
  font-weight: bold;
  padding: 0 12px;
  min-width: 54px;
  text-align: center;
  white-space: nowrap;
}
.page-layout .page-list-container .list-item .item-detail {
  width: calc(100% - 70px);
}
.page-layout .page-list-container .list-item .link-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.page-layout .page-list-container .list-item .row-action {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.page-layout .page-list-container .list-item .row-action .ant-btn {
  margin-left: 10px;
}
.page-layout .ant-form-item-explain.ant-form-item-explain-error {
  white-space: pre-wrap;
}
.page-layout .ant-radio-group {
  margin: 0 0 10px;
}
.page-layout .ant-radio-group .ant-radio-wrapper {
  line-height: 32px;
}
.page-layout .ant-radio-group .ant-radio-wrapper .ant-radio {
  vertical-align: middle;
}
.page-layout .item-extra {
  color: rgba(0,0,0,0.45);
  font-size: 14px;
  margin-left: 6px;
}
