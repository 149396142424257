.task-and-records {
  height: 100%;
}
.task-and-records .view-tabs {
  line-height: 40px;
  background: rgba(0,21,41,0.08);
}
.task-and-records .view-tabs .tab {
  display: inline-block;
  vertical-align: top;
  color: #333;
  font-size: 16px;
  width: 124px;
  text-align: center;
  cursor: pointer;
}
.task-and-records .view-tabs .tab.active {
  color: #001529;
  font-weight: bold;
  background: #fff;
}
.task-and-records .view-tabs .operation-tip {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  color: #999;
  font-size: 12px;
}
.task-and-records .view-tabs .operation-tip .finger-img {
  width: 12px;
  margin-right: 4px;
}
.task-and-records .view-content {
  height: calc(100% - 40px);
  padding: 22px 30px 40px;
  font-size: 14px;
  color: #333;
}
.task-and-records .view-content .task-content {
  color: #333;
  font-size: 14px;
  line-height: 24px;
}
.task-and-records .view-content .task-content div {
  margin-bottom: 14px;
}
.task-and-records .view-content .task-content table {
  border: 1px solid #e4e4e4;
  border-width: 1px 0 0 1px;
  border-radius: 4px;
  border-collapse: separate;
  margin-bottom: 14px;
}
.task-and-records .view-content .task-content table th,
.task-and-records .view-content .task-content table td {
  padding: 6px 24px;
  line-height: 36px;
  border: 1px solid #e4e4e4;
  border-width: 0 1px 1px 0;
}
.task-and-records .view-content .task-content table th {
  background: #f4f4f5;
  font-weight: bold;
  color: #333;
}
.task-and-records .view-content .task-content table th:not([colspan='2']) {
  border-bottom: none;
}
.task-and-records .view-content .task-content table td {
  border-color: #e4e4e4;
  color: #555550;
}
