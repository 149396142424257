.form-question-list .list-wrap
    display flex
    .left-side
        width 120px
        .type-btn-list
            .ant-btn
                border-radius 0
                transition none
            .ant-btn + .ant-btn
                margin-top -1px
            .ant-btn:last-child
                margin-bottom 10px
        .add-btn
            margin-bottom 6px
            border-radius 4px
        .score-btn
            border-radius 4px
            width 110px
        .ant-btn:hover
            z-index 100
        .ant-btn.selected
            background #1890ff
            color #ffffff
            border-color #1890ff
    .right-side
        flex-grow 1
        border 1px solid #D9D9D9
