.exam-info-page
    .confirm-title
        font-size 24px
        font-weight bold
        text-align center
        color #2a354b
        line-height 31px
    .ks-btn
        width 200px
        border-radius 4px
        font-size 14px
    .exam-info-logout
        color #ffffff
        font-size 14px
        cursor pointer
        position absolute
        right 40px
        top 40px
        i
            margin-right 8px
