.exam-page-main .answer-view {
  position: relative;
  padding: 8px 20px 24px;
  background: #f3f8ff;
  border-radius: 8px;
  margin: 30px 0 30px 15px;
  color: #55525e;
  font-size: 14px;
  overflow: hidden;
}
.exam-page-main .answer-view .view-section {
  display: flex;
  align-items: top;
  margin-top: 16px;
}
.exam-page-main .answer-view .view-section .view-icon {
  height: 17px;
  margin-top: 5px;
  margin-right: 8px;
}
.exam-page-main .answer-view .view-section .view-label {
  line-height: 26px;
  color: #2a354b;
  font-size: 16px;
  white-space: nowrap;
}
.exam-page-main .answer-view .view-section .view-answer,
.exam-page-main .answer-view .view-section .view-explanation {
  line-height: 26px;
  white-space: pre-wrap;
}
.exam-page-main .answer-view .view-reference {
  white-space: pre-wrap;
  margin-top: 16px;
  line-height: 24px;
  overflow-x: auto;
}
.exam-page-main .answer-view .view-reference::-webkit-scrollbar-thumb {
  background-color: rgba(220,220,220,0.8);
}
.exam-page-main .answer-view .view-reference table {
  margin-top: 20px;
  min-width: 100%;
}
.exam-page-main .answer-view .view-reference table tr td {
  border-color: #deecff;
  height: 48px;
  padding: 11px 24px;
  white-space: nowrap;
}
.exam-page-main .answer-view .view-reference table tr td:first-of-type {
  width: 80px;
}
.exam-page-main .answer-view .view-reference table tr:first-of-type td {
  background: #c3dcff;
  font-weight: bold;
  color: #2a354b;
}
.exam-page-main .answer-view .fold-view {
  height: 26px;
}
.exam-page-main .answer-view .fold-btn {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 6px;
  line-height: 44px;
  text-align: center;
  color: #397fff;
  font-size: 14px;
  background: linear-gradient(0deg, #f3f8ff 0%, rgba(243,248,255,0.88) 100%);
  cursor: pointer;
}
.exam-page-main .answer-view .fold-btn .icon-down {
  display: inline-block;
  padding: 0 6px;
  font-size: 14px;
  transition: 0.1s all;
}
.exam-page-main .answer-view .fold-btn .icon-down.rotate-180 {
  transform: rotate(180deg);
}
.paper-print-wrap {
  height: 100%;
}
.paper-print-wrap .paper-detail-page {
  height: 100%;
}
.paper-print-wrap .page-header {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 28px 0 0 40px;
  background: #fff;
}
.paper-print-wrap .page-header .ant-btn {
  margin-left: 20px;
}
.paper-print-wrap .page-content {
  height: calc(100% - 40px);
  overflow-y: auto;
  padding: 28px 40px 40px;
}
.paper-print-wrap .answer-view {
  position: relative;
  padding: 8px 20px 24px;
  background: #f3f8ff;
  border-radius: 8px;
  margin: 30px 0 30px 15px;
  color: #55525e;
  font-size: 14px;
  overflow: hidden;
}
.paper-print-wrap .answer-view .view-section {
  display: flex;
  align-items: top;
  margin-top: 16px;
}
.paper-print-wrap .answer-view .view-section .view-icon {
  height: 17px;
  margin-top: 5px;
  margin-right: 8px;
}
.paper-print-wrap .answer-view .view-section .view-label {
  line-height: 26px;
  color: #2a354b;
  font-size: 16px;
  white-space: nowrap;
}
.paper-print-wrap .answer-view .view-section .view-answer,
.paper-print-wrap .answer-view .view-section .view-explanation {
  line-height: 26px;
  white-space: pre-wrap;
}
.paper-print-wrap .answer-view .view-reference {
  white-space: pre-wrap;
  margin-top: 16px;
  line-height: 24px;
  overflow-x: auto;
}
.paper-print-wrap .answer-view .view-reference::-webkit-scrollbar-thumb {
  background-color: rgba(220,220,220,0.8);
}
.paper-print-wrap .answer-view .view-reference table {
  margin-top: 20px;
  min-width: 100%;
}
.paper-print-wrap .answer-view .view-reference table tr td {
  border-color: #deecff;
  height: 48px;
  padding: 11px 24px;
  white-space: nowrap;
}
.paper-print-wrap .answer-view .view-reference table tr td:first-of-type {
  width: 80px;
}
.paper-print-wrap .answer-view .view-reference table tr:first-of-type td {
  background: #c3dcff;
  font-weight: bold;
  color: #2a354b;
}
.paper-print-wrap .answer-view .fold-view {
  height: 26px;
}
.paper-print-wrap .answer-view .fold-btn {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
  padding-top: 6px;
  line-height: 44px;
  text-align: center;
  color: #397fff;
  font-size: 14px;
  background: linear-gradient(0deg, #f3f8ff 0%, rgba(243,248,255,0.88) 100%);
  cursor: pointer;
}
.paper-print-wrap .answer-view .fold-btn .icon-down {
  display: inline-block;
  padding: 0 6px;
  font-size: 14px;
  transition: 0.1s all;
}
.paper-print-wrap .answer-view .fold-btn .icon-down.rotate-180 {
  transform: rotate(180deg);
}
.paper-print-wrap .answer-view {
  -webkit-print-color-adjust: exact;
}
.paper-print-wrap .answer-view .view-section .view-icon {
  display: none;
}
@media print {
  .paper-print-wrap {
    height: auto;
  }
  .paper-print-wrap .paper-detail-page {
    height: 100%;
  }
  .paper-print-wrap .page-header {
    display: none;
  }
  .paper-print-wrap .paper-preview-container {
    border: none;
  }
  .paper-print-wrap .page-content {
    height: auto;
  }
}
@media print {
  body {
    overflow: auto;
  }
}
