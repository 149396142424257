.add-question-modal {
  max-width: 1000px;
}
.add-question-modal .ant-modal-body {
  padding: 24px 0 20px 24px;
}
.add-question-modal .search-form {
  margin-right: 24px;
}
.add-question-modal .action-bar {
  margin-top: 10px;
  height: 40px;
}
.add-question-modal .action-bar .selects-num {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.add-question-modal .action-bar .right-action {
  float: right;
  margin-right: 24px;
}
.add-question-modal .action-bar .right-action .ant-btn {
  margin-left: 10px;
}
.add-question-modal .page-list-container {
  position: relative;
  height: 400px;
  padding-right: 16px;
  overflow-y: scroll;
}
.add-question-modal .page-list-container .ant-pagination {
  text-align: right;
}
.add-question-modal .page-list-container .list-item {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 8px 20px 12px 12px;
  border: 1px solid #d9d9d9;
  margin: 10px 0;
  height: 100px;
  color: #3b3e4f;
}
.add-question-modal .page-list-container .list-item .ant-checkbox-wrapper {
  width: 16px;
}
.add-question-modal .page-list-container .list-item .item-no {
  font-size: 24px;
  font-weight: bold;
  padding: 0 12px;
  min-width: 54px;
  text-align: center;
  white-space: nowrap;
}
.add-question-modal .page-list-container .list-item .item-detail {
  width: calc(100% - 160px);
}
.add-question-modal .page-list-container .list-item .row-1 span {
  margin-right: 8px;
  font-size: 14px;
}
.add-question-modal .page-list-container .list-item .row-1 .item-type {
  color: #6076ff;
}
.add-question-modal .page-list-container .list-item .row-1 .ant-btn {
  position: absolute !important;
  top: 10px;
  left: auto;
  bottom: auto;
  right: 10px;
}
.add-question-modal .page-list-container .list-item .row-2 {
  font-size: 18px;
  line-height: 30px;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
.add-question-modal .page-list-container .list-item .row-3 .ant-tag {
  margin-bottom: 4px;
}
