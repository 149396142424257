@font-face {
  font-family: "iconfont"; /* Project id 2458855 */
  src: url('iconfont.woff2?t=1702002499482') format('woff2'),
       url('iconfont.woff?t=1702002499482') format('woff'),
       url('iconfont.ttf?t=1702002499482') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back:before {
  content: "\e719";
}

.icon-default-icon:before {
  content: "\e619";
}

.icon-fold:before {
  content: "\e718";
}

.icon-close:before {
  content: "\e711";
}

.icon-unfold:before {
  content: "\e712";
}

.icon-clock:before {
  content: "\e713";
}

.icon-warn:before {
  content: "\e714";
}

.icon-success:before {
  content: "\e715";
}

.icon-error:before {
  content: "\e716";
}

.icon-notice:before {
  content: "\e717";
}

.icon-down:before {
  content: "\e70b";
}

.icon-choose:before {
  content: "\e70c";
}

.icon-quit:before {
  content: "\e70d";
}

.icon-enlarge:before {
  content: "\e70e";
}

.icon-refresh:before {
  content: "\e70f";
}

.icon-logo:before {
  content: "\e710";
}

