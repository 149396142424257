
.create-detail-modal
    .ant-modal-content
        .ant-modal-body
            width 100%
            padding 0
            padding-bottom 30px
            .ant-form-horizontal
                height 300px
                overflow-y scroll
                padding-bottom 40px
                .ant-row
                    margin-top 10px
                h3
                    font-size 18px
                    font-weight bold
                    margin-left 70px
                    margin-top 30px
                    margin-bottom 10px
                    &.top-h3
                        margin-top 10px
                .ant-form-item
                    margin-bottom 5px
            .right-action
                position absolute
                left 0
                bottom 10px
                width 100%
                background #fff
                .ant-btn
                    width 100px
                    margin-top 30px
                    margin-left 100px
