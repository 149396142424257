.page-sidebar
    position absolute
    left 0
    top 0
    bottom 0
    width 200px
    border-right 1px solid #F0F0F7
    padding-top 24px
    a
        position relative
        display block
        line-height 40px
        text-align center
        color #000
        margin 10px 0
        &:after
            position absolute
            top 0
            right 0
            bottom 0
            border-right 3px solid #1890ff
            transform scaleY(0.0001)
            opacity 0
            content ''
        &:hover
            color #1890ff
        &.active
            color #1890ff
            background #e6f7ff
            &:after
                transform scale(1)
                opacity 1
                transition transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1)
    .bottom-bar
        position absolute
        bottom 40px
        text-align center
        width 100%
        .logout
            color #1890ff
            cursor pointer
