.login-info .confirm-title {
  margin: 30px 0 50px;
}
.login-info .confirm-content .blue-border {
  float: left;
  width: 4px;
  height: 16px;
  background: #397fff;
  border-radius: 2px;
  margin: 3px 10px 0 30px;
}
.login-info .confirm-content .second-title {
  font-size: 16px;
  font-weight: bold;
  color: #2a354b;
  line-height: 21px;
}
.login-info .information {
  margin-bottom: 28px;
}
.login-info .information .line {
  margin: 6px 10px 0 44px;
  font-size: 14px;
  line-height: 24px;
  color: #2a354b;
  display: flex;
}
.login-info .information .line .line-name {
  width: 70px;
  display: inline-block;
}
.login-info .information .line .spacing {
  width: 434px;
  display: inline-block;
  color: #555e6f;
  margin-left: 8px;
}
.login-info .ks-btn {
  margin: 18px 200px 40px 200px;
}
