.simulation-record-list
    .ant-table-content
        table
            border 1px solid #E4E4E4
            border-width 1px 1px 0 1px
            border-radius 4px
            .ant-table-thead > tr > th, .ant-table-tbody > tr > td
                padding 6px 24px
                line-height 36px
            .ant-table-tbody > tr > td
                border-color #E4E4E4
                color #555550
            .ant-table-thead > tr > th
                background #F4F4F5
                font-weight bold
                color #333333
            .ant-table-tbody > tr.ant-table-row:hover > td
                background rgba(57, 127, 255, 0.05)
            .operate-targets
                padding 5px 0
                line-height 26px
                .show-more
                    cursor pointer
