.paper-detail-page .list-item-wrap {
  margin-top: 10px;
  padding: 12px 16px;
  border: 1px solid #d9d9d9;
}
.paper-detail-page .list-item-wrap .row-2 {
  padding: 6px 0;
}
.paper-detail-page .paper-detail {
  margin-top: 20px;
}
.paper-detail-page .paper-preview-container {
  min-height: 200px;
  margin-top: 10px;
  border: 1px solid #d9d9d9;
  padding: 10px 25px;
}
