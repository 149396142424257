.question-detail-page .question-preview-container {
  margin-top: 10px;
  padding: 30px 25px;
  border: 1px solid #d9d9d9;
}
.question-detail-page .question-preview-container .question-header .question-no,
.question-detail-page .question-preview-container .question-header .question-score,
.question-detail-page .question-preview-container .question-header .question-quick-locating {
  display: none;
}
.question-detail-page .question-preview-container .question-list .question-header .question-no {
  display: inline;
}
.question-detail-page .question-detail {
  margin-top: 20px;
}
