.fullscreen-button {
  display: none;
  position: absolute;
  right: 12px;
  bottom: 120px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  font-size: 14px;
  color: #fff;
  background: #397fff;
  box-shadow: 0px 4px 12px 0px rgba(42,53,75,0.3);
  cursor: pointer;
}
.fullscreen-button.show {
  display: block;
  transition: all 0.2s;
}
.fullscreen-button.show:hover {
  background: #619eff;
}
