@import 'reset'

html, body
	-ms-text-size-adjust 100%
	-webkit-text-size-adjust 100%
	height 100%
	width 100%
	text-align justify
	word-break break-all
html, body, pre
	font-family 'MicrosoftYaHei', 'MicrosoftYaHei-Bold', 'San Francisco', 'PingFang SC', 'Droid Sans', 'Hiragino Sans GB', 'Heiti SC' !important
body
	font-size 14px
	overflow hidden
#app
	background #fff
	height 100%
	margin 0 auto
	.mobile-header .fixed-box
		margin 0 auto
.ant-input:focus
	box-shadow none !important
.ant-modal
	.ant-modal-content
		border-radius 8px
.bf-container, .braft-output-content
	font-size 16px
	color #2A354B
	h1, h2, h3
		font-weight bold
		margin-top 20px
		margin-bottom 12px
		color #2A354B
	h1
		line-height 30px
		font-size 20px
	h2
		line-height 28px
		font-size 18px
	h3
		line-height 26px
		font-size 16px
	.bf-dropdown.headings-dropdown .dropdown-content .dropdown-content-inner .menu .menu-item
		&:nth-child(4), &:nth-child(5), &:nth-child(6)
			display none
	.bf-media .bf-image
		float none !important
		text-align center
		margin 20px 0
.scrollbar
	overflow-y auto
	-webkit-overflow-scrolling touch
.scrollbar::-webkit-scrollbar
	width 8px
	height 8px
	background-color transparent
.scrollbar.hide-bar::-webkit-scrollbar
	display none
.scrollbar::-webkit-scrollbar-track
	background-color transparent
.scrollbar::-webkit-scrollbar-thumb
	border-radius 4px
	background-color transparent
.scrollbar:hover::-webkit-scrollbar-thumb
	background-color rgba(220, 220, 220, 0.8)
.scrollbar.deep:hover::-webkit-scrollbar-thumb
	background-color rgba(206, 206, 206, 1)
