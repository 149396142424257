@import '../../../ks/exam/components/Answer.styl'

.paper-print-wrap
    height: 100%
    .paper-detail-page
        height: 100%
    .page-header
        height: 60px
        display: flex
        align-items: center
        padding: 28px 0 0 40px
        background: #fff
        .ant-btn
            margin-left: 20px
    .page-content
        height: calc(100% - 40px)
        overflow-y: auto
        padding: 28px 40px 40px
    answerStyle()
    .answer-view
        -webkit-print-color-adjust: exact
    .answer-view .view-section
        .view-icon
            display: none
    @media print
        height: auto
        .paper-detail-page
            height: 100%
        .page-header
            display: none
        .paper-preview-container
            border: none
        .page-content
            height: auto
@media print
    body
        overflow: auto
