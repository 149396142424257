.sb-detail-page .sb-input
    border-color #E4E4E4
    outline none
    color #333333
    max-width 210px
    &:placeholder
        color #C9CCD2
    &:not(.ant-input-disabled)
        &:hover, &:focus
            border-color #CBB586
    &.ant-input-borderless
        padding-left 0
