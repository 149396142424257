@import '../../../../style/mixins.styl'

.add-question-modal
    max-width 1000px
    .ant-modal-body
        padding 24px 0 20px 24px
    .search-form
        margin-right 24px
    .action-bar
        margin-top 10px
        height 40px
        .selects-num
            display inline-block
            vertical-align middle
            margin-right 10px
        .right-action
            float right
            margin-right 24px
            .ant-btn
                margin-left 10px
    .page-list-container
        position relative
        height 400px
        padding-right 16px
        overflow-y scroll
        .ant-pagination
            text-align right
        .list-item
            position relative
            display flex
            align-items center
            line-height 22px
            padding 8px 20px 12px 12px
            border 1px solid #d9d9d9
            margin 10px 0
            height 100px
            color #3B3E4F
            .ant-checkbox-wrapper
                width 16px
            .item-no
                font-size 24px
                font-weight bold
                padding 0 12px
                min-width 54px
                text-align center
                white-space nowrap
            .item-detail
                width calc(100% - 160px)
            .row-1
                span
                    margin-right 8px
                    font-size 14px
                .item-type
                    color #6076FF
                .ant-btn
                    absolute(10px, 10px, auto, auto)
            .row-2
                font-size 18px
                line-height 30px
                textOverflow()
            .row-3 .ant-tag
                margin-bottom 4px
