.loading
    display flex
    align-items center
    position absolute
    top 50%
    left 50%
    height 40px
    transform translate(-50%, -50%)
    color #3b3e4f
    .loading-text
        padding-left 10px
        font-size 16px
        color #aaaaaa
