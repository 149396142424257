html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
button,
input,
select,
textarea {
  margin: 0;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
img,
video {
  height: auto;
  max-width: 100%;
}
iframe {
  border: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
td,
th {
  padding: 0;
  text-align: justify;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
a {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #40a9ff;
}
a:active {
  color: #096dd9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0,0,0,0.25);
  cursor: not-allowed;
  pointer-events: none;
}
img {
  vertical-align: middle;
  border-style: none;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
html,
body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  width: 100%;
  text-align: justify;
  word-break: break-all;
}
html,
body,
pre {
  font-family: 'MicrosoftYaHei', 'MicrosoftYaHei-Bold', 'San Francisco', 'PingFang SC', 'Droid Sans', 'Hiragino Sans GB', 'Heiti SC' !important;
}
body {
  font-size: 14px;
  overflow: hidden;
}
#app {
  background: #fff;
  height: 100%;
  margin: 0 auto;
}
#app .mobile-header .fixed-box {
  margin: 0 auto;
}
.ant-input:focus {
  box-shadow: none !important;
}
.ant-modal .ant-modal-content {
  border-radius: 8px;
}
.bf-container,
.braft-output-content {
  font-size: 16px;
  color: #2a354b;
}
.bf-container h1,
.braft-output-content h1,
.bf-container h2,
.braft-output-content h2,
.bf-container h3,
.braft-output-content h3 {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 12px;
  color: #2a354b;
}
.bf-container h1,
.braft-output-content h1 {
  line-height: 30px;
  font-size: 20px;
}
.bf-container h2,
.braft-output-content h2 {
  line-height: 28px;
  font-size: 18px;
}
.bf-container h3,
.braft-output-content h3 {
  line-height: 26px;
  font-size: 16px;
}
.bf-container .bf-dropdown.headings-dropdown .dropdown-content .dropdown-content-inner .menu .menu-item:nth-child(4),
.braft-output-content .bf-dropdown.headings-dropdown .dropdown-content .dropdown-content-inner .menu .menu-item:nth-child(4),
.bf-container .bf-dropdown.headings-dropdown .dropdown-content .dropdown-content-inner .menu .menu-item:nth-child(5),
.braft-output-content .bf-dropdown.headings-dropdown .dropdown-content .dropdown-content-inner .menu .menu-item:nth-child(5),
.bf-container .bf-dropdown.headings-dropdown .dropdown-content .dropdown-content-inner .menu .menu-item:nth-child(6),
.braft-output-content .bf-dropdown.headings-dropdown .dropdown-content .dropdown-content-inner .menu .menu-item:nth-child(6) {
  display: none;
}
.bf-container .bf-media .bf-image,
.braft-output-content .bf-media .bf-image {
  float: none !important;
  text-align: center;
  margin: 20px 0;
}
.scrollbar {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.scrollbar.hide-bar::-webkit-scrollbar {
  display: none;
}
.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: transparent;
}
.scrollbar:hover::-webkit-scrollbar-thumb {
  background-color: rgba(220,220,220,0.8);
}
.scrollbar.deep:hover::-webkit-scrollbar-thumb {
  background-color: #cecece;
}
