@import '../../../style/mixins.styl'

.exam-page
    height 100%
    overflow-x auto
    .page-container
        position relative
        height 100%
        min-width 1024px
        .exam-page-header
            absolute(0, 0, auto, 0)
            height 80px
            background #397FFF
        .exam-page-sidebar
            absolute(80px, auto, 0, 0)
            width 410px
            background #F3F8FF
        .exam-page-main
            absolute(80px, 0, 0, 410px)
