.confirm-QiFeng-status
    .ant-modal-body
        .confirm-status
            .confirm-status-tips
                text-align center
                color #FF8217
                margin-bottom 16px
                .icon-img
                    width 14px
                    height 14px
                .tips-content
                    margin-left 8px
            .confirm-status-form
                .ant-input
                    border-radius 5px
                .confirm-notice-btn
                    margin-top 20px
                    .ant-btn
                        height 36px
                        width 100px
                        border-radius 5px
                    .ant-btn:first-child
                        margin 0 20px 0 220px
                        border-color #AAAEB7
                        color #555E6F
                        &:hover
                            color #1497e2
                            background #EBF2FF
                            border-color #1497e2
                    .ant-btn:last-child
                        color #ffffff
                        background #1497e2
                        &:hover
                            color #ffffff
                            background #1497e2
        .ant-modal-confirm-btns
            display none
.confirm-careful
    margin-top 80px
    .ant-modal-body
        .ant-modal-confirm-content
            .success
                color #555555
            .confirm-notice-btn
                margin-top 28px
                .ant-btn
                    height 36px
                    width 100px
                    border-radius 5px
                .ant-btn:last-child
                    color #ffffff
                    margin-left 90px
                    background #1497e2
                    &:hover
                        color #ffffff
                        background #1497e2
        .ant-modal-confirm-btns
            display none
