.simulation-record-item
    .back-btn
        padding 0
    .ant-row
        flex-wrap nowrap
    .item-label, .item-value
        font-size 14px
        color #333333
        line-height 26px
        margin-bottom 10px
    .item-label
        font-weight bold
        width 70px
        white-space nowrap
    .item-value .operate-obj
        display inline-block
        vertical-align top
        &:not(:last-child):after
            content '、'
    .ant-table-wrapper
        display inline-block
        .ant-table table
            width auto
            .ant-table-thead > tr > th, .ant-table-tbody > tr > td
                padding 11px 24px
                line-height 26px
