.sb-detail-page .sb-detail-default-page.panel
    margin-bottom 0
.sb-detail-default-page
    padding-bottom 20px
    min-height 100%
    .default-name
        font-size 24px
        font-weight 700
        text-align center
        color #2a354b
        line-height 31px
        padding 52px 0 63px 0
    .default-title
        font-size 14px
        color #666666
        line-height 24px
        padding-left 11.74%
    .default-content
        margin-top 20px
        display flex
        justify-content space-between
        .function-1, .function-2
            width 34.64%
            display flex
            .function-num
                width 30px
                height 30px
                background #cbb586
                border-radius 15px
                color #fff
                line-height 30px
                text-align center
            .function-introduce
                width 88.36%
                margin-left 1.14%
                .introduce-title
                    font-size 18px
                    color #333333
                    line-height 24px
                .introduce-content
                    margin-top 8px
                    font-size 14px
                    color #666666
                    line-height 22px
        .function-1
            margin-left 11.74%
        .function-2
            margin-right 13.46%
