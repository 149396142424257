.form-question-list .list-wrap {
  display: flex;
}
.form-question-list .list-wrap .left-side {
  width: 120px;
}
.form-question-list .list-wrap .left-side .type-btn-list .ant-btn {
  border-radius: 0;
  transition: none;
}
.form-question-list .list-wrap .left-side .type-btn-list .ant-btn + .ant-btn {
  margin-top: -1px;
}
.form-question-list .list-wrap .left-side .type-btn-list .ant-btn:last-child {
  margin-bottom: 10px;
}
.form-question-list .list-wrap .left-side .add-btn {
  margin-bottom: 6px;
  border-radius: 4px;
}
.form-question-list .list-wrap .left-side .score-btn {
  border-radius: 4px;
  width: 110px;
}
.form-question-list .list-wrap .left-side .ant-btn:hover {
  z-index: 100;
}
.form-question-list .list-wrap .left-side .ant-btn.selected {
  background: #1890ff;
  color: #fff;
  border-color: #1890ff;
}
.form-question-list .list-wrap .right-side {
  flex-grow: 1;
  border: 1px solid #d9d9d9;
}
