.paper-preview
    .question-module
        .question-name
            font-size 20px
            line-height 36px
            font-weight bold
            margin 20px 0 20px
        .exam-question
            margin-bottom 30px
            .question-header
                .question-type
                    display none
