
.create-exam-modal
    .ant-modal-content
        .ant-modal-body
            padding 10px
            height 300px
            overflow-y scroll
            padding-bottom 30px
            .ant-form-horizontal
                padding 0 50px 40px 0
                .ant-row
                    margin-top 10px
                h3
                    font-size 18px
                    font-weight bold
                    margin-left 70px
                    margin-top 30px
                    margin-bottom 10px
                    &.top-h3
                        margin-top 10px
                .ant-form-item
                    margin-bottom 5px
            .right-action
                position absolute
                left 0
                bottom 0
                width 100%
                background #fff
                .ant-btn
                    width 100px
                    margin 30px 0 10px 100px
