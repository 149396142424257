.simulation-geshui .home-page {
  height: 100%;
}
.simulation-geshui .home-page .geshui-date-picker {
  background-color: #fff;
  height: 72px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: #333;
}
.simulation-geshui .home-page .geshui-date-picker .ant-picker {
  height: 32px;
  width: 222px;
  border-radius: 5px;
}
.simulation-geshui .home-page .cards {
  height: calc(100% - 114px);
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.simulation-geshui .home-page .cards article.extend-to-bottom {
  min-height: 132px;
  height: calc(100% - 384px);
}
.simulation-geshui .home-page .cards article {
  background: #fff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
}
.simulation-geshui .home-page .cards article .todo-count {
  color: #ff0200;
  line-height: 26px;
  margin-left: 10px;
}
.simulation-geshui .home-page .cards article .notes {
  color: #999;
  line-height: 26px;
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
}
.simulation-geshui .home-page .cards article .feature {
  padding-left: 14px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 16px;
  grid-column-gap: 19px;
}
.simulation-geshui .home-page .cards article .feature a.feature-item {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 31px 20px 20px;
  display: flex;
  color: $ #333;
}
.simulation-geshui .home-page .cards article .feature a.feature-item .icon {
  margin-right: 10px;
}
.simulation-geshui .home-page .cards article .feature a.feature-item .info {
  flex: 1;
  display: inline-block;
}
.simulation-geshui .home-page .cards article .feature a.feature-item .info h3 {
  margin-bottom: 0;
  line-height: 26px;
  letter-spacing: 1px;
  font-size: 16px;
  color: rgba(85,85,85,0.333) 5;
}
.simulation-geshui .home-page .cards article .feature a.feature-item .info div {
  font-size: 14px;
  line-height: 26px;
  color: #999;
}
.simulation-geshui .home-page .cards article h3 {
  font-size: 16px;
  font-weight: 700;
  color: #333;
  line-height: 26px;
  margin-bottom: 20px;
}
.simulation-geshui .home-page .cards article h3 .line {
  height: 16px;
  display: inline-block;
  width: 4px;
  background: #4285f3;
  border-radius: 2px;
  margin-right: 10px;
  position: relative;
  top: 2px;
}
