.ant-modal-confirm.confirm-modal
    .ant-modal-content
        border-radius 8px
    .ant-modal-body
        padding 30px 40px
        .ant-modal-confirm-body
            .ant-modal-confirm-title
                line-height 18px
                font-size 18px
                font-weight bold
                color #2A354B
            .ant-modal-confirm-content
                margin-top 28px
                white-space pre-wrap
                color #555E6F
                font-size 14px
                line-height 24px
        .ant-modal-confirm-btns
            margin-top 28px
            .ant-btn
                height 36px
                width 100px
            .ant-btn:first-child
                border-color #AAAEB7
                color #555E6F
                &:hover
                    color #397FFF
                    background #EBF2FF
                    border-color #397FFF
            .ant-btn:last-child
                margin-left 20px
                background #397FFF
                &:hover
                    background #619EFF
    &.error-confirm
        .ant-modal-confirm-body .ant-modal-confirm-content
            margin-top 16px
        .error-content
            text-align center
            color #555E6F
            line-height 24px
            .error-message
                font-size 14px
                font-weight bold
                word-break keep-all
        .ant-modal-confirm-btns
            float none
            text-align center
            .ant-btn:first-child
                display none
            .ant-btn:last-child
                margin-left 0
