@import '../../../style/index.styl'

.login-layout
    position absolute
    width 100%
    height 100%
    .login-back
        position absolute
        width 100%
        height 50%
        background #1669FF
        overflow hidden
        .ellipse
            position absolute
            left -500px
            top 100px
        .ellipse2
            position absolute
            right -540px
            top -527px
    .login-box
        width 100%
        height 100%
        display flex
        align-items center
        justify-content center
        .login-content
            width 600px
            height auto
            opacity 1
            z-index 99
            background #ffffff
            border-radius 8px
            box-shadow 0px 0px 16px 0px rgba(22, 105, 255, 0.23)
