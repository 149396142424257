.sb-detail-page .sb-detail-default-page.panel {
  margin-bottom: 0;
}
.sb-detail-default-page {
  padding-bottom: 20px;
  min-height: 100%;
}
.sb-detail-default-page .default-name {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #2a354b;
  line-height: 31px;
  padding: 52px 0 63px 0;
}
.sb-detail-default-page .default-title {
  font-size: 14px;
  color: #666;
  line-height: 24px;
  padding-left: 11.74%;
}
.sb-detail-default-page .default-content {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.sb-detail-default-page .default-content .function-1,
.sb-detail-default-page .default-content .function-2 {
  width: 34.64%;
  display: flex;
}
.sb-detail-default-page .default-content .function-1 .function-num,
.sb-detail-default-page .default-content .function-2 .function-num {
  width: 30px;
  height: 30px;
  background: #cbb586;
  border-radius: 15px;
  color: #fff;
  line-height: 30px;
  text-align: center;
}
.sb-detail-default-page .default-content .function-1 .function-introduce,
.sb-detail-default-page .default-content .function-2 .function-introduce {
  width: 88.36%;
  margin-left: 1.14%;
}
.sb-detail-default-page .default-content .function-1 .function-introduce .introduce-title,
.sb-detail-default-page .default-content .function-2 .function-introduce .introduce-title {
  font-size: 18px;
  color: #333;
  line-height: 24px;
}
.sb-detail-default-page .default-content .function-1 .function-introduce .introduce-content,
.sb-detail-default-page .default-content .function-2 .function-introduce .introduce-content {
  margin-top: 8px;
  font-size: 14px;
  color: #666;
  line-height: 22px;
}
.sb-detail-default-page .default-content .function-1 {
  margin-left: 11.74%;
}
.sb-detail-default-page .default-content .function-2 {
  margin-right: 13.46%;
}
