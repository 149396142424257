.geshuiText
    line-height 1.5
    div
        margin-bottom 14px
        color #2a354b
    table
        color #555555
        border-collapse separate
        border-radius 4px
        margin-top 14px
        margin-bottom 14px
        border-radius 5px
        border 1px solid #E4E4E4
        font-size 14px
        th
            background-color #f4f4f5
            font-size 14px
            font-weight 700
            color #333333
        th, td
            padding 11px 24px
            border-bottom 1px solid #E4E4E4
            border-right 1px solid #E4E4E4
        tr>td:last-child
            border-right none
        tr:last-child>td
            border-bottom none
