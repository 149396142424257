.score-statistics {
  border-left: 1px solid;
}
.score-statistics .column {
  width: 10%;
  float: left;
}
.score-statistics .column .row-1,
.score-statistics .column .row-2,
.score-statistics .column .row-3 {
  height: 30px;
  text-align: center;
  border: 1px solid;
  line-height: 30px;
  border-left: none;
}
.score-statistics .column .row-2,
.score-statistics .column .row-3 {
  border-top: none;
}
.score-statistics .column .active {
  height: 30px;
  text-align: center;
  border: 1px solid #000;
  line-height: 30px;
  border-left: none;
  border-top: none;
  color: #f00;
}
.score-statistics .columns .rows-1 span,
.score-statistics .columns .rows-2 span,
.score-statistics .columns .rows-3 span {
  width: 10%;
  display: inline-block;
  height: 30px;
  text-align: center;
  border: 1px solid #000;
  line-height: 30px;
  border-left: none;
}
.score-statistics .columns .rows-2 span,
.score-statistics .columns .rows-3 span {
  border-top: none;
}
.score-statistics .columns .active {
  color: #f00;
}
