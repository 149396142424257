.exam-page-sidebar
    padding-bottom 80px
    .sidebar-title
        padding 23px 30px 20px
        font-size 20px
        line-height 24px
        color #222222
    .sidebar-catalog
        padding 0 22px 40px 30px
        height calc(100% - 67px)
        overflow-y scroll
        .catalog-item
            border-radius 8px
            background #ffffff
            margin-bottom 10px
            overflow hidden
            max-height 72px
            transition all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
            .item-title
                position relative
                display flex
                height 72px
                line-height 72px
                align-items center
                cursor pointer
                .item-no
                    width 30px
                    height 30px
                    line-height 30px
                    border-radius 6px
                    background rgba(57, 127, 255, 0.15)
                    text-align center
                    font-size 16px
                    font-weight bold
                    color #2A354B
                    margin 0 10px 0 24px
                .item-type
                    font-size 16px
                    font-weight bold
                    color #2A354B
                .item-score
                    font-size 14px
                    color #555E6F
                    transition color 0.3s
                .icon-fold, .icon-unfold
                    position absolute
                    right 30px
                    color #397FFF
                    cursor pointer
            .item-list
                padding 19px 25px 25px
                border-top 1px solid #ffffff
                transition border-color 0.3s
                .list-item
                    display inline-block
                    vertical-align top
                    margin 5px
                    background #F4F5F6
                    color #555E6F
                    text-align center
                    width 40px
                    height 40px
                    border-radius 20px
                    line-height 38px
                    cursor pointer
                    transition all 0.1s
                    border 1px solid #F4F5F6
                    &.completed
                        border-color #4DD8A7
                        background #4DD8A7
                        color #ffffff
                    &:hover
                        background #F4F5F6
                        border-color #397FFF
                        color #397FFF
                    &.current
                        border-color #397FFF
                        background #397FFF
                        color #ffffff
            &.unfold
                max-height 1000px
                box-shadow 10px 20px 20px 0px rgba(177, 188, 199, 0.15)
                .item-title
                    .item-score
                        color #AAAEB7
                .item-list
                    border-color #F4F5F6
    .sidebar-footer
        position absolute
        bottom 0
        left 30px
        right 30px
        height 80px
        border-top 1px solid #E9EAEC
        display flex
        align-items center
        justify-content space-evenly
        .status-item
            .item-content
                display inline-block
                vertical-align top
                color #85838F
                font-size 14px
                line-height 16px
            .item-tag
                display inline-block
                vertical-align top
                height 16px
                width 16px
                border-radius 8px
                margin-right 10px
            &:nth-child(1) .item-tag
                border 1px solid #AAAEB7
                background #F4F5F6
            &:nth-child(2) .item-tag
                background #4DD8A7
            &:nth-child(3) .item-tag
                background #397FFF
