.paper-detail
    .ant-row
        margin 10px 0
        padding 4px 0
        line-height 24px
        flex-wrap nowrap
        .item-label
            min-width 80px
            font-weight bold
        .item-value
            white-space pre-wrap
