.exam-page {
  height: 100%;
  overflow-x: auto;
}
.exam-page .page-container {
  position: relative;
  height: 100%;
  min-width: 1024px;
}
.exam-page .page-container .exam-page-header {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: auto;
  right: 0;
  height: 80px;
  background: #397fff;
}
.exam-page .page-container .exam-page-sidebar {
  position: absolute !important;
  top: 80px;
  left: 0;
  bottom: 0;
  right: auto;
  width: 410px;
  background: #f3f8ff;
}
.exam-page .page-container .exam-page-main {
  position: absolute !important;
  top: 80px;
  left: 410px;
  bottom: 0;
  right: 0;
}
