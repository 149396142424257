.question-detail-page
    .question-preview-container
        margin-top 10px
        padding 30px 25px
        border 1px solid #d9d9d9
        .question-header
            .question-no, .question-score, .question-quick-locating
                display none
        .question-list .question-header
            .question-no
                display inline
    .question-detail
        margin-top 20px
