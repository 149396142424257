.sb-detail-page .sb-select.ant-select
    border-color #E4E4E4
    outline none
    user-select none
    color #333333
    width 210px
    &:hover, &.ant-select-focused
        .ant-select-selector
            border-color #CBB586
            box-shadow none
    .ant-select-selection-search
        input:placeholder
            color #C9CCD2
    .ant-select-dropdown
        top 33px
    .ant-select-item.ant-select-item-option
        color #666666
        &:hover, &.ant-select-item-option-active
            background rgba(203, 181, 134, 0.15)
        &.ant-select-item-option-selected
            background #CBB586
            color #ffffff
