.confirm-notice
    .ant-modal-body
        max-height 500px
        .ant-modal-confirm-content
            .success
                color #555555
            .ant-checkbox-inner
                border-radius 3px
            .confirm-notice-btn
                margin-top 28px
                .ant-btn
                    height 36px
                    width 100px
                    border-radius 5px
                .ant-btn:first-child
                    margin 0 20px 0 220px
                    border-color #AAAEB7
                    color #555E6F
                    &:hover
                        color #1497e2
                        background #EBF2FF
                        border-color #1497e2
                .ant-btn:last-child
                    color #ffffff
                    background #1497e2
                    &:hover
                        color #ffffff
                        background #1497e2
        .ant-modal-confirm-btns
            display none
.confirm-careful
    margin-top 80px
    .ant-modal-body
        .ant-modal-confirm-content
            .ok
                color #555555
            .confirm-notice-btn
                margin-top 28px
                .ant-btn
                    height 36px
                    width 100px
                    border-radius 5px
                .ant-btn:last-child
                    color #ffffff
                    margin-left 90px
                    background #1497e2
                    &:hover
                        color #ffffff
                        background #1497e2
        .ant-modal-confirm-btns
            display none
.confirm-submit
    .ant-modal-content
        .ant-modal-body
            .anticon, .anticon-exclamation-circle
                display none
            .ant-modal-confirm-title
                font-weight bold
                font-size 18px
                color #2a354b
            .ant-modal-confirm-body
                .ant-modal-confirm-content
                    color #555555
            .ant-modal-confirm-btns
                float none
                display flex
                justify-content center
                .ant-btn
                    margin-left 20px
                    height 36px
                    width 100px
                    border-radius 5px
                .ant-btn:first-child
                    border-color #AAAEB7
                    color #555E6F
                    &:hover
                        color #1497e2
                        background #EBF2FF
                        border-color #1497e2
                .ant-btn:last-child
                    color #ffffff
                    background #1497e2
                    &:hover
                        color #ffffff
                        background #1497e2
.confirm-result
    .ant-modal-body
        .ant-modal-confirm-content
            .confirm-notice-btn
                float none
                display flex
                justify-content center
                margin-top 28px
                .ant-btn
                    height 36px
                    width 100px
                    margin-left 20px
                    border-radius 5px
                .ant-btn:first-child
                    border-color #AAAEB7
                    color #555E6F
                    &:hover
                        color #1497e2
                        background #EBF2FF
                        border-color #1497e2
                .ant-btn:last-child
                    color #ffffff
                    background #1497e2
                    &:hover
                        color #ffffff
                        background #1497e2
        .ant-modal-confirm-btns
            display none
