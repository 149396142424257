.task-and-records
    height 100%
    .view-tabs
        line-height 40px
        background rgba(0, 21, 41, 0.08)
        .tab
            display inline-block
            vertical-align top
            color #333333
            font-size 16px
            width 124px
            text-align center
            cursor pointer
            &.active
                color #001529
                font-weight bold
                background #ffffff
        .operation-tip
            display inline-block
            vertical-align top
            margin-left 10px
            color #999999
            font-size 12px
            .finger-img
                width 12px
                margin-right 4px
    .view-content
        height calc(100% - 40px)
        padding 22px 30px 40px
        font-size 14px
        color #333333
        .task-content
            color #333333
            font-size 14px
            line-height 24px
            div
                margin-bottom 14px
            table
                border 1px solid #e4e4e4
                border-width 1px 0 0 1px
                border-radius 4px
                border-collapse separate
                margin-bottom 14px
                th, td
                    padding 6px 24px
                    line-height 36px
                    border 1px solid #e4e4e4
                    border-width 0 1px 1px 0
                th
                    background #F4F4F5
                    font-weight bold
                    color #333333
                    &:not([colspan='2'])
                        border-bottom none
                td
                    border-color #E4E4E4
                    color #555550
