.operate-box {
  width: 100%;
  border: 1px solid #000;
  margin: 30px 0;
  padding: 30px;
}
.operate-box .operate-name {
  font-weight: bold;
  font-size: 18px;
}
.operate-box .operate-content .content-score {
  font-weight: bold;
}
.operate-box .operate-content .resources-score {
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 10px;
}
.operate-box .operate-content .none-list {
  margin: 20px 40px;
}
.operate-box .operate-content .resources-table .ant-table-thead {
  height: 40px;
}
.operate-box .operate-content .resources-table .ant-table-thead > tr > th,
.operate-box .operate-content .resources-table .ant-table-tbody > tr > td,
.operate-box .operate-content .resources-table .ant-table tfoot > tr > th,
.operate-box .operate-content .resources-table .ant-table tfoot > tr > td {
  padding: 5px;
}
