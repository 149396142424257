.submit-success-modal .ant-modal-confirm-btns {
  display: none;
}
.submit-success-modal .ant-modal-content .ant-modal-body {
  padding: 60px;
}
.submit-success-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 0;
  text-align: center;
}
.submit-success-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .icon-success {
  font-size: 50px;
  color: #02c882;
}
.submit-success-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .result-text {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 18px;
  margin-bottom: 4px;
  padding-left: 13px;
}
.submit-success-modal .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content .result-hint {
  font-size: 14px;
  line-height: 24px;
  color: #c9ccd2;
  padding-left: 1px;
}
