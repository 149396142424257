.list-item-paper {
  padding: 20px;
}
.list-item-paper .detail-information .detail-information-title {
  font-weight: bold;
  margin-bottom: 10px;
}
.list-item-paper .detail-information div {
  font-size: 16px;
}
.list-item-paper .detail-information .paper-snapshot {
  position: fixed;
  right: 80px;
  top: 60px;
}
.list-item-paper .detail-information .text-button {
  position: fixed;
  right: 70px;
  top: 110px;
}
.list-item-paper .detail-score {
  margin: 40px 0 60px 0;
}
.list-item-paper .detail-score .detail-score-title {
  font-weight: bold;
  margin-bottom: 10px;
}
