.operate-box
    width 100%
    border 1px solid #000
    margin 30px 0
    padding 30px
    .operate-name
        font-weight bold
        font-size 18px
    .operate-content
        .content-score
            font-weight bold
        .resources-score
            font-weight bold
            margin-left 10px
            margin-bottom 10px
        .none-list
            margin 20px 40px
        .resources-table
            .ant-table-thead
                height 40px
            .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td
                padding 5px
