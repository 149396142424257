.exam-page-header {
  display: flex;
  color: #fff;
}
.exam-page-header .exam-name {
  padding-left: 30px;
  font-size: 24px;
  font-weight: bold;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
.exam-page-header .exam-name .icon-paper {
  width: 32px;
  margin-right: 15px;
}
.exam-page-header .user {
  padding: 0 25px 0 65px;
  display: flex;
  align-items: center;
}
.exam-page-header .user .icon-container {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background: rgba(138,187,255,0.56);
  padding: 6px 8px;
}
.exam-page-header .user .icon-container .icon-user {
  width: 20px;
  height: 22px;
}
.exam-page-header .user .user-info {
  padding: 0 36px 0 12px;
}
.exam-page-header .user .user-info .username {
  font-size: 16px;
  padding-bottom: 4px;
  line-height: 21px;
}
.exam-page-header .user .user-info .user-ticket {
  font-size: 13px;
  line-height: 17px;
  opacity: 0.8;
}
.exam-page-header .user .logout-btn {
  color: rgba(255,255,255,0.8);
  font-size: 12px;
}
.exam-page-header .user .logout-btn .icon-quit {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  font-weight: bold;
}
