.simulation-geshui-modal .total-form-item .ant-form-item-control-input-content > .ant-input,
.simulation-geshui-modal .total-form-item .ant-form-item-label > label[title='小计'] {
  color: #ff8217;
}
.simulation-geshui .tax-panel {
  color: #333;
}
.simulation-geshui .tax-panel article {
  background-color: #fff;
  padding: 20px;
}
.simulation-geshui .tax-panel article ul.steps {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}
.simulation-geshui .tax-panel article ul.steps li.current {
  background-color: #4285f3;
  color: #fff;
}
.simulation-geshui .tax-panel article ul.steps li {
  margin-right: 80px;
  position: relative;
  width: 220px;
  height: 40px;
  background-color: #f4f4f5;
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
}
.simulation-geshui .tax-panel article ul.steps li:last-child {
  margin-right: 0;
}
.simulation-geshui .tax-panel article ul.steps li:not(:last-child)::after {
  content: '> >';
  position: absolute;
  right: -53px;
  color: #999;
}
.simulation-geshui .tax-panel article h3 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.simulation-geshui .tax-panel article h3 .line {
  margin-right: 10px;
  height: 16px;
  width: 4px;
  border-radius: 2px;
  background-color: #4285f3;
  display: inline-block;
}
.simulation-geshui .tax-panel article table.menu-table {
  line-height: 26px;
  border-collapse: separate;
  border: 1px solid #e4e4e4;
  width: 100%;
  border-radius: 4px;
}
.simulation-geshui .tax-panel article table.menu-table thead {
  background: #f4f4f5;
  font-weight: 700;
}
.simulation-geshui .tax-panel article table.menu-table td,
.simulation-geshui .tax-panel article table.menu-table th {
  padding: 11px 24px;
  border-bottom: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
}
.simulation-geshui .tax-panel article table.menu-table tr>td:first-child {
  color: #4285f3;
}
.simulation-geshui .tax-panel article table.menu-table tr>td:last-child {
  border-right: none;
}
.simulation-geshui .tax-panel article table.menu-table tr:last-child>td {
  border-bottom: none;
}
.simulation-geshui .tax-panel article button.link {
  background: none;
  color: #4285f3;
  border: none;
  cursor: pointer;
}
.simulation-geshui .tax-panel article a {
  color: #4285f3;
  padding: 0 6px;
}
.simulation-geshui .tax-panel article a.text {
  color: #4285f3;
  padding: 0;
}
