.simulation-record-item .back-btn {
  padding: 0;
}
.simulation-record-item .ant-row {
  flex-wrap: nowrap;
}
.simulation-record-item .item-label,
.simulation-record-item .item-value {
  font-size: 14px;
  color: #333;
  line-height: 26px;
  margin-bottom: 10px;
}
.simulation-record-item .item-label {
  font-weight: bold;
  width: 70px;
  white-space: nowrap;
}
.simulation-record-item .item-value .operate-obj {
  display: inline-block;
  vertical-align: top;
}
.simulation-record-item .item-value .operate-obj:not(:last-child):after {
  content: '、';
}
.simulation-record-item .ant-table-wrapper {
  display: inline-block;
}
.simulation-record-item .ant-table-wrapper .ant-table table {
  width: auto;
}
.simulation-record-item .ant-table-wrapper .ant-table table .ant-table-thead > tr > th,
.simulation-record-item .ant-table-wrapper .ant-table table .ant-table-tbody > tr > td {
  padding: 11px 24px;
  line-height: 26px;
}
