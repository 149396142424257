.answer-sheet {
  position: relative;
  margin-top: 40px;
  margin-bottom: 60px;
}
.answer-sheet .analyze-type {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  position: absolute;
  left: 0;
  top: -35px;
}
.answer-sheet .question-content {
  display: flex;
}
.answer-sheet .question-content .row-1 {
  width: 5%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #000;
  border-right: none;
  background: #f5f5f5;
}
.answer-sheet .question-content .row-2,
.answer-sheet .question-content .row-3,
.answer-sheet .question-content .row-4,
.answer-sheet .question-content .row-5,
.answer-sheet .question-content .row-6 {
  width: 19%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #000;
  border-right: none;
  background: #f5f5f5;
}
.answer-sheet .question-content div:last-child {
  border-right: 1px solid #000;
}
.answer-sheet .question-answer {
  display: flex;
}
.answer-sheet .question-answer .row-1 {
  width: 5%;
  text-align: center;
  line-height: 40px;
  border: 1px solid #000;
  border-right: none;
  border-top: none;
  background: #f5f5f5;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
}
.answer-sheet .question-answer .row-2,
.answer-sheet .question-answer .row-3,
.answer-sheet .question-answer .row-4,
.answer-sheet .question-answer .row-5,
.answer-sheet .question-answer .row-6,
.answer-sheet .question-answer .row-7 {
  width: 19%;
  text-align: center;
  line-height: 40px;
  border: 1px solid #000;
  border-right: none;
  border-top: none;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
}
.answer-sheet .question-answer div:last-child {
  border-right: 1px solid #000;
}
.answer-sheet .question-answer .enter .ok {
  height: 100%;
  cursor: pointer;
  border: 2px solid #f00;
}
.answer-sheet .setup-score {
  position: absolute;
  right: 1%;
  top: -40px;
}
