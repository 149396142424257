.simulation-modal .ant-modal-body .splitter-layout {
  z-index: 0;
  top: 40px;
  left: 0;
  height: calc(100% - 40px);
}
.simulation-modal .ant-modal-body .splitter-layout .layout-pane {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.simulation-modal .ant-modal-body .splitter-layout .layout-pane::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.simulation-modal .ant-modal-body .splitter-layout .layout-pane::-webkit-scrollbar-track {
  background-color: transparent;
}
.simulation-modal .ant-modal-body .splitter-layout .layout-pane::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: transparent;
}
.simulation-modal .ant-modal-body .splitter-layout .layout-pane:hover::-webkit-scrollbar-thumb {
  background-color: rgba(220,220,220,0.8);
}
.simulation-modal .ant-modal-body .splitter-layout .layout-pane:first-child {
  z-index: -1;
}
.simulation-modal .ant-modal-body .splitter-layout .layout-pane:last-child {
  background: #fff;
  z-index: 9999;
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.2);
}
.simulation-modal .ant-modal-body .splitter-layout .layout-splitter {
  position: relative;
  height: 1px;
  color: #bbb;
  z-index: 10000;
}
.simulation-modal .ant-modal-body .splitter-layout .layout-splitter::before {
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  height: 4px;
  content: ' ';
}
.simulation-modal .ant-modal-body .splitter-layout .layout-splitter::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  content: ' ';
  z-index: 10;
}
