.confirm-notice .ant-modal-body {
  max-height: 500px;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-content .success {
  color: #555;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-content .ant-checkbox-inner {
  border-radius: 3px;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn {
  margin-top: 28px;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn {
  height: 36px;
  width: 100px;
  border-radius: 5px;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:first-child {
  margin: 0 20px 0 220px;
  border-color: #aaaeb7;
  color: #555e6f;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:first-child:hover {
  color: #1497e2;
  background: #ebf2ff;
  border-color: #1497e2;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child {
  color: #fff;
  background: #1497e2;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.confirm-notice .ant-modal-body .ant-modal-confirm-btns {
  display: none;
}
.confirm-careful {
  margin-top: 80px;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .ok {
  color: #555;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn {
  margin-top: 28px;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn {
  height: 36px;
  width: 100px;
  border-radius: 5px;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child {
  color: #fff;
  margin-left: 90px;
  background: #1497e2;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-btns {
  display: none;
}
.confirm-submit .ant-modal-content .ant-modal-body .anticon,
.confirm-submit .ant-modal-content .ant-modal-body .anticon-exclamation-circle {
  display: none;
}
.confirm-submit .ant-modal-content .ant-modal-body .ant-modal-confirm-title {
  font-weight: bold;
  font-size: 18px;
  color: #2a354b;
}
.confirm-submit .ant-modal-content .ant-modal-body .ant-modal-confirm-body .ant-modal-confirm-content {
  color: #555;
}
.confirm-submit .ant-modal-content .ant-modal-body .ant-modal-confirm-btns {
  float: none;
  display: flex;
  justify-content: center;
}
.confirm-submit .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn {
  margin-left: 20px;
  height: 36px;
  width: 100px;
  border-radius: 5px;
}
.confirm-submit .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn:first-child {
  border-color: #aaaeb7;
  color: #555e6f;
}
.confirm-submit .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn:first-child:hover {
  color: #1497e2;
  background: #ebf2ff;
  border-color: #1497e2;
}
.confirm-submit .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn:last-child {
  color: #fff;
  background: #1497e2;
}
.confirm-submit .ant-modal-content .ant-modal-body .ant-modal-confirm-btns .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.confirm-result .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn {
  float: none;
  display: flex;
  justify-content: center;
  margin-top: 28px;
}
.confirm-result .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn {
  height: 36px;
  width: 100px;
  margin-left: 20px;
  border-radius: 5px;
}
.confirm-result .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:first-child {
  border-color: #aaaeb7;
  color: #555e6f;
}
.confirm-result .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:first-child:hover {
  color: #1497e2;
  background: #ebf2ff;
  border-color: #1497e2;
}
.confirm-result .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child {
  color: #fff;
  background: #1497e2;
}
.confirm-result .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.confirm-result .ant-modal-body .ant-modal-confirm-btns {
  display: none;
}
