.simulation-geshui
    .home-page
        height 100%
        .geshui-date-picker
            background-color #FFFFFF
            height 72px
            padding 0 20px
            display flex
            align-items center
            color #333333
            .ant-picker
                height 32px
                width 222px
                border-radius 5px
        .cards
            height calc(100% - 114px)
            padding-top 20px
            padding-left 20px
            padding-right 20px
            article.extend-to-bottom
                min-height 132px
                height calc(100% - 384px)
            article
                .todo-count
                    color #FF0200
                    line-height 26px
                    margin-left 10px
                .notes
                    color #999999
                    line-height 26px
                    font-size 14px
                    text-align center
                    margin-top 30px
                    margin-bottom 10px
                background #ffffff
                margin-bottom 20px
                padding 20px
                border-radius 4px
                .feature
                    padding-left 14px
                    display grid
                    grid-template-columns repeat(3, 1fr)
                    grid-template-rows repeat(2, 1fr)
                    grid-row-gap 16px
                    grid-column-gap 19px
                    a.feature-item
                        background-color #fff
                        border 1px solid #e4e4e4
                        border-radius 4px
                        padding 31px 20px 20px
                        display flex
                        color $ #333333
                        .icon
                            margin-right 10px
                        .info
                            flex 1
                            display inline-block
                            h3
                                margin-bottom 0
                                line-height 26px
                                letter-spacing 1px
                                font-size 16px
                                color #5555 5
                            div
                                font-size 14px
                                line-height 26px
                                color #999999
                h3
                    font-size 16px
                    font-weight 700
                    color #333333
                    line-height 26px
                    margin-bottom 20px
                    .line
                        height 16px
                        display inline-block
                        width 4px
                        background #4285f3
                        border-radius 2px
                        margin-right 10px
                        position relative
                        top 2px
