.sb-detail-page .sb-button
    font-size 14px
    color #333333
    border-color #999999
    background transparent
    width 60px
    padding 0 6px
    margin 0 5px
    transition all 0.2s
    &:hover
        color #BE986F
        border-color #BE986F
    &.theme
        color #BE986F
        border-color #BE986F
        &:hover
            color #BE986F
            background #FAF5E9
    &.ant-btn-primary
        background #BE986F
        color #ffffff
        border none
        &:hover
            background #CBB586
    &.commit-btn, &.cancel-btn
        width 100px
        height 36px
        border-radius 4px
        margin 0 10px
