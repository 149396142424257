.sb-home-page {
  position: relative;
  height: 100%;
  z-index: 0;
}
.sb-home-page .sidebar.panel {
  height: 100%;
  width: 234px;
  padding: 20px 0;
  border-radius: 0;
  z-index: 1;
}
.sb-home-page .sidebar.panel .sidebar-item {
  display: block;
  line-height: 48px;
  color: #555;
  transition: all 0.1s;
  font-size: 16px;
}
.sb-home-page .sidebar.panel .sidebar-item:hover {
  color: #be986f;
  background: rgba(203,181,134,0.15);
}
.sb-home-page .sidebar.panel .sidebar-item.disabled {
  pointer-events: none;
}
.sb-home-page .sidebar.panel .sidebar-item img {
  width: 24px;
  margin: -2px 14px auto 30px;
}
.sb-home-page .page-right {
  position: absolute !important;
  top: 0;
  left: 234px;
  bottom: 0;
  right: 0;
  background: #f4f4f5;
  overflow-y: scroll;
  z-index: -1;
}
.sb-home-page .page-right .panel-1,
.sb-home-page .page-right .panel-2,
.sb-home-page .page-right .panel-3,
.sb-home-page .page-right .panel-4 {
  width: calc((100% - 100px) * 0.2061);
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sb-home-page .page-right .panel-1 .panel-left img,
.sb-home-page .page-right .panel-2 .panel-left img,
.sb-home-page .page-right .panel-3 .panel-left img,
.sb-home-page .page-right .panel-4 .panel-left img {
  width: 46px;
  height: 46px;
}
.sb-home-page .page-right .panel-1 .panel-right,
.sb-home-page .page-right .panel-2 .panel-right,
.sb-home-page .page-right .panel-3 .panel-right,
.sb-home-page .page-right .panel-4 .panel-right {
  margin-left: 16%;
}
.sb-home-page .page-right .panel-1 .panel-right .type-num,
.sb-home-page .page-right .panel-2 .panel-right .type-num,
.sb-home-page .page-right .panel-3 .panel-right .type-num,
.sb-home-page .page-right .panel-4 .panel-right .type-num {
  font-size: 30px;
  line-height: 42px;
  color: #555;
}
.sb-home-page .page-right .panel-1 .panel-right .type-name,
.sb-home-page .page-right .panel-2 .panel-right .type-name,
.sb-home-page .page-right .panel-3 .panel-right .type-name,
.sb-home-page .page-right .panel-4 .panel-right .type-name {
  font-size: 14px;
  line-height: 26px;
  color: #999;
}
.sb-home-page .page-right .panel-1 {
  position: absolute !important;
  top: 20px;
  left: 20px;
  bottom: auto;
  right: auto;
}
.sb-home-page .page-right .panel-2 {
  position: absolute !important;
  top: 20px;
  left: calc((100% - 100px) * 0.2061 + 40px);
  bottom: auto;
  right: auto;
}
.sb-home-page .page-right .panel-3 {
  position: absolute !important;
  top: 170px;
  left: 20px;
  bottom: auto;
  right: auto;
}
.sb-home-page .page-right .panel-4 {
  position: absolute !important;
  top: 170px;
  left: calc((100% - 100px) * 0.2061 + 40px);
  bottom: auto;
  right: auto;
}
.sb-home-page .page-right .panel-5 {
  position: absolute !important;
  top: 20px;
  left: calc((100% - 100px) * 0.4122 + 60px);
  bottom: auto;
  right: auto;
  width: calc((100% - 100px) * 0.3381);
  height: 280px;
  background: #cbb586;
  overflow: hidden;
}
.sb-home-page .page-right .panel-5 .c-5-img {
  position: absolute !important;
  top: 28px;
  left: 23px;
  bottom: auto;
  right: auto;
  width: 183px;
  max-width: 75%;
}
.sb-home-page .page-right .panel-5 .c-6-img {
  position: absolute !important;
  top: auto;
  left: auto;
  bottom: 0;
  right: -75px;
  width: 239px;
}
.sb-home-page .page-right .panel-6 {
  position: absolute !important;
  top: 20px;
  left: calc((100% - 100px) * 0.7503 + 80px);
  bottom: auto;
  right: 12px;
  height: 280px;
  padding: 14px 20px;
}
.sb-home-page .page-right .panel-6 .fake-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  border-radius: 4px;
  background: #f4f4f5;
}
.sb-home-page .page-right .panel-6 .fake-input .fake-placeholder {
  color: #c3c3c3;
  font-size: 14px;
}
.sb-home-page .page-right .panel-6 .fake-input .search-img {
  width: 16px;
}
.sb-home-page .page-right .panel-6 .search-title {
  line-height: 26px;
  font-size: 14px;
  color: #be986f;
  margin-top: 12px;
}
.sb-home-page .page-right .panel-6 .search-result {
  margin-top: 10px;
}
.sb-home-page .page-right .panel-6 .search-result .result-item {
  line-height: 26px;
  margin-bottom: 6px;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
.sb-home-page .page-right .panel-6 .search-result .result-item span:last-child {
  margin-left: 12px;
  color: #666;
}
.sb-home-page .page-right .panel-6 .search-result .result-item:nth-child(1) span:first-child {
  color: #f9302a;
}
.sb-home-page .page-right .panel-6 .search-result .result-item:nth-child(2) span:first-child {
  color: #ff8217;
}
.sb-home-page .page-right .panel-6 .search-result .result-item:nth-child(3) span:first-child {
  color: #ffb517;
}
.sb-home-page .page-right .panel-6 .search-result .result-item:nth-child(4) span:first-child {
  color: #999;
}
.sb-home-page .page-right .panel-7,
.sb-home-page .page-right .panel-8 {
  height: calc(100% - 320px);
  min-height: 260px;
}
.sb-home-page .page-right .panel-7 .panel-header,
.sb-home-page .page-right .panel-8 .panel-header {
  display: flex;
  justify-content: space-between;
  line-height: 48px;
  border-bottom: 1px solid #ededed;
  padding: 0 20px;
}
.sb-home-page .page-right .panel-7 .panel-header .panel-title,
.sb-home-page .page-right .panel-8 .panel-header .panel-title {
  font-size: 16px;
  color: #333;
}
.sb-home-page .page-right .panel-7 .panel-header .panel-action,
.sb-home-page .page-right .panel-8 .panel-header .panel-action {
  font-size: 14px;
  color: #999;
}
.sb-home-page .page-right .panel-7 {
  position: absolute !important;
  top: 320px;
  left: 20px;
  bottom: auto;
  right: auto;
  width: calc((100% - 100px) * 0.4122 + 20px);
}
.sb-home-page .page-right .panel-8 {
  position: absolute !important;
  top: 320px;
  left: calc((100% - 100px) * 0.4122 + 60px);
  bottom: auto;
  right: 12px;
}
.sb-home-page .page-right .panel-8 .panel-row {
  margin: 12px 20px;
  line-height: 36px;
  background: #f4f4f5;
}
.sb-home-page .page-right .panel-8 .panel-row .row-item {
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding-left: 28px;
  color: #666;
  font-size: 14px;
}
