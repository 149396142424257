.question-list-page .page-list-container .list-item .row-1 span {
  margin-right: 8px;
  font-size: 14px;
}
.question-list-page .page-list-container .list-item .row-1 .item-type {
  color: #6076ff;
}
.question-list-page .page-list-container .list-item .row-2 {
  font-size: 18px;
  padding: 2px 0;
  line-height: 30px;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}
.question-list-page .page-list-container .list-item .row-3 .ant-tag {
  margin-bottom: 4px;
}
