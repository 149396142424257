.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-tips {
  text-align: center;
  color: #ff8217;
  margin-bottom: 16px;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-tips .icon-img {
  width: 14px;
  height: 14px;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-tips .tips-content {
  margin-left: 8px;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-form .ant-input {
  border-radius: 5px;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-form .confirm-notice-btn {
  margin-top: 20px;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-form .confirm-notice-btn .ant-btn {
  height: 36px;
  width: 100px;
  border-radius: 5px;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-form .confirm-notice-btn .ant-btn:first-child {
  margin: 0 20px 0 220px;
  border-color: #aaaeb7;
  color: #555e6f;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-form .confirm-notice-btn .ant-btn:first-child:hover {
  color: #1497e2;
  background: #ebf2ff;
  border-color: #1497e2;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-form .confirm-notice-btn .ant-btn:last-child {
  color: #fff;
  background: #1497e2;
}
.confirm-QiFeng-status .ant-modal-body .confirm-status .confirm-status-form .confirm-notice-btn .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.confirm-QiFeng-status .ant-modal-body .ant-modal-confirm-btns {
  display: none;
}
.confirm-careful {
  margin-top: 80px;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .success {
  color: #555;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn {
  margin-top: 28px;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn {
  height: 36px;
  width: 100px;
  border-radius: 5px;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child {
  color: #fff;
  margin-left: 90px;
  background: #1497e2;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-content .confirm-notice-btn .ant-btn:last-child:hover {
  color: #fff;
  background: #1497e2;
}
.confirm-careful .ant-modal-body .ant-modal-confirm-btns {
  display: none;
}
