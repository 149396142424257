.exam-info-btn {
  font-size: 14px;
}
.exam-info-btn .icon-notice {
  margin-right: 6px;
  color: #ff8217;
}
.exam-info-btn span {
  color: #aaaeb7;
}
.exam-info-btn span:hover {
  color: #397fff;
}
.exam-info-modal .exam-info-view {
  text-align: center;
}
.exam-info-modal .exam-info-view .view-title {
  color: #2a354b;
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
  margin-bottom: 40px;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-nav {
  margin-bottom: 0;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-nav::before {
  border-bottom: none;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab {
  background: #e9eaec;
  border: none;
  margin: 0;
  color: rgba(42,53,75,0.9);
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  color: #2a354b;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:nth-child(1) {
  border-radius: 4px 0 0 0;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab:nth-child(2) {
  border-radius: 0 4px 0 0;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  background: #397fff;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-nav .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-content {
  height: 231px;
  padding-top: 20px;
  background: #f0f8ff;
  text-align: justify;
  color: #555e6f;
  font-size: 14px;
  line-height: 22px;
  white-space: pre-wrap;
  border-radius: 0 4px 4px 4px;
}
.exam-info-modal .exam-info-view .view-tabs .ant-tabs-content .ant-tabs-tabpane {
  height: 100%;
  padding: 2px 20px 22px;
}
.exam-info-modal .exam-info-view .close-btn {
  width: 200px;
  margin: 30px auto 6px;
}
