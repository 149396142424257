.answer-sheet
    position relative
    margin-top 40px
    margin-bottom 60px
    .analyze-type
        font-weight bold
        font-size 16px
        margin-bottom 10px
        position absolute
        left 0
        top -35px
    .question-content
        display flex
        .row-1
            width 5%
            height 40px
            text-align center
            line-height 40px
            border 1px solid #000
            border-right none
            background #f5f5f5
        .row-2, .row-3, .row-4, .row-5, .row-6
            width 19%
            height 40px
            text-align center
            line-height 40px
            border 1px solid #000
            border-right none
            background #f5f5f5
        div:last-child
            border-right 1px solid #000
    .question-answer
        display flex
        .row-1
            width 5%
            text-align center
            line-height 40px
            border 1px solid #000
            border-right none
            border-top none
            background #f5f5f5
            overflow hidden
            white-space normal
            word-break break-word
        .row-2, .row-3, .row-4, .row-5, .row-6, .row-7
            width 19%
            text-align center
            line-height 40px
            border 1px solid #000
            border-right none
            border-top none
            overflow hidden
            white-space normal
            word-break break-word
        div:last-child
            border-right 1px solid #000
        .enter
            .ok
                height 100%
                cursor pointer
                border 2px solid red
    .setup-score
        position absolute
        right 1%
        top -40px
