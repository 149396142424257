.simulation-modal .ant-modal-body
    .splitter-layout
        z-index 0
        top 40px
        left 0
        height calc(100% - 40px)
        .layout-pane
            overflow-y auto
            -webkit-overflow-scrolling touch
            &::-webkit-scrollbar
                width 8px
                height 8px
                background-color transparent
            &::-webkit-scrollbar-track
                background-color transparent
            &::-webkit-scrollbar-thumb
                border-radius 4px
                background-color transparent
            &:hover::-webkit-scrollbar-thumb
                background-color rgba(220, 220, 220, 0.8)
            &:first-child
                z-index -1
            &:last-child
                background #fff
                z-index 9999
                box-shadow 0px 0px 20px 0px rgba(0, 0, 0, 0.2)
        .layout-splitter
            position relative
            height 1px
            color #BBBBBB
            z-index 10000
            &::before
                position absolute
                top -4px
                left 0
                right 0
                height 4px
                content ' '
            &::after
                position absolute
                top 0
                left 0
                right 0
                height 5px
                content ' '
                z-index 10
