.br4
    border-radius 4px
// 覆写 antd 组件的样式
ant-alert-info()
    .ant-alert-info
        padding-left 10px
        margin-bottom $space
        @extend .br4
        border-color #95bbf8
        background-color #eff5ff
        .ant-alert-icon
            color $mainColor
        .ant-alert-content
            color #555555
            .ant-alert-message, .ant-alert-description
                color inherit
ant-tabs-top()
    .ant-table-thead > tr > th
        color $fontColor
        font-weight 700
    .ant-table
        color #555555
    .ant-table-selection-column
        .ant-checkbox-checked .ant-checkbox-inner
            background-color $mainColor
            border-color $mainColor
    .ant-table.ant-table-bordered > .ant-table-footer, .ant-table-footer
        display inline-block
        position absolute
        right 0
        border none
        background transparent
        .ant-btn
            color #FF8217
            border-color #FF8217
    .ant-pagination > li
        color $mainColor
    .ant-table-tbody > tr.ant-table-row-selected > td
        background-color #F4F9FF
    .ant-pagination > li, .ant-table-container
        @extend .br4
    .ant-tabs-top
        & > .ant-tabs-nav .ant-tabs-ink-bar
            color $mainColor
        .ant-tabs-tab-btn
            color $fontColor
        .ant-tabs-tab-active .ant-tabs-tab-btn
            color $mainColor
        .ant-tabs-nav
            margin-top 4px
            margin-bottom $space
ant-button()
    button[class|="ant"]
        @extend .br4
    .ant-btn
        border-color $mainColor
        color $mainColor
    .ant-btn-primary
        background-color $mainColor
        color #FDFDFD
    .ant-btn[disabled]
        background #f3f3f3
        border 1px solid #e4e4e4
        color #C2C2C2
modal-form()
    .geshui-modal-form
        padding 20px 40px 10px 30px
        color $fontColor
    .person-status-item
        margin-bottom 6px
        justify-content flex-end
        .ant-form-item-control
            flex initial
    .form-section
        &:last-child
            margin-bottom 0
            border-bottom none
        border-bottom 1px solid #E4E4E4
        margin-bottom 30px
        // 覆写antd form 组件样式
        .ant-picker-input > input[disabled], .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector
            color #333
        .ant-cascader-picker.ks-cascader.address-cascader
            width 100%
        .ant-cascader-picker.ks-cascader:hover .ant-cascader-input.ant-input, .ant-cascader-picker.ks-cascader:focus .ant-cascader-input.ant-input
            border-color $mainColor !important
        .ant-input-disabled
            background-color #f3f3f3
        .ant-select-selector
            @extend .br4
        .ant-input-number
            width 100%
            @extend .br4
        .ant-input, .ant-picker, .ant-input-number-input
            width 100%
            border-color #e4e4e4
            @extend .br4
            color $fontColor
        .ant-checkbox-wrapper
            color $fontColor
        .ant-input:hover:not(.ant-input-disabled), .ant-input:focus(.ant-input-disabled)
            border-color $mainColor
        .ant-checkbox-checked .ant-checkbox-inner
            background-color $mainColor
            border-color $mainColor
        // 覆写antd form 组件样式 end
        .ant-form-item
            display inline-flex
            width 50%
            justify-content flex-start
            margin-bottom 20px
        .ant-form-item:nth-child(even)
            justify-content flex-end
        .ant-form-item-label
            width 133px
            &>label
                color $fontColor
        .ant-form-item-control
            flex-grow 0
            flex-basis auto
            width 222px
    .form-section-title
        margin-bottom 20px
        font-size 16px
        line-height 1
        font-weight 700
        .line
            height 16px
            width 4px
            display inline-block
            background $mainColor
            border-radius 2px
            margin-right 10px
            vertical-align bottom
ant-modal()
    .simulation-geshui-confirm, .simulation-geshui-modal
        ant-button()
    .simulation-geshui-confirm
        .ant-modal-confirm-btns
            float none
            text-align center
    .simulation-geshui-modal
        .ant-modal-header
            padding 16px 32px
            border-radius 8px 8px 0 0
        .ant-modal-title
            font-size 16px
            font-weight 700
            line-height 21px
        .ant-modal-body
            padding 0
        .ant-modal-footer
            padding-top 10px
            padding-bottom 10px
            text-align center
            .ant-btn
                height 36px
                width 68px
        modal-form()
